import { useState } from "react";

export const useGaCategory = () => {
  const [category, setGaCategory] = useState(window.gaEventCategory);

  const setCategory = (value) => {
    setGaCategory(value);
    window.gaEventCategory = value;
  };

  const getGaEvent = (event) => ({ category, ...event });

  const getGaEventString = (event) => {
    const nextEvent = getGaEvent(event);
    return `${nextEvent.category}|${nextEvent.action}|${nextEvent.label}`;
  };

  return {
    setGaCategory: setCategory,
    getGaEvent,
    getGaEventString,
    category,
  };
};
