import React, { useState, useEffect } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useForm, useBaseContext, useUserContext } from "helpers/hooks";
import { subscribeFavorites, getTelegramRegionLink } from "helpers/api";
import { sendEvent } from "helpers/analytics";
import { UIForm } from "../components/UIKit/Form";
import { UIInput } from "../components/UIKit/Input";
import { UIButton } from "../components/UIKit/Button";
import { UITelegramButton } from "../components/UIKit/TelegramButton";
import { gaEventActions as gaActions } from "../analytics";
import { useGaCategory } from "../analytics/useCategory";

import style from "./Subscription.module.scss";

const Subscription = () => {
  const intl = useIntl();
  const { getGaEvent: ga, getGaEventString: gaStr } = useGaCategory();
  const { showMessage } = useBaseContext();
  const { state: user } = useUserContext();
  const { state, handleChangeInput } = useForm({ email: user.email });
  const [loading, setLoading] = useState(false);
  const [telegram, setTelegram] = useState();

  const getTelegramLink = () => {
    getTelegramRegionLink()
      .then((response) => {
        if (response.status !== "ok") return;
        setTelegram(response.data.url);
      })
      .catch(() => {});
  };

  const onSubmit = () => {
    setLoading(true);
    subscribeFavorites({ email: state.email })
      .then((response) => {
        setLoading(false);
        sendEvent(gaStr(gaActions.subscription.email_success));
        showMessage("subscription.success");
        if (response.status !== "ok") throw new Error();
      })
      .catch(() => {
        setLoading(false);
        showMessage("error");
        sendEvent(gaStr(gaActions.subscription.email_error));
      });
  };

  useEffect(() => {
    getTelegramLink();
  }, []);

  return (
    <div className={style.wrapper}>
      <div className={style.content}>
        <div className={style.title}>
          <FormattedMessage id="subscription.title" />
        </div>
        <div>
          <FormattedMessage id="subscription.subtitle" />
        </div>
      </div>
      <div className={style.divider} />
      <div className={style.actions}>
        <div className={style.actionsCell}>
          <UIForm.Group wrap>
            <UIForm.Row>
              <UIInput
                name="email"
                onChange={handleChangeInput}
                value={state.email}
                event={ga(gaActions.subscription.email_input_click)}
                label={intl.formatMessage({ id: "subscription.label" })}
              />
            </UIForm.Row>
            <UIButton
              event={ga(gaActions.subscription.subscribe_email_click)}
              appearance="primary"
              loading={loading}
              onClick={onSubmit}
            >
              <FormattedMessage id="subscribe" />
            </UIButton>
          </UIForm.Group>
        </div>
        <div className={style.actionsCell}>
          <div className={style.telegram}>
            <UITelegramButton
              event={ga(gaActions.subscription.subscribe_telegram_click)}
              disabled={!telegram}
              attributes={{ href: telegram, target: "_blank" }}
              fullWidth
            >
              <FormattedMessage id="subscription.telegram" />
            </UITelegramButton>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Subscription;
