export const COOKIE_NAME_USER_LANGUAGE = "user_language";

export const COOKIE_NAME_CURRENCY = "preferred_currency";

export const Language = {
  Ru: "ru",
  Uk: "uk",
};

export const LANGUAGE_LABELS = {
  [Language.Ru]: "Русский",
  [Language.Uk]: "Українська",
};

export const Currency = {
  UAH: "uah",
  USD: "usd",
};

export const SubscriptionType = {
  ByEmail: "email",
  ByTelegram: "telegram",
};

export const CURRENCY_LABELS = {
  [Currency.UAH]: "currency.uah",
  [Currency.USD]: "currency.usd",
};

export const CURRENCY_SIGN = {
  [Currency.UAH]: "грн",
  [Currency.USD]: "$",
};

export const SUBSCRIPTION_LABELS = {
  [SubscriptionType.ByEmail]: "subscriptionType.email",
  [SubscriptionType.ByTelegram]: "subscriptionType.telegram",
};

export const RegionId = {
  Kyiv: 10,
  Lviv: 13,
  Odesa: 15,
};

export const MainRegions = [RegionId.Kyiv, RegionId.Lviv, RegionId.Odesa];

export const TELEGRAM_SUPPORT_LINK = `https://telegram.me/lun_support_bot?start=${window.btoa(
  "my.lun.ua"
)}`;

export const ApiResponseStatus = {
  Ok: "ok",
  Error: "error",
};
