export const REGION_TYPES = {
  INIT_REGIONS: "INIT_REGIONS",
  SET_ACTIVE_REGION: "SET_ACTIVE_REGION",
  SET_ACTIVE_LINK: "SET_ACTIVE_LINK",
};

export const initialRegions = {
  common: [],
  primary: [],
  active: JSON.parse(localStorage.getItem("activeRegion") || "{}"),
  isInitial: true,
  isEmptyRegions: true,
};

export const regionReducer = (state, { type, payload }) => {
  switch (type) {
    case REGION_TYPES.INIT_REGIONS:
      return {
        ...state,
        common: payload.regions.common_region,
        primary: payload.regions.primary_region,
        active: payload.regions.active_region[0],
        isInitial: false,
        isEmptyRegions: false,
      };
    case REGION_TYPES.SET_ACTIVE_REGION:
      return {
        ...state,
        active: payload.activeRegion,
        isInitial: false,
      };
    case REGION_TYPES.SET_ACTIVE_LINK:
      return {
        ...state,
        link: payload.link,
        isInitial: false,
      };
    default:
      return state;
  }
};
