import { useLocation, useHistory } from "react-router-dom";
import qs from "qs";

const useUrlParams = () => {
  const location = useLocation();
  const history = useHistory();
  const urlParams = qs.parse(location.search, { ignoreQueryPrefix: true });

  const setUrlParams = (value) => {
    const q = qs.stringify(
      { ...urlParams, ...value },
      { indices: false, addQueryPrefix: false }
    );
    history.push({ pathname: location.pathname, search: q });
  };

  const getUrlParamsString = (value) =>
    qs.stringify(value, { indices: false, addQueryPrefix: false });

  return {
    urlParams,
    setUrlParams,
    getUrlParamsString,
  };
};

export default useUrlParams;
