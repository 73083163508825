import React, { useMemo, useState } from "react";
import PropTypes from "prop-types";
import { PLACEHOLDERS } from "constants/image-placeholders";
import { FavoriteEntity } from "constants/favorites";
import styles from "./Image.module.scss";
import { BackgroundPlaceholder } from "placeholders/Placeholder";
import cn from "classnames";
import LazyLoad from "react-lazyload";
import Webp from "./Wepb";

export const randomImage = (entity, id) => {
  if (!PLACEHOLDERS[entity]) entity = FavoriteEntity.BUILDINGS;
  if (id) return PLACEHOLDERS[entity][id % PLACEHOLDERS[entity].length];
  return PLACEHOLDERS[entity][
    Math.floor(Math.random() * PLACEHOLDERS[entity].length)
  ];
};

export const ImagePlaceholder = ({ entity, id, className }) => {
  if (!entity) return <BackgroundPlaceholder />;
  return (
    <img
      className={cn(styles.placeholder, className)}
      src={randomImage(entity, id)}
      alt=""
    />
  );
};

const Image = ({
  src,
  srcset,
  className,
  alt,
  entity,
  id,
  useWebp,
  classes = {},
}) => {
  const [loaded, setLoaded] = useState(false);
  const placeholder = useMemo(
    () => <ImagePlaceholder entity={entity} id={id} />,
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [loaded]
  );

  if (useWebp) {
    return (
      <Webp
        placeholder={placeholder}
        srcSet={srcset ? `${srcset}.webp` : undefined}
        src={`${src}.webp`}
        imageSrc={src}
        imageSrcset={srcset}
        alt={alt}
        className={classes.picture}
      />
    );
  }

  return (
    <LazyLoad placeholder={placeholder}>
      <img
        src={src}
        srcSet={srcset ? `${src} 1x ${srcset} 2x` : src}
        className={cn(className, classes.image)}
        alt={alt}
        onLoad={() => setLoaded(true)}
        loading="lazy"
      />
      {!loaded && placeholder}
    </LazyLoad>
  );
};

Image.propTypes = {
  src: PropTypes.string,
  srcset: PropTypes.string,
  className: PropTypes.string,
  alt: PropTypes.string,
  isSmall: PropTypes.bool,
  entity: PropTypes.oneOf([FavoriteEntity.BUILDINGS, FavoriteEntity.LAYOUTS]),
  useWebp: PropTypes.bool,
  classes: PropTypes.shape({
    picture: PropTypes.string,
    image: PropTypes.string,
  }),
};

export { Image };
