import React, { createContext, useReducer, useEffect } from "react";
import { useUserContext } from "helpers/hooks";
import { favoritesReducer } from "./reducer";
import { INITIAL_FAVORITES_STATE } from "./constants";
import * as pureActions from "./actions";
import { composeDispatch } from "../utils";

const FavoritesContext = createContext();

const FavoriteContextProvider = ({ children }) => {
  const [state, dispatch] = useReducer(
    favoritesReducer,
    INITIAL_FAVORITES_STATE
  );
  const { state: user } = useUserContext();
  const actions = composeDispatch(pureActions, dispatch);

  const value = { ...state, actions };

  useEffect(() => {
    actions.fetchBuildingIds();
    if (!user.isLoaded) return;
    actions.reset();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user.isGuest]);

  return (
    <FavoritesContext.Provider value={value}>
      {children}
    </FavoritesContext.Provider>
  );
};

export { FavoritesContext, FavoriteContextProvider };
