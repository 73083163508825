import React, { useRef, useState } from "react";
import { FormattedMessage } from "react-intl";
import { useRouteMatch } from "react-router-dom";
import { useBaseContext } from "helpers/hooks";
import { attachGaDataAttributes } from "helpers/analytics";
import { navigationDefaultProps, navigationPropTypes } from "../Navigation";
import ProfileIcon from "../ProfileIcon";
import NavigationProfileMenu from "../NavigationProfileMenu";
import { useGaCategory } from "../../analytics/useCategory";
import { gaEventActions as gaActions } from "../../analytics";
import Webp from "../../components/Image/Wepb";

import homeIcon from "../../icons/bottom-navigation/home-inactive.png";
import homeIcon2x from "../../icons/bottom-navigation/home-inactive@2x.png";
import homeIconWebp from "../../icons/bottom-navigation/home-inactive.png.webp";
import homeIcon2xWebp from "../../icons/bottom-navigation/home-inactive@2x.png.webp";

import buildingIcon from "../../icons/bottom-navigation/buildings-inactive.png";
import buildingIcon2x from "../../icons/bottom-navigation/buildings-inactive@2x.png";
import buildingIconWebp from "../../icons/bottom-navigation/buildings-inactive.png.webp";
import buildingIcon2xWebp from "../../icons/bottom-navigation/buildings-inactive@2x.png.webp";

import saleIcon from "../../icons/bottom-navigation/sale-inactive.png";
import saleIcon2x from "../../icons/bottom-navigation/sale-inactive@2x.png";
import saleIconWebp from "../../icons/bottom-navigation/sale-inactive.png.webp";
import saleIcon2xWebp from "../../icons/bottom-navigation/sale-inactive@2x.png.webp";

import rentIcon from "../../icons/bottom-navigation/rent-inactive.png";
import rentIcon2x from "../../icons/bottom-navigation/rent-inactive@2x.png";
import rentIconWebp from "../../icons/bottom-navigation/rent-inactive.png.webp";
import rentIcon2xWebp from "../../icons/bottom-navigation/rent-inactive@2x.png.webp";

import favoritesIcon from "../../icons/bottom-navigation/favorites-active.png";
import favoritesIcon2x from "../../icons/bottom-navigation/favorites-active@2x.png";
import favoritesIconWebp from "../../icons/bottom-navigation/favorites-active.png.webp";
import favoritesIcon2xWebp from "../../icons/bottom-navigation/favorites-active@2x.png.webp";

import "./BottomNavigation.scss";

const BottomNavigation = ({ links }) => {
  const { routes } = useBaseContext();
  const matchQuestion = useRouteMatch(routes.question);
  const matchAppointment = useRouteMatch(routes.appointment);
  const profileTrigger = useRef();
  const [profileOpened, setProfileOpened] = useState(false);
  const { getGaEvent: ga } = useGaCategory();

  if (matchQuestion || matchAppointment) return null;

  return (
    <div className="BottomNavigation">
      <div className="BottomNavigation-floating">
        <div className="BottomNavigation-wrapper">
          <a
            href={links.home.url}
            className="BottomNavigation-item"
            {...attachGaDataAttributes(
              ga(gaActions.navigation_bottom.goto_home)
            )}
          >
            <Webp
              src={homeIconWebp}
              srcset={homeIcon2xWebp}
              imageSrc={homeIcon}
              imageSrcset={homeIcon2x}
            />
            <FormattedMessage id="navigation.home" />
          </a>
          <a
            href={links.building.url}
            className="BottomNavigation-item"
            {...attachGaDataAttributes(
              ga(gaActions.navigation_bottom.goto_catalog)
            )}
          >
            <Webp
              src={buildingIconWebp}
              srcset={buildingIcon2xWebp}
              imageSrc={buildingIcon}
              imageSrcset={buildingIcon2x}
            />
            <FormattedMessage id="navigation.catalog" />
          </a>
          <a
            href={links.flats_buy.url}
            className="BottomNavigation-item"
            {...attachGaDataAttributes(
              ga(gaActions.navigation_bottom.goto_flat_sale)
            )}
          >
            <Webp
              src={saleIconWebp}
              srcset={saleIcon2xWebp}
              imageSrc={saleIcon}
              imageSrcset={saleIcon2x}
            />
            <FormattedMessage id="navigation.flats_buy" />
          </a>
          <a
            href={links.flats_rent.url}
            className="BottomNavigation-item"
            {...attachGaDataAttributes(
              ga(gaActions.navigation_bottom.goto_flat_rent)
            )}
          >
            <Webp
              src={rentIconWebp}
              srcset={rentIcon2xWebp}
              imageSrc={rentIcon}
              imageSrcset={rentIcon2x}
            />
            <FormattedMessage id="navigation.flats_rent" />
          </a>
          <div
            className="BottomNavigation-item"
            onClick={() => setProfileOpened(true)}
            ref={profileTrigger}
            {...attachGaDataAttributes(
              ga(gaActions.navigation_bottom.open_auth_click)
            )}
          >
            <ProfileIcon
              className="BottomNavigation-avatar"
              defaultIcon={
                <Webp
                  src={favoritesIconWebp}
                  srcset={favoritesIcon2xWebp}
                  imageSrc={favoritesIcon}
                  imageSrcset={favoritesIcon2x}
                />
              }
            />
            Мій ЛУН
          </div>
        </div>
        <NavigationProfileMenu
          opened={profileOpened}
          onClose={() => setProfileOpened(false)}
          anchorEl={profileTrigger.current}
        />
      </div>
    </div>
  );
};

BottomNavigation.propTypes = navigationPropTypes;

BottomNavigation.defaultProps = navigationDefaultProps;

export default BottomNavigation;
