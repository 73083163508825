import { useNotification } from "helpers/hooks";
import { useCallback } from "react";
import { useQuery } from "react-query";

const useQueryApi = (queryKey, queryFnApi, config) => {
  const notifications = useNotification();

  const queryFn = useCallback(async () => {
    const res = await queryFnApi();
    if (res.status !== "ok") throw new Error(res.message);
    return res.data;
  }, [queryFnApi]);

  return useQuery(queryKey, queryFn, {
    onError: () => {
      notifications.showMessage("error");
    },
    refetchOnWindowFocus: false,
    ...config,
  });
};

export default useQueryApi;
