import { useState, useEffect } from "react";

const useSteps = (count, initialStep = 1) => {
  const [step, setStep] = useState(initialStep);

  const next = () => {
    const nextStep = step + 1;
    setStep(nextStep > count ? count : nextStep);
  };

  const prev = () => {
    const prevStep = step - 1;
    setStep(prevStep < 1 ? 1 : prevStep);
  };

  useEffect(() => {
    window.scrollTo({ top: 0 });
  }, [step]);

  return { prev, next, step, setStep };
};

export default useSteps;
