import axios from "axios";
import qs from "qs";

export const API_HOST = `${process.env.REACT_APP_API}/api`;
export const API_HOST_USER = `${API_HOST}/user`;
export const API_HOST_SITE = `${process.env.REACT_APP_SITE_LINK}/api`;
export const API_HOST_LUN_MARKET = `${process.env.REACT_APP_LUN_MARKET_API_LINK}/api`;

export const source = axios.CancelToken.source();

const req = axios.create({
  withCredentials: true,
});

const get = (api, params) =>
  req.get(`${API_HOST}/${api}`, { params }).then((r) => r.data);

const post = (api, data) =>
  req.post(`${API_HOST}/${api}`, data).then((r) => r.data);

const remove = (api, data) =>
  req.delete(`${API_HOST}/${api}`, { data }).then((r) => r.data);

export const addCompare = (data) =>
  post("user/compare-list", { building_ids: JSON.stringify(data) });
export const autocompete = (q) =>
  get(`autocomplete?${q.length > 0 ? `q=${q}` : "default_list=navigation"}`);
export const autocompleteBuilding = (q) => get(`building-autocomplete?q=${q}`);
export const addFavorite = (params) =>
  post(`favorites/${params.entity}/${params.id}`);
export const cancelAppointment = (hash) => post(`cancel-appointment?h=${hash}`);
export const getABTestBranch = () => get("user/process-ab-test");
export const getAllNews2 = (page = 1, newsType) =>
  get(`user/v2/news?p=${page}${newsType ? `&news_type=${newsType}` : ""}`);
export const getAppointmentCancelData = (hash) =>
  get(`cancel-appointment?h=${hash}`);
export const getAppointmentData = (id) =>
  get(`appointment-data?building_id=${id}`);
export const getCompareIds = () => get("user/compare-list");
export const getContacts = () => get("user/contacts");
export const getDeveloperQuestionData = (id) =>
  get(`v2/question?building_id=${id}`);
export const getFavoriteBuildings = (data) =>
  get("user/favorite-buildings", data);
export const getFavoriteBuildingIds = () => get("user/favorite-building-ids");
export const getFavoriteBuildingsTable = (data) =>
  post("user/favorites-table", data);
export const getFavoriteLayouts = () => get("user/favorite-layouts");
export const getIsSubscribed = () => get("user/is-subscribe");
export const getLastNews = () => get("user/new-objects");
export const getLastVisited = () => get("user/last-visited");
export const getLastVisitedLayouts = () => get("user/last-visited-layouts");
export const getLayoutData = (id) => get(`layout-data/${id}`);
export const getNavigation = () => get("navigation-tabs");
export const getNews = (page = 1) => get(`user/favorite-news?p=${page}`);
export const getPopular = () => get("user/popular-buildings");
export const getProjectData = (id) => get(`cottage-typical-project/${id}`);
export const getRegionList = () => get("user/regions-list");
export const getTabs = () => get("user/get-tabs");
export const getTelegramRegionLink = () => get("user/telegram-subscribe-url");
export const getUpdates = () => get("user/updates");
export const getUserData = () => get("user/data");
export const login = (data) => post("user/login", data);
export const loginFacebook = (data) => post("user/auth/facebook", data);
export const loginGoogle = (data) => post("user/auth/google", data);
export const loginPhone = (data) => post("user/login-phone", data);
export const loginTelegram = (data) =>
  post("user/login-telegram", `user=${JSON.stringify(data)}`);
export const loginToken = (data) => post("user/login-token", data);
export const logout = () => post("user/logout", null);
export const removeCompare = (data) =>
  remove("user/compare-list", { building_ids: JSON.stringify(data) });
export const removeFavorite = (params) =>
  remove(`favorites/${params.entity}/${params.id}`);
export const resendCode = (data) => post("verify/send-sms", data);
export const resendEmail = (data) => post("verify/send-email", data);
export const savePhoneClick = (data) => post("save-show-phone-click", data);
export const sendAppointment = (data) => post("appointment-subscriber", data);
export const validateAppointment = (data) =>
  post("appointment-subscriber-validate", data);
export const sendDeveloperQuestion = (data) => post("v2/question", data);
export const sendMessage = (data) => post("message", data);
export const setRegion = (data) => post("user/set-region", data);
export const subscribeBack = (data) => post("subscriber/subscribe", data);
export const subscribeEmail = (data) =>
  post("user/email-global-news-subscribe", data);
export const subscribeFavorites = (data) =>
  post("subscriber/subscribe-favorites", data);
export const unsubscribeAll = (data) =>
  post("subscriber/unsubscribe-from-all-news", data);
export const unsubscribeBuilding = (data) =>
  post("subscriber/unsubscribe-from-building", data);
export const unsubscribeCompany = (data) =>
  post("subscriber/unsubscribe-from-company", data);
export const unsubscribeFeedback = (data) =>
  post("subscriber/send-feedback", data);
export const unsubscribeGlobalNews = () =>
  post("user/unsubscribe-global-news-user");
export const unsubscribeRegion = (data) =>
  post("subscriber/unsubscribe-from-region", data);
// export const verifyFormAuthKey = data => post('verify/email', data);
export const verifyFormCode = (data) => post("verify/phone", data);
export const appointmentFeedback = (data) => post("appointment-feedback", data);
export const getShortenedUrl = (url) => get(`get-shortened-url?url=${url}`);
export const updateUserData = (data) => post("user/update", data);
export const getTerritoryLabel = () => get("geo-territory-label");

export const fetchLunMarketFavorites = (params) =>
  req
    .get(
      `${API_HOST_LUN_MARKET}/realties/batch?${qs.stringify(params, {
        arrayFormat: "repeat",
      })}`
    )
    .then((r) => r.data);

export const fetchLunMarketCurrencies = () =>
  req.get(`${API_HOST_LUN_MARKET}/currencies`).then((r) => r.data);
