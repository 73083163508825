import cx from "classnames";

function mergeClasses(baseClasses, additionalClasses) {
  const merged = Object.entries(baseClasses).reduce((acc, [key, value]) => {
    return {
      ...acc,
      [key]: cx(value, additionalClasses[key]),
    };
  }, {});

  return merged;
}

export default mergeClasses;
