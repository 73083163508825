import React, { useMemo, useState } from "react";
import { useHistory } from "react-router-dom";
import { RefreshComponents, ToggleChip } from "@flatfy/supernova-new";
import { FormattedMessage, useIntl } from "react-intl";
import { CardPlaceholder, RowPlaceholder } from "placeholders/Placeholder";
import {
  useMutationApi,
  useQueryApi,
  useUserContext,
  useUrlParams,
  useDeveloperQuestionEvents,
  useNotification,
} from "../../helpers/hooks";
import {
  getDeveloperQuestionData,
  loginToken,
  sendDeveloperQuestion,
} from "../../helpers/api";
import { STEPS } from "../../helpers/hooks/useDeveloperQuestionEvents";
import DeveloperQuestionStart from "./steps/DeveloperQuestionStart";
import DeveloperQuestionPersonalInfo from "./steps/DeveloperQuestionPersonalInfo";
import DeveloperQuestionFinal from "./steps/DeveloperQuestionFinal";
import DeveloperQuestionSocial from "./DeveloperQuestionSocial";
import AnalyticsElement from "../../components/AnalyticsElement";
import {
  CONFIRMATION_TYPES,
  COMMUNICATION_TYPE,
  COMMUNICATION_TYPE_LABELS,
  NORMALIZED_SOCIAL_EVENTS,
} from "../../constants/developer-question";
import Form from "../../components/FormRefresh";
import { Close } from "../../icons";
import styles from "./DeveloperQuestion.module.scss";

const DeveloperQuestion = ({ onClose, id, token, page }) => {
  const notification = useNotification();
  const history = useHistory();
  const { urlParams } = useUrlParams();
  const [step, setStep] = useState(
    urlParams.callMeBack ? STEPS.FORM : STEPS.START
  );
  const { state: user, actions: userActions } = useUserContext();
  const analytics = useDeveloperQuestionEvents(step, id);
  const intl = useIntl();
  const [communication, setCommunication] = useState(COMMUNICATION_TYPE.EMAIL);

  const building = useQueryApi(
    ["building", { id }],
    () => getDeveloperQuestionData(id),
    {
      onError: (err) => {
        notification.showMessageStr(err.message);
        analytics.sendEvent("server_error");
      },
    }
  );

  const communicationList = useMemo(() => {
    if (!building.data) return [];
    const result = [COMMUNICATION_TYPE.EMAIL];
    if (building.data.viber_link) {
      result.push(COMMUNICATION_TYPE.VIBER);
    }
    if (building.data.telegram_link) {
      result.push(COMMUNICATION_TYPE.TELEGRAM);
    }
    return result;
  }, [building.data]);

  const emailVerification = useQueryApi(
    ["email-verification", { token }],
    () => loginToken({ token }),
    {
      enabled: !!token,
      onSuccess: (res) => {
        history.push({ search: "" });
        setStep(STEPS.FINAL);
        userActions.setData(res);
      },
      onError: (err) => {
        notification.showMessageStr(err.message);
        analytics.sendEvent("server_error");
      },
    }
  );

  const sendQuestion = useMutationApi(sendDeveloperQuestion, {
    onSuccess: (res, variables) => {
      window.scrollTo(0, 0);
      analytics.sendSubmitQuestionEvents({
        ...variables,
        buildingId: id,
        buildingClickPrice: building.data.building_click_price,
        adPlan: building.data.ad_plan,
      });
      analytics.sendEvent("goto_final");
      setStep(STEPS.FINAL);
    },
    onError: (err) => {
      notification.showMessageStr(err.message);
      analytics.sendEvent("server_error");
    },
  });

  const handleClose = () => {
    if (onClose) {
      onClose();
    } else {
      window.location.replace(building.data.building_url);
    }
  };

  const handleSendQuestion = (values) => {
    sendQuestion.mutate({
      ...values,
      phone: values.phone.replace(/\s/g, ""),
      building_id: id,
      use_viber: values.use_viber ? "yes" : "no",
      page: page || building.data.building_url,
      event_category: urlParams.eventCategory || window.gaEventCategory,
      gclid: urlParams.gclid,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  };

  return (
    <div className={styles.wrapper}>
      <div className={styles.header}>
        <RefreshComponents.Typography variant="h6">
          <FormattedMessage id="developerQuestion.askDeveloper" />
        </RefreshComponents.Typography>
        <AnalyticsElement
          element={RefreshComponents.Button}
          variant="secondary-icon"
          leftIcon={<Close />}
          onClick={handleClose}
          eventCategory={analytics.eventCategory}
          eventAction={analytics.eventAction}
          eventLabel="close_click"
          eventOptions={analytics.eventOptions}
          className={styles.closeButton}
        />
      </div>
      <div className={styles.content}>
        {building.isFetching || emailVerification.isFetching ? (
          <div className={styles.loading}>
            <RowPlaceholder />
            <CardPlaceholder size="medium" />
          </div>
        ) : (
          <Form
            onSubmit={handleSendQuestion}
            initialValues={{
              email: user.email,
              phone: user.phone,
              communication_type: COMMUNICATION_TYPE.PHONE,
              name: user.first_name,
              chips: [],
              ...(urlParams.callMeBack && {
                message: intl.formatMessage({ id: "callMeBack" }),
              }),
            }}
          >
            {() => (
              <div className={styles.root}>
                {[STEPS.FORM, STEPS.START].includes(step) &&
                  communicationList.length > 1 && (
                    <ToggleChip className={styles.communication}>
                      {communicationList.map((type) => (
                        <AnalyticsElement
                          element={RefreshComponents.Chip}
                          key={type}
                          active={type === communication}
                          onClick={() => setCommunication(type)}
                          className={styles.communicationChip}
                          eventCategory={analytics.eventCategory}
                          eventAction={analytics.eventAction}
                          eventLabel={`communication_${NORMALIZED_SOCIAL_EVENTS[type]}_click`}
                          eventOptions={analytics.eventOptions}
                        >
                          {COMMUNICATION_TYPE_LABELS[type]}
                        </AnalyticsElement>
                      ))}
                    </ToggleChip>
                  )}
                {communication !== COMMUNICATION_TYPE.EMAIL && (
                  <DeveloperQuestionSocial
                    link={
                      communication === COMMUNICATION_TYPE.VIBER
                        ? building.data.viber_link
                        : building.data.telegram_link
                    }
                    buildingName={building.data.building_name}
                    type={communication}
                    analytics={analytics}
                  />
                )}
                {step === STEPS.START && communication === COMMUNICATION_TYPE.EMAIL && (
                  <div className={styles.step}>
                    <DeveloperQuestionStart
                      building={building.data}
                      analytics={analytics}
                      onSubmit={() => setStep(STEPS.FORM)}
                      isQuestionFromFlats={urlParams.isQuestionFromFlats}
                    />
                  </div>
                )}
                {step === STEPS.FORM && communication === COMMUNICATION_TYPE.EMAIL && (
                  <div className={styles.step}>
                    <DeveloperQuestionPersonalInfo
                      analytics={analytics}
                      loading={sendQuestion.isLoading}
                      callMeBack={urlParams.callMeBack}
                      parentDesktop={urlParams.isDesktop}
                    />
                  </div>
                )}
                {step === STEPS.FINAL && (
                  <DeveloperQuestionFinal
                    building={building.data}
                    analytics={analytics}
                    onClose={handleClose}
                    callMeBack={urlParams.callMeBack}
                  />
                )}
              </div>
            )}
          </Form>
        )}
      </div>
    </div>
  );
};

export default DeveloperQuestion;
