import { GEO_TYPE } from "../constants/geoType";

export const useGeo = (geo, geoEntities) => {
  if (!geoEntities || geoEntities.length == 0) return geo;
  const normalizedGeoEntities = geoEntities.reduce(
    (prev, current) => ({
      ...prev,
      [current.type]: current,
    }),
    {}
  );

  const subGeo = [];

  if (normalizedGeoEntities[GEO_TYPE.STREET]) {
    subGeo.push(normalizedGeoEntities[GEO_TYPE.RESIDENTAL_COMPLEX]);
  }

  subGeo.push(
    ...[
      normalizedGeoEntities[GEO_TYPE.MICRODISTRICT],
      normalizedGeoEntities[GEO_TYPE.DISTRICT],
      normalizedGeoEntities[GEO_TYPE.LOCALITY],
    ]
  );

  return subGeo
    .filter(Boolean)
    .map((e) => e.name)
    .join(", ");
};
