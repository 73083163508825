import { useState } from "react";

const useTime = (timeout = 1000) => {
  const [time, setTime] = useState();

  /* time in seconds */
  const start = (value) => {
    setTime(value);
    const interval = setInterval(() => {
      setTime((ms) => {
        if (ms > 1) return ms - 1;
        clearInterval(interval);
        return null;
      });
    }, timeout);
    return () => clearInterval(interval);
  };

  return {
    start,
    time,
  };
};

export default useTime;
