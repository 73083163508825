import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { FormattedMessage, useIntl } from "react-intl";
import { useForm, useUrlParams, useBaseContext } from "helpers/hooks";
import { UIInput } from "../../components/UIKit/Input";
import { UIButton } from "../../components/UIKit/Button";
import { sendEvent } from "helpers/analytics";
import { ReactComponent as SuccessIcon } from "icons/subscribe.svg";
import { appointmentFeedback } from "helpers/api";
import { UIChip } from "../../components/UIKit/Chip";
import styles from "./AppointmentFeedback.module.scss";
import {
  CardPlaceholder,
  RowPlaceholder,
} from "../../placeholders/Placeholder";
import { useGaCategory } from "../../analytics/useCategory";
import { gaEventActions as gaActions } from "../../analytics";

const appointmentMarkAnalytics = {
  0: "not_visited",
  1: "bad",
  2: "ok",
  3: "good",
  4: "very_good",
};

const AppointmentFeedbackForm = (params) => {
  const intl = useIntl();
  const { urlParams } = useUrlParams();
  const { state, handleChangeInput } = useForm();
  const [activeMark, setActiveMark] = useState(+urlParams.mark);
  const [fetching, setFetching] = useState(false);
  const marks = [4, 3, 2, 1, 0];
  const isActiveMark = marks.indexOf(activeMark) !== -1;
  const { showMessage } = useBaseContext();
  const { getGaEvent: ga, getGaEventString: gaStr } = useGaCategory();

  const onSubmit = (e) => {
    e.preventDefault();
    const formData = {
      ...state,
      mark: activeMark,
      token: urlParams.token,
    };
    setFetching(true);
    appointmentFeedback(formData)
      .then((res) => {
        if (res.status !== "ok") throw new Error(res.message);
        sendEvent(gaStr(gaActions.appointment_feedback.form_success));
        params.setSuccess(true);
      })
      .catch((err) => {
        showMessage(err.message);
        setFetching(false);
        sendEvent(gaStr(gaActions.appointment_feedback.form_error));
      });
    params.setSuccess(true);
  };

  useEffect(() => {
    if (isActiveMark) {
      sendEvent(
        gaStr(
          gaActions.appointment_feedback.mark(
            appointmentMarkAnalytics[activeMark]
          )
        )
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeMark]);

  return (
    <form onSubmit={onSubmit} className={styles.form}>
      <div className="UIChips -wrap">
        {marks.map((mark) => (
          <UIChip
            active={activeMark === mark}
            key={`mark=${mark}`}
            onClick={() => setActiveMark(mark)}
            event={ga(gaActions.appointment_feedback.mark(mark))}
          >
            <FormattedMessage id={`appointmentFeedback.mark.${mark}`} />
          </UIChip>
        ))}
      </div>
      <div style={{ visibility: isActiveMark ? "visible" : "hidden" }}>
        <div className={styles.textarea}>
          <UIInput
            name="message"
            label={intl.formatMessage({
              id: "appointmentFeedback.message",
            })}
            value={state.message}
            isTextarea
            onChange={handleChangeInput}
            attributes={{ rows: 4 }}
            event={ga(gaActions.appointment_feedback.message_type)}
          />
        </div>
        <UIButton
          event={ga(gaActions.appointment_feedback.submit_click)}
          appearance="primary"
          attributes={{ type: "submit" }}
          loading={fetching}
          fullWidth
        >
          <FormattedMessage id="send" />
        </UIButton>
      </div>
    </form>
  );
};

const AppointmentFeedbackSuccess = () => {
  return (
    <div className={styles.success}>
      <div className={styles.title}>
        <FormattedMessage id="appointmentFeedback.success.title" />
      </div>
      <div className={styles.subtitle}>
        <FormattedMessage id="appointmentFeedback.success.subtitle" />
      </div>
      <SuccessIcon />
    </div>
  );
};

const AppointmentFeedback = (params) => {
  const [buildingData, setBuildingData] = useState(null);
  const { showMessage, routes } = useBaseContext();
  const history = useHistory();
  const [success, setSuccess] = useState(false);
  const { urlParams } = useUrlParams();

  useEffect(() => {
    appointmentFeedback({
      mark: urlParams.mark,
      token: urlParams.token,
    })
      .then((res) => {
        if (res.status !== "ok") throw new Error(res.message);
        setBuildingData(res.data);
        if (res.data.comment_ready) setSuccess(true);
      })
      .catch((err) => {
        showMessage(err.message);
        history.push(routes.main);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className={styles.wrapper}>
      {!buildingData ? (
        <>
          <RowPlaceholder />
          <CardPlaceholder size="small" subtitle={false} />
          <br />
          <CardPlaceholder size="medium" />
        </>
      ) : (
        <>
          <div className={styles.title}>
            <FormattedMessage
              id="appointmentFeedback.title"
              values={{ br: <br /> }}
            />
          </div>
          <div className={styles.subtitle}>
            <FormattedMessage
              id="appointmentFeedback.subtitle"
              values={{ name: buildingData.building_name, br: <br /> }}
            />
          </div>
          {success ? (
            <AppointmentFeedbackSuccess />
          ) : (
            <AppointmentFeedbackForm {...params} setSuccess={setSuccess} />
          )}
        </>
      )}
    </div>
  );
};

export default AppointmentFeedback;
