export const fetchActionTypes = {
  LOADING: "LOADING",
  SUCCESS: "SUCCESS",
  FAILURE: "FAILURE",
  EMPTY: "EMPTY",
};

export const initialState = {
  loading: true,
  success: false,
  failure: false,
  empty: false,
};

export const fetchReducer = (state = initialState, { type }) => {
  switch (type) {
    case fetchActionTypes.LOADING:
      return {
        loading: true,
        success: false,
        failure: false,
        empty: false,
      };
    case fetchActionTypes.SUCCESS:
      return {
        loading: false,
        success: true,
        failure: false,
        empty: false,
      };
    case fetchActionTypes.FAILURE:
      return {
        loading: false,
        success: false,
        failure: true,
        empty: false,
      };
    case fetchActionTypes.EMPTY:
      return {
        loading: false,
        success: true,
        failure: false,
        empty: true,
      };
    default:
      return state;
  }
};
