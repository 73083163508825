import { sendEvent } from "helpers/analytics";
import { useUrlParams } from "helpers/hooks";

export const QUESTION_CATEGORY = "developer_question_form";

export const STEPS = {
  START: "start",
  FORM: "personal_info",
  FINAL: "final",
};

const useDeveloperQuestionEvents = (stepName, buildingId) => {
  const { urlParams } = useUrlParams();
  const defaultEventOptions = { buildingId };
  const eventOptions = urlParams.placeOnPage
    ? { ...defaultEventOptions, placemark: urlParams.placeOnPage }
    : defaultEventOptions;

  const getEventStr = (label, action = stepName) =>
    `${QUESTION_CATEGORY}|${action}|${label}`;

  const getEvent = (label, action = stepName) => ({
    category: QUESTION_CATEGORY,
    action,
    label,
    options: eventOptions,
  });

  const sendSubmitQuestionEvents = (formData) => {
    sendEvent(getEventStr("success_all"), eventOptions);

    window.dataLayer.push({
      event: "autoEvent",
      eventCategory: "conversions",
      eventAction: "developer_questions",
      eventLabel: formData.buildingId,
      eventValue: formData.buildingClickPrice,
      ad_plan: formData.adPlan,
      eventNonInteraction: "False",
    });

    formData.chips.forEach((chip) => {
      if (chip.label === "rooms") {
        sendEvent(
          `${QUESTION_CATEGORY}|${STEPS.START}|room_${chip.params}_final`,
          eventOptions
        );
      } else {
        sendEvent(`${QUESTION_CATEGORY}|${STEPS.START}|${chip.label}_final`, eventOptions);
      }
    });
    sendEvent(
      `${QUESTION_CATEGORY}|${STEPS.FORM}|${formData.communication_type}_final`,
      eventOptions
    );
    if (formData.email) sendEvent(getEventStr("email_true_final"), eventOptions);
  };

  const sendFinalEvents = () => {
    sendEvent(getEventStr("success_all_final"), eventOptions);
  };

  return {
    getEventStr,
    getEvent,
    sendEvent: (label) => sendEvent(getEventStr(label), eventOptions),
    sendSubmitQuestionEvents,
    sendFinalEvents,
    eventAction: stepName,
    eventCategory: QUESTION_CATEGORY,
    eventOptions,
  };
};

export default useDeveloperQuestionEvents;
