import React, { useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";
import ProjectCard from "components/ProjectCard";
import { getLastNews } from "../helpers/api";
import { GridPlaceholder } from "../placeholders/Placeholder";
import GridBlock from "../components/GridBlock/GridBlock";
import { gaEventActions as gaActions } from "../analytics";
import { useGaCategory } from "../analytics/useCategory";

const NewBuildings = () => {
  const [data, setData] = useState();
  const [catalogLink, setCatalogLink] = useState(null);
  const { getGaEvent: ga } = useGaCategory();

  useEffect(() => {
    getLastNews().then((response) => {
      if (response.status !== "ok") return;
      setData(response.data.new_objects);
      setCatalogLink(response.data.region_url);
    });
  }, []);

  if (!data) return <GridPlaceholder />;

  return (
    <GridBlock.Wrapper>
      <GridBlock.Header
        title={{ id: "newBuildings" }}
        withChip
        chip={{
          event: ga(gaActions.new_projects_list.goto_catalog),
          children: <FormattedMessage id="showAll" />,
          href: catalogLink,
          withLink: true,
        }}
      />
      <GridBlock.List>
        {data.map((item) => (
          <GridBlock.ListItem key={item.id}>
            <ProjectCard
              {...item}
              gaAction={gaActions.new_projects_list.name}
            />
          </GridBlock.ListItem>
        ))}
      </GridBlock.List>
    </GridBlock.Wrapper>
  );
};

export default NewBuildings;
