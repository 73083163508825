import React from "react";
import { attachGaDataAttributes } from "helpers/analytics";
import moment from "moment";
import { FormattedMessage } from "react-intl";
import { Language } from "../icons";
import { useLocale } from "../helpers/hooks";
import { useGaCategory } from "../analytics/useCategory";
import { FooterCurrency } from "./FooterCurrency";
import { gaEventActions as gaActions } from "../analytics";

import "./FooterMobile.scss";

const FooterMobile = () => {
  const [locale, switchLocale] = useLocale();
  const { getGaEvent: ga } = useGaCategory();
  const siteLink = `${process.env.REACT_APP_SITE_LINK}/${locale}`;

  const handleSwitchLocale = (e) => {
    e.preventDefault();
    e.stopPropagation();
    switchLocale();
  };

  return (
    <footer className="FooterMobile">
      <div className="wrapper">
        <section>
          <a
            href={`${siteLink}/terms`}
            {...attachGaDataAttributes(ga(gaActions.footer.goto_terms))}
          >
            <FormattedMessage id="footer.terms" />
          </a>
        </section>
        <section className="FooterMobile-row">
          <div
            className="FooterMobile-language"
            {...attachGaDataAttributes(
              ga(gaActions.footer.language_change_click)
            )}
            onClick={handleSwitchLocale}
          >
            <Language />
            {locale === "uk" ? "чужою мовою" : "українською"}
          </div>
          <FooterCurrency />
        </section>
        <section>
          <div className="FooterMobile-copy">
            <FormattedMessage
              id="footer.copyright"
              values={{ year: moment().get("year") }}
            />
          </div>
        </section>
      </div>
    </footer>
  );
};

export default FooterMobile;
