import { useState, useRef } from "react";

const useSwiper = () => {
  const [isPrev, setIsPrev] = useState(false);
  const [isNext, setIsNext] = useState(false);
  const swiperRef = useRef({});

  const onSwiper = (el) => {
    swiperRef.current = el;
    setArrows();
  };

  const onNextSlide = (e) => {
    e.preventDefault();
    if (!swiperRef.current) return;
    swiperRef.current.slideNext();
    setArrows();
  };

  const onPrevSlide = (e) => {
    e.preventDefault();
    if (!swiperRef.current) return;
    swiperRef.current.slidePrev();
    setArrows();
  };

  const setArrows = () => {
    if (!swiperRef.current) return;
    setIsNext(!swiperRef.current.isEnd);
    setIsPrev(!swiperRef.current.isBeginning);
  };

  return {
    swiperRef,
    isPrev,
    isNext,
    onSwiper,
    onNextSlide,
    onPrevSlide,
  };
};

export default useSwiper;
