import { QUESTION_CATEGORY } from "helpers/hooks/useDeveloperQuestionEvents";

export const sendEvent = (values, options = {}) => {
  const params =
    typeof values === "string"
      ? values.split("|")
      : [values.category, values.action, values.label];
  if (
    params[0] === "visit_form" ||
    params[2] === "success_developer_question_form"
  ) {
    console.log("%c%s", "color: #f06400", values, options);
  }
  if (params[0] === QUESTION_CATEGORY) {
    console.log("%c%s", "color: #168aad", values, options);
  }

  const [eventCategory, eventAction, eventLabel] = params;

  window.dataLayer.push({
    event: "my.lun.ua",
    eventCategory,
    eventAction,
    eventLabel,
    eventNonInteraction: false,
    building_id: params[3],
    ...options,
  });

  window.gtag('event', eventLabel, {
    pageType: eventCategory,
    placeOnPage: eventAction,
    loginStatus: window.isGuest ? 0 : 1,
    ...options,
  });
};

export const sendFavoritesChangeEvent = ({
  entity,
  actionType,
  buildingId,
  favorites,
  compares,
}) => {
  const event = {
    event: "my.lun.ua",
    eventCategory: "favorite",
    eventAction: actionType,
    eventLabel: entity,
    eventNonInteraction: false,
    building_id: buildingId,
  };
  if (favorites.buildings) {
    event.favorite_buildings = favorites.buildings.filter(
      (e) => e.type === "buildings"
    ).length;
    event.favorite_cottages = favorites.buildings.filter(
      (e) => e.type === "cottages"
    ).length;
  }
  if (favorites.layouts) {
    event.favorite_layouts = favorites.layouts.length;
  }
  if (compares) {
    event.compare_buildings = compares;
  }
  window.dataLayer.push(event);
};

export const initUserAnalytics = ({ id, buildings, cottages, layouts }) => {
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({ user_id: id });
  window.dataLayer.push({
    favorite_buildings: buildings,
    favorite_cottages: cottages,
    favorite_layouts: layouts,
  });
};

export const initFavoriteEntityAnalytics = (entity, favorites) => {
  window.dataLayer = window.dataLayer || [];
  if (entity === "layouts") {
    window.dataLayer.push({
      favorite_layouts: favorites.length,
    });
  }
  if (entity === "buildings") {
    window.dataLayer.push({
      favorite_buildings: favorites.filter((e) => e.type === "buildings")
        .length,
      favorite_cottages: favorites.filter((e) => e.type === "cottages").length,
    });
  }
};

export const sendCompareChangeEvent = ({ type, compares, buildingId }) => {
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({
    event: "my.lun.ua",
    eventCategory: "buildings",
    eventAction: "card",
    eventLabel: `compare_${type}`,
    eventNonInteraction: false,
    building_id: buildingId,
    compare_buildings: compares,
  });
};

export const initComparesAnalytics = (compares) => {
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({
    compare_buildings: compares,
  });
};

export const initTestingBranch = (data) => {
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({
    split_testing_version: data,
  });
};

export const initAppVersion = () => {
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({ appVersion: "1.0.0" });
};

// options string format: "key1:value1|key2:value2"
const getFormattedOptions = (serializedOptions) => {
  if (!serializedOptions) {
    return {};
  }

  return serializedOptions.split('|').reduce((acc, v) => {
    const [key, value] = v.split(':');

    return {
      ...acc,
      [key]: value,
    };
  }, {});
};

const getSerializedOptions = (options = {}) => {
  const optionsEntries = Object.entries(options);
  return optionsEntries.reduce((acc, [key, value], index) => {
    return `${key}:${value}${index < optionsEntries.length - 1 ? '|' : ''}`;
  }, '');
};

export const initAnalytics = () => {
  document.addEventListener(
    "click",
    (event) => {
      const nodes = document.querySelectorAll('[data-on="click"]');
      [].forEach.call(nodes, (node) => {
        if (node.contains(event.target)) {
          const { eventCategory, eventAction, eventLabel, eventOptions } = node.dataset;
          const options = getFormattedOptions(eventOptions);

          sendEvent(`${eventCategory}|${eventAction}|${eventLabel}`, options);
        }
      });
    },
    { capture: true }
  );
};

export const attachGaDataAttributes = (event) => {
  if (!event) return {};
  let eventOptions;
  if (event.options) {
    eventOptions = typeof event.options === 'string'
      ? event.options
      : getSerializedOptions(event.options);
  }

  return {
    "data-on": "click",
    "data-event-category": event.category,
    "data-event-action": event.action,
    "data-event-label": event.label || "click",
    ...(eventOptions && {
      "data-event-options": eventOptions,
    }),
  };
};
