export const ImageSize = {
  Building300: "building-300x300",
  Building600: "building-600x600",
  Building333x380: "building-333x380",
  Building666x760: "building-666x760",

  Layout277x173: "layout-277x173",
  Layout554x346: "layout-554x346",
  Layout398x255: "layout-398x255",
  Layout796x510: "layout-796x510",

  CompanyPremium: "company-premium",
};

export const getImage = (imageId, imageExtension) => (size) =>
  `https://img.lunstatic.net/${size}/${imageId}.${imageExtension}`;
