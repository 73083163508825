import { useState } from "react";

export const useRecaptcha = () => {
  const [loading, setLoading] = useState(false);
  const execute = (callback = () => {}) => {
    setLoading(true);
    const script = document.createElement("script");
    script.src = `https://www.google.com/recaptcha/api.js?render=${process.env.REACT_APP_RECAPTCHA_KEY}`;
    script.addEventListener("load", () => {
      window.grecaptcha.ready((_) => {
        window.grecaptcha
          .execute(process.env.REACT_APP_RECAPTCHA_KEY, { action: "submit" })
          .then((token) => {
            setLoading(false);
            script.remove();
            callback(token);
          });
      });
    });
    document.body.appendChild(script);
  };

  return {
    loading,
    execute,
  };
};
