import React, { useState } from "react";
import { useUserContext, useProfileMenuPage } from "helpers/hooks";
import { FormattedMessage } from "react-intl";
import { UIButton, UITypography, UIMenu } from "components/UIKit";
import cn from "classnames";
import { UserAvatar } from "components/UserAvatar";
import { ReactComponent as Ring } from "icons/ring-outline.svg";
import { ReactComponent as Domain } from "icons/domain.svg";
import { ReactComponent as Libra } from "icons/libra.svg";
import { ReactComponent as Favorite } from "icons/favorite-filled.svg";
import { ReactComponent as Message } from "icons/message.svg";
import { useBaseContext } from "../../helpers/hooks";
import { useGaCategory } from "../../analytics/useCategory";
import { gaEventActions as gaActions } from "../../analytics";
import { attachGaDataAttributes } from "../../helpers/analytics";
import styles from "./NavigationProfileMenu.module.scss";

const noop = () => {};

const NavigationId = {
  Favorites: "favorites",
  News: "news",
  Layouts: "layouts",
  Messages: "messages",
  Compares: "compares",
  Market: "market",
};

export const NavigationProfileMenuContent = ({
  onLoginClick = noop,
  onClose = noop,
}) => {
  const user = useUserContext();
  const { routes } = useBaseContext();
  const [loading, setLoading] = useState(false);
  const isProfileMenuPage = useProfileMenuPage();
  const { getGaEvent: ga } = useGaCategory();

  const NAVIGATION = [
    {
      id: NavigationId.Favorites,
      icon: <Favorite />,
      to: routes.main,
      event: gaActions.navigation_mylun.goto_my_favorites,
    },
    {
      id: NavigationId.Compares,
      icon: <Libra />,
      to: routes.compare,
      event: gaActions.navigation_mylun.goto_my_comparison,
    },
    {
      id: NavigationId.Layouts,
      icon: <Domain />,
      to: routes.plans,
      event: gaActions.navigation_mylun.goto_my_layouts,
    },
    {
      id: NavigationId.News,
      icon: <Ring />,
      to: routes.news,
      event: gaActions.navigation_mylun.goto_my_updates,
    },
    {
      id: NavigationId.Messages,
      icon: <Message />,
      to: routes.contacts,
      event: gaActions.navigation_mylun.goto_my_requests,
    },
  ];

  const sendClosePopupMessage = () => {
    window.parent.postMessage({ eventName: "myLun.closePopup" }, "*");
  };

  const sendLoginClickMessage = () => {
    window.parent.postMessage({ eventName: "myLun.loginClick" }, "*");
  };

  const sendLinkClickMessage = (link) => {
    window.parent.postMessage({ eventName: "myLun.linkClick", link }, "*");
  };

  const sendLogoutMessage = () => {
    window.parent.postMessage({ eventName: "myLun.logout" }, "*");
  };

  const handleLogout = async () => {
    try {
      setLoading(true);
      await user.actions.logout();
      setLoading(false);
      if (isProfileMenuPage) {
        sendLogoutMessage();
      } else {
        handleClose();
      }
    } catch (err) {
      setLoading(false);
    }
  };

  const handleClose = () => {
    onClose();
    sendClosePopupMessage();
  };

  return (
    <div className={cn({ [styles.page]: isProfileMenuPage })}>
      {user.state.id && (
        <div className={styles.profile}>
          <div className={styles.profileAvatar}>
            <UserAvatar src={user.state.avatar} />
          </div>
          <div className={styles.content}>
            <UITypography className={styles.title}>
              {user.state.username}
            </UITypography>
            <UITypography className={styles.caption} variant="caption">
              <FormattedMessage id="my.profile" />
            </UITypography>
          </div>
        </div>
      )}
      {!user.state.id && !isProfileMenuPage && (
        <div className={styles.loginHeader}>
          <div className={styles.content}>
            <UITypography className={styles.title}>
              <FormattedMessage id="auth.title" />
            </UITypography>
            <UITypography className={styles.caption} variant="body">
              <FormattedMessage id="auth.subtitle" />
            </UITypography>
          </div>
        </div>
      )}
      {isProfileMenuPage && (
        <div className={styles.menu}>
          {NAVIGATION.map((link) => (
            <div
              className={cn("UICell", styles.cell)}
              onClick={() => sendLinkClickMessage(link.to)}
              key={link.id}
              {...attachGaDataAttributes(link.event)}
            >
              <div className={cn("UICell-icon", styles.cellIcon)}>
                {link.icon}
              </div>
              <div className={cn("UICell-content", styles.cellContent)}>
                <FormattedMessage id={`my.${link.id}`} />
              </div>
            </div>
          ))}
        </div>
      )}
      <div className={styles.submit}>
        {user.state.id ? (
          <UIButton
            event={ga(gaActions.navigation_top_mylun.logout_click)}
            fullWidth
            onClick={handleLogout}
            loading={loading}
          >
            <FormattedMessage id="exit" />
          </UIButton>
        ) : (
          <UIButton
            appearance="primary"
            event={ga(gaActions.navigation_top_mylun.login_click)}
            fullWidth
            onClick={isProfileMenuPage ? sendLoginClickMessage : onLoginClick}
          >
            <FormattedMessage id="enter" />
          </UIButton>
        )}
      </div>
    </div>
  );
};
