import React, { useState, useEffect, useMemo } from "react";
import moment from "moment";
import { FormattedMessage } from "react-intl";
import { UIButton } from "components/UIKit/Button/index";
import { attachGaDataAttributes } from "helpers/analytics";
import { TELEGRAM_SUPPORT_LINK } from "constants/base";
import FooterProject from "./FooterProject";
import * as Icon from "../icons";
import { gaEventActions as gaActions } from "../analytics";
import { useGaCategory } from "../analytics/useCategory";
import { FooterCurrency } from "./FooterCurrency";
import { useLocale } from "../helpers/hooks";
import { getTerritoryLabel } from "../helpers/api";
import { ApiResponseStatus } from "../constants/base";
import AppLinks from "components/AppLinks";
import "./Footer.scss";

const Footer = () => {
  const { getGaEvent: ga } = useGaCategory();
  const [locale, switchLocale] = useLocale();
  const siteLink = `${process.env.REACT_APP_SITE_LINK}/${locale}`;
  const [territoryLabel, setTerritoryLabel] = useState();

  const cosmosLink = useMemo(() => {
    const base = "https://cosmos.lun.ua/boost";
    if (!territoryLabel) return base;
    return `${base}?region=${territoryLabel}`;
  }, [territoryLabel]);

  const handleSwitchLocale = (e) => {
    e.preventDefault();
    e.stopPropagation();
    switchLocale();
  };

  const fetchTerritoryLabel = async () => {
    try {
      const res = await getTerritoryLabel();
      if (res.status !== ApiResponseStatus.Ok) throw new Error(res.message);
      setTerritoryLabel(res.data);
    } catch (err) {}
  };

  useEffect(() => {
    fetchTerritoryLabel();
  }, []);

  return (
    <footer className="Footer">
      <div className="wrapper">
        <div className="FooterTop">
          <a
            href={siteLink}
            className="Footer-logo"
            {...attachGaDataAttributes(ga(gaActions.footer.goto_main_logo))}
          >
            <Icon.LogoLight />
          </a>
          <div className="FooterSocialLinks">
            <div className="Footer-section-title">
              <FormattedMessage id="footer.social" />
            </div>
            <div className="FooterSocialLinksContent">
              <UIButton
                appearance="ghost-light"
                size="small"
                event={ga(gaActions.footer.goto_instagram)}
                icon={<Icon.Instagram />}
                attributes={{
                  href: "https://www.instagram.com/lun.ua/",
                  target: "_blank",
                }}
              />
              <UIButton
                appearance="ghost-light"
                size="small"
                event={ga(gaActions.footer.goto_twitter)}
                icon={<Icon.Twitter />}
                attributes={{
                  href: "https://twitter.com/lun_ua",
                  target: "_blank",
                }}
              />
              <UIButton
                appearance="ghost-light"
                size="small"
                event={ga(gaActions.footer.goto_youtube)}
                icon={<Icon.Youtube />}
                attributes={{
                  href: "https://www.youtube.com/c/lunua",
                  target: "_blank",
                }}
              />
              <UIButton
                appearance="ghost-light"
                size="small"
                event={ga(gaActions.footer.goto_facebook)}
                icon={<Icon.FacebookOutline />}
                attributes={{
                  href: "https://www.facebook.com/lun.ua",
                  target: "_blank",
                }}
              />
            </div>
          </div>
        </div>

        <hr className="FooterLine -desktop-only" />

        <div className="FooterMain">
          <div className="FooterMainLinks">
            <div className="FooterMainLinksBlock">
              <div className="Footer-section-title">
                <FormattedMessage id="footer.about" />
              </div>
              <a
                className="FooterLink"
                href="https://team.lun.ua/"
                target="_blank"
                {...attachGaDataAttributes(ga(gaActions.footer.goto_careers))}
              >
                {/* Вакансії */}
                <FormattedMessage id="footer.vacancies" />
              </a>
              <a
                className="FooterLink"
                href={`${siteLink}/faq`}
                {...attachGaDataAttributes(ga(gaActions.footer.goto_faq))}
              >
                FAQ
              </a>
              <a
                className="FooterLink"
                target="_blank"
                href={cosmosLink}
                {...attachGaDataAttributes(
                  ga(gaActions.footer.for_developers_placement)
                )}
              >
                {/* Девелоперам: реклама на ЛУН */}
                <FormattedMessage id="footer.for_developers_placement" />
              </a>
              <a
                className="FooterLink"
                target="_blank"
                href="https://my.rieltor.ua/"
                {...attachGaDataAttributes(ga(gaActions.footer.for_rieltors))}
              >
                {/* Рієлторам: розмістити оголошення */}
                <FormattedMessage id="footer.for_rieltor" />
              </a>
              <a
                className="FooterLink"
                target="_blank"
                href="https://lun.page.link/lun_create_ads_button/"
                {...attachGaDataAttributes(ga(gaActions.footer.for_owners))}
              >
                {/* Власникам: розмістити оголошення через додаток ЛУН */}
                <FormattedMessage id="footer.for_owners" />
              </a>
            </div>

            <div className="FooterMainLinksBlock">
              <div className="Footer-section-title">Контакти</div>
              <a
                className="FooterLink"
                target="_blank"
                href="https://misto.lun.ua/pr"
                {...attachGaDataAttributes(
                  ga(gaActions.footer.contacts_for_mass_media)
                )}
              >
                {/* Для ЗМІ */}
                <FormattedMessage id="footer.contacts_for_mass_media" />
              </a>
              <a
                className="FooterLink"
                target="_blank"
                href={TELEGRAM_SUPPORT_LINK}
                {...attachGaDataAttributes(
                  ga(gaActions.footer.telegram_support)
                )}
              >
                {/* Команда підтримки */}
                <FormattedMessage id="footer.telegram_support" />
                <Icon.TelegramRounded />
              </a>

              <AppLinks className="Footer-apps" eventAction="footer" />
            </div>
          </div>

          <hr className="FooterLine -mobile-only" />

          <div className="OtherLunProjects">
            <div className="OtherLunProjectsHeader">
              <div className="Footer-section-title OtherLunProjectsHeader-title">
                {/* Iнші проєкти */}
                <FormattedMessage id="footer.other_projects" />
              </div>
              <div className="OtherLunProjectsHeaderLogo">
                <div className="OtherLunProjectsHeaderLogo-prefix">від</div>
                <Icon.LogoLight />
              </div>
            </div>
            <div className="OtherLunProjectsList">
              <FooterProject
                link="https://misto.lun.ua/"
                icon={<Icon.Misto />}
                event={gaActions.footer.goto_lunmisto}
              >
                <FormattedMessage id="footer.misto" values={{ br: <br /> }} />
              </FooterProject>
              <FooterProject
                link="https://rieltor.ua/"
                icon={<Icon.Rieltor />}
                event={gaActions.footer.goto_rieltor}
              >
                <FormattedMessage id="footer.rieltor" values={{ br: <br /> }} />
              </FooterProject>
              <FooterProject
                link="https://apps.apple.com/app/apple-store/id1446261104?pt=102173849&amp;ct=lun-footer&amp;mt=8"
                icon={<Icon.Bird />}
                event={gaActions.footer.goto_bird}
              >
                <FormattedMessage id="footer.bird" values={{ br: <br /> }} />
              </FooterProject>
              <FooterProject
                link="https://korter.com/"
                icon={<Icon.Korter />}
                event={gaActions.footer.goto_korter}
              >
                <FormattedMessage id="footer.korter" values={{ br: <br /> }} />
              </FooterProject>
              <FooterProject
                link="https://redcommunity.ua/"
                icon={<Icon.RedCommunity />}
                event={gaActions.footer.goto_red}
              >
                <FormattedMessage
                  id="footer.redCommunity"
                  values={{ br: <br /> }}
                />
              </FooterProject>
              <FooterProject
                link="https://www.dobovo.com/ua/"
                icon={<Icon.Dobovo />}
                event={gaActions.footer.goto_dobovo}
              >
                <FormattedMessage id="footer.dobovo" />
              </FooterProject>
            </div>
          </div>
        </div>

        <hr className="FooterLine" />

        <div className="FooterBottom">
          <div className="FooterCopyright">
            <div className="FooterCopyright-text">
              <FormattedMessage
                id="footer.copyright"
                values={{ year: moment().get("year") }}
              />
            </div>
          </div>

          <div className="FooterBottomAside">
            <a
              href={`${siteLink}/terms`}
              className="FooterLink"
              {...attachGaDataAttributes(ga(gaActions.footer.goto_terms))}
            >
              <FormattedMessage id="footer.terms" />
            </a>
            <div
              className="FooterLink -icon-left -icon-small"
              {...attachGaDataAttributes(
                ga(gaActions.footer.language_change_click)
              )}
              onClick={handleSwitchLocale}
            >
              <Icon.Language />
              {locale === "uk" ? "чужою мовою" : "українською"}
            </div>
            <div className="Footer-currency">
              <FooterCurrency appearance="overlay" />
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
