import React, { useState } from "react";
import { AUTH_STEP } from "constants/auth";
import styles from "./Authorization.module.scss";
import AuthorizationMain from "./AuthorizationMain";
import AuthorizationPhoneCode from "./AuthorizationPhoneCode";
import AuthorizationEmail from "./AuthorizationEmail";

const Authorization = ({ onClose = () => {} }) => {
  const [step, setStep] = useState(AUTH_STEP.MAIN);
  const [phone, setPhone] = useState();

  const handleEmailClose = () => {
    onClose();
    setTimeout(() => setStep(AUTH_STEP.MAIN), 300);
  };

  return (
    <div className={styles.root}>
      {step === AUTH_STEP.MAIN && (
        <AuthorizationMain
          setStep={setStep}
          setPhone={setPhone}
          onClose={onClose}
        />
      )}
      {step === AUTH_STEP.CODE && (
        <AuthorizationPhoneCode phone={phone} onClose={onClose} />
      )}
      {step === AUTH_STEP.EMAIL && (
        <AuthorizationEmail onClose={handleEmailClose} />
      )}
    </div>
  );
};

export default Authorization;
