import React, { useMemo } from "react";
import { Card } from "@flatfy/supernova-advanced";
import { useFavoriteButton, useUahCurrency } from "helpers/hooks";
import { useGaCategory } from "analytics/useCategory";
import { attachGaDataAttributes, sendEvent } from "helpers/analytics";
import { gaEventActions as gaActions } from "analytics";
import { getImage, ImageSize } from "helpers/getImageUrl";

const ProjectCard = ({
  id,
  name,
  address,
  url,
  cover,
  is_risky,
  label,
  price,
  other_companies,
  developer,
  city,
  gaAction,
}) => {
  const { getGaEvent: ga, getGaEventString: gaStr } = useGaCategory();
  const isUahCurrency = useUahCurrency();
  const getCoverBySize = getImage(cover?.image_id, cover?.image_format);
  const favorite = useFavoriteButton({
    id,
    options: {
      onRemove: () => {
        sendEvent(gaStr(gaActions[gaAction].favorites_add_click));
      },
      onAdd: () => {
        sendEvent(gaStr(gaActions[gaAction].favorites_remove_click));
      },
    },
  });

  const coverUrl = useMemo(() => {
    if (!cover) return null;
    return getCoverBySize(ImageSize.Building333x380);
  }, [cover, getCoverBySize]);

  const coverUrl2x = useMemo(() => {
    if (!cover) return null;
    return getCoverBySize(ImageSize.Building666x760);
  }, [cover, getCoverBySize]);

  const normalizedDeveloper = useMemo(() => {
    if (!developer) return undefined;
    const developerImage = developer.small_round_logo;
    return {
      id: developer.id,
      isPremium: developer.is_premium,
      name: developer.name,
      cardColor: developer.card_color,
      textColor: developer.text_color,
      logo: developerImage
        ? getImage(
            developerImage.image_id,
            developerImage.image_format
          )(ImageSize.CompanyPremium)
        : undefined,
    };
  }, [developer]);

  const normalizedOtherCompanies = useMemo(() => {
    if (!other_companies || !other_companies.names || !other_companies.logos)
      return undefined;
    return other_companies;
  }, [other_companies]);

  return (
    <Card
      name={name || address}
      url={url}
      cover={coverUrl}
      cover2x={coverUrl2x}
      isRisky={is_risky}
      label={label}
      price={price && (isUahCurrency ? price.value : price.value_usd)}
      developer={normalizedDeveloper}
      otherCompanies={normalizedOtherCompanies}
      location={city}
      LinkAttributes={attachGaDataAttributes(
        ga(gaActions[gaAction].goto_view_project)
      )}
      FavoriteButtonProps={{
        value: favorite.isActiveFavorite,
        onChange: favorite.onChange,
      }}
    />
  );
};

export default ProjectCard;
