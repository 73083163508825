import React from "react";
import PropTypes from "prop-types";
import cn from "classnames";
import { FormattedMessage } from "react-intl";
import styles from "./ReportError.module.scss";
import { TELEGRAM_SUPPORT_LINK } from "constants/base";
import { gaEventActions as gaActions } from "../../../analytics";
import { useGaCategory } from "../../../analytics/useCategory";
import { attachGaDataAttributes } from "helpers/analytics";

export const UIReportError = ({ className, classes = {} }) => {
  const { getGaEvent: ga } = useGaCategory();
  return (
    <div className={cn(className, classes.root, styles.root)}>
      <a
        href={TELEGRAM_SUPPORT_LINK}
        target="_blank"
        rel="noopener noreferrer"
        className={cn(classes.content, styles.content)}
        {...attachGaDataAttributes(ga(gaActions.support.click))}
      >
        <FormattedMessage id="report-error" />
      </a>
    </div>
  );
};

UIReportError.propTypes = {
  className: PropTypes.string,
  classes: PropTypes.shape({
    root: PropTypes.string,
    content: PropTypes.string,
  }),
};
