import React, { useContext } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import FormTerms from "components/FormTerms/FormTerms";
import { useDeveloperQuestionSupport } from "helpers/hooks";
import { RefreshComponents } from "@flatfy/supernova-new";
import AnalyticsElement from "../../../components/AnalyticsElement";
import {
  COMMUNICATION_TYPE,
  COMMUNICATION_TYPE_LABELS,
} from "../../../constants/developer-question";
import { Field, FormTextField } from "../../../components/FormRefresh/Field";
import { FormContext } from "../../../components/FormRefresh";
import { attachGaDataAttributes } from "../../../helpers/analytics";
import styles from "../DeveloperQuestion.module.scss";

const DeveloperQuestionPersonalInfo = ({ analytics, loading, callMeBack, parentDesktop }) => {
  const intl = useIntl();
  const isSupport = useDeveloperQuestionSupport();
  const form = useContext(FormContext);
  const isEmailRequired =
    form.value.communication_type === COMMUNICATION_TYPE.EMAIL;

  return (
    <>
      <RefreshComponents.Typography
        variant="subtitle2"
        component="div"
        className={styles.title}
      >
        <FormattedMessage id="question.popup.contacts.title" />
      </RefreshComponents.Typography>
      <div className={styles.row}>
        <FormTextField
          name="name"
          variant="outline"
          label={intl.formatMessage({ id: "name.surname" })}
          onError={(err) => analytics.sendEvent(`name_${err}_error`)}
          InputProps={attachGaDataAttributes(analytics.getEvent("name_type"))}
          classes={{ helperText: styles.helperText }}
          validate
          required
        />
      </div>
      <div className={styles.row}>
        <FormTextField
          name="phone"
          isPhone
          variant="outline"
          label={intl.formatMessage({ id: "phone" })}
          required={!isSupport}
          onError={(err) => analytics.sendEvent(`phone_${err}_error`)}
          classes={{ helperText: styles.helperText }}
          InputProps={attachGaDataAttributes(analytics.getEvent("phone_type"))}
          validate
        />
      </div>
      {!callMeBack && (
        <>
          <div className={styles.row}>
            <FormTextField
              name="email"
              variant="outline"
              label={
                isEmailRequired
                  ? "E-mail"
                  : intl.formatMessage({ id: "email.optional" })
              }
              required={isEmailRequired}
              onError={(err) => analytics.sendEvent(`email_${err}_error`)}
              InputProps={{
                type: "email",
                ...attachGaDataAttributes(analytics.getEvent("email_type")),
              }}
              classes={{ helperText: styles.helperText }}
              validate
            />
          </div>
          <div className={styles.row}>
            <Field name="communication_type">
              {({ value, onChange }) => (
                <RefreshComponents.Select
                  value={value}
                  label={intl.formatMessage({ id: "communication" })}
                  onChange={onChange}
                  renderValue={(v) => COMMUNICATION_TYPE_LABELS[v]}
                  MenuProps={{ fullScreenMobile: !parentDesktop }}
                  InputProps={{
                    readOnly: true,
                    InputProps: attachGaDataAttributes(
                      analytics.getEvent("comms_type_select_click")
                    ),
                  }}
                >
                  {Object.entries(COMMUNICATION_TYPE_LABELS).map(
                    ([key, label]) => (
                      <AnalyticsElement
                        element={RefreshComponents.MenuItem}
                        key={key}
                        value={key}
                        eventAction={analytics.eventAction}
                        eventCategory={analytics.eventCategory}
                        eventLabel={key}
                        eventOptions={analytics.eventOptions}
                      >
                        {label}
                      </AnalyticsElement>
                    )
                  )}
                </RefreshComponents.Select>
              )}
            </Field>
          </div>
        </>
      )}
      <FormTerms className={styles.terms} />

      <div className={styles.submit}>
        <div className={styles.bottomAction}>
          <AnalyticsElement
            element={RefreshComponents.Button}
            variant="primary"
            onClick={form.onSubmit}
            loading={loading}
            eventCategory={analytics.eventCategory}
            eventAction={analytics.eventAction}
            eventLabel="send"
            eventOptions={analytics.eventOptions}
            fullWidth
          >
            <FormattedMessage id="send" />
          </AnalyticsElement>
        </div>
      </div>
    </>
  );
};

export default DeveloperQuestionPersonalInfo;
