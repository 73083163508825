import { useRouteMatch } from "react-router-dom";
import { useBaseContext } from "helpers/hooks";

const useFavoriteLinkActive = () => {
  const { routes } = useBaseContext();
  const isContactsPage = useRouteMatch(routes.message);
  const isLayoutsPage = useRouteMatch(routes.plans);
  const isComparesPage = useRouteMatch(routes.compare);

  return isContactsPage || isLayoutsPage || isComparesPage;
};

export default useFavoriteLinkActive;
