import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useUrlParams, useBaseContext } from "helpers/hooks";
import Unsubscribe from "./Unsubscribe";
import {
  unsubscribeBuilding,
  unsubscribeCompany,
  unsubscribeRegion,
} from "../../helpers/api";
import styles from "./Unsubscribe.module.scss";
import {
  CardPlaceholder,
  RowPlaceholder,
} from "../../placeholders/Placeholder";

const UnsubscribeLoading = () => (
  <div className={styles.root}>
    <RowPlaceholder />
    <CardPlaceholder size="small" subtitle={false} />
    <br />
    <CardPlaceholder size="medium" />
  </div>
);

const useUnsubscribe = (promise, required) => {
  const history = useHistory();
  const { routes } = useBaseContext();
  const { urlParams } = useUrlParams();
  const [data, setData] = useState(null);

  useEffect(() => {
    if (required.some((e) => !urlParams[e])) {
      history.push({ pathname: routes.main, search: "" });
    }
    promise(urlParams)
      .then((res) => {
        if (res.status !== "ok") throw new Error();
        setData(res.data);
      })
      .catch(() => {
        history.push({ pathname: routes.main, search: "" });
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return { data, urlParams };
};

export const UnsubscribeRegion = () => {
  const { urlParams, data } = useUnsubscribe(unsubscribeRegion, [
    "token",
    "rid",
  ]);
  if (!data) return <UnsubscribeLoading />;
  return <Unsubscribe type="region" params={{ ...urlParams, ...data }} />;
};

export const UnsubscribeBuilding = () => {
  const { urlParams, data } = useUnsubscribe(unsubscribeBuilding, [
    "token",
    "bid",
  ]);
  if (!data) return <UnsubscribeLoading />;
  return <Unsubscribe type="building" params={{ ...urlParams, ...data }} />;
};

export const UnsubscribeCompany = () => {
  const { urlParams, data } = useUnsubscribe(unsubscribeCompany, [
    "token",
    "cid",
  ]);
  if (!data) return <UnsubscribeLoading />;
  return <Unsubscribe type="company" params={{ ...urlParams, ...data }} />;
};
