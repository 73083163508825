import { useIntl } from "react-intl";
import * as WALL_TYPE from "../constants/wallType";

const MESSAGES = {
  [WALL_TYPE.BLOCK]: "block",
  [WALL_TYPE.MONOLITHIC_FRAME]: "monolithic",
  [WALL_TYPE.PANEL]: "panel",
  [WALL_TYPE.INSULATED_PANEL]: "insulatedPanel",
  [WALL_TYPE.BRICK]: "brick",
};

export const useWallType = (value) => {
  const intl = useIntl();
  if (!value) return null;
  return intl.formatMessage({ id: `market.wallType.${MESSAGES[value]}` });
};
