import * as Sentry from "@sentry/browser";
import { fetchActionTypes } from "../../components/FetchHelper/fetchReducer";
import {
  getUserData,
  login as loginPost,
  logout as logoutPost,
  loginFacebook,
  loginPhone,
  loginTelegram,
  loginToken,
  loginGoogle,
  getIsSubscribed,
  subscribeEmail,
  unsubscribeGlobalNews,
  updateUserData,
} from "../../helpers/api";
import { USER_TYPES } from "./reducer";
import { sendEvent } from "../../helpers/analytics";

const { SUCCESS, FAILURE, LOADING } = fetchActionTypes;

export const getCachedUser = () => {
  const cachedUser = JSON.parse(
    localStorage.getItem("userData") || JSON.stringify({ isLoaded: false })
  );
  cachedUser.isGuest = cachedUser.is_guest;
  if (cachedUser.id === "") delete cachedUser.id;
  delete cachedUser.is_guest;
  return cachedUser;
};

const setCachedUser = (data) => {
  localStorage.setItem("userData", JSON.stringify(data));
};

const postLogin = ({ promise, helper, dispatch, preventLoading }) => {
  if (!preventLoading) {
    helper({ type: LOADING });
  }
  return promise()
    .then((res) => {
      if (res.data.id) {
        dispatch({ type: USER_TYPES.LOGIN, payload: res.data });
        sendEvent("auth|login|success");
        Sentry.configureScope((scope) => {
          scope.setUser({ userId: res.data.id });
        });
      }
      helper({ type: SUCCESS });
      setCachedUser(res.data);
      return res;
    })
    .catch(() => helper({ type: FAILURE }));
};

const phone = (args) => (data) =>
  postLogin({
    promise: () => loginPhone(data),
    preventLoading: true,
    ...args,
  });

const facebook = (args) => (data) =>
  postLogin({ promise: () => loginFacebook(data), ...args });

const google = (args) => (data) =>
  postLogin({ promise: () => loginGoogle(data), ...args });

const telegram = (args) => (data) =>
  postLogin({ promise: () => loginTelegram(data), ...args });

const login = (args) => (data) =>
  postLogin({
    promise: () => loginPost(data),
    preventLoading: true,
    ...args,
  });

const receiveInitSubscription = ({ dispatch, response }) => {
  const {
    subscribed: isSubscribed,
    showButton: canSubscribe,
    type: loginType,
  } = response.data;
  dispatch({
    type: USER_TYPES.INIT_SUBSCRIPTION,
    payload: { isSubscribed, canSubscribe, loginType },
  });
};

const fetch = ({ helper, dispatch }) => () => {
  getUserData()
    .then((res) => {
      if (res.status === "error") throw new Error();
      if (res.data.is_guest) {
        dispatch({ type: USER_TYPES.LOGOUT, payload: res.data });
      } else {
        dispatch({ type: USER_TYPES.LOGIN, payload: res.data });
      }
      helper({ type: SUCCESS });
      setCachedUser(res.data);
    })
    .catch(() => {
      helper({ type: FAILURE });
      dispatch({ type: USER_TYPES.LOGOUT });
    });
};

const update = ({ dispatch }) => async (data) => {
  const res = await updateUserData(data);
  if (res.status === "error") throw new Error();
  dispatch({ type: USER_TYPES.UPDATE, payload: res.data });
};

const logout = ({ helper, dispatch }) => () =>
  logoutPost()
    .then(() => {
      helper({ type: SUCCESS });
      dispatch({ type: USER_TYPES.LOGOUT });
      sendEvent("auth|logout|success");
      setCachedUser({ isGuest: true });
    })
    .catch(() => {
      helper({ type: FAILURE });
    });

export const tokenMessage = (err) =>
  err === "token_invalid" ? "login.error.invalidToken" : "login.error.common";

const token = ({ helper, dispatch }) => (data) =>
  loginToken(data)
    .then((response) => {
      if (response.status === "error") throw response.message;
      helper({ type: SUCCESS });
      dispatch({ type: USER_TYPES.LOGIN, payload: response.data });
      sendEvent("auth|login|success");
      setCachedUser(response.data);
      return { success: true };
    })
    .catch((err) => ({
      success: false,
      message: tokenMessage(err),
    }));

const initSubscription = ({ helper, dispatch }) => () => {
  getIsSubscribed()
    .then((response) => {
      if (response.status === "error") throw response.message;
      helper({ type: SUCCESS });
      receiveInitSubscription({ dispatch, response });
    })
    .catch(() => {
      helper({ type: FAILURE });
    });
};

const subscribeEmailAction = ({ helper, dispatch }) =>
  subscribeEmail()
    .then((response) => {
      if (response.status === "error") throw response.message;
      helper({ type: SUCCESS });
      dispatch({ type: USER_TYPES.SUBSCRIBE });
      sendEvent("news_global|subscribe|email");
      return true;
    })
    .catch(() => {
      helper({ type: FAILURE });
    });

const subscribe = (params) => () => {
  return subscribeEmailAction(params);
};

const unsubscribe = ({ helper, dispatch }) => () =>
  unsubscribeGlobalNews()
    .then((response) => {
      if (response.status === "error") throw response.message;
      helper({ type: SUCCESS });
      dispatch({ type: USER_TYPES.UNSUBSCRIBE });
      sendEvent("news_global|unsubscribe|success");
      return response.status === "ok";
    })
    .catch(() => {
      helper({ type: FAILURE });
    });

const setData = ({ dispatch }) => (data) => {
  dispatch({ type: USER_TYPES.LOGIN, payload: data });
};

export const userPostActions = {
  google,
  telegram,
  facebook,
};

export const userActions = {
  token,
  login,
  logout,
  fetch,
  phone,
  initSubscription,
  subscribe,
  unsubscribe,
  setData,
  update,
};
