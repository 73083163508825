import React, { useEffect } from "react";
import { FormattedMessage } from "react-intl";
import cx from "classnames";
import { RefreshComponents } from "@flatfy/supernova-new";
import AnalyticsElement from "components/AnalyticsElement";

import { ReactComponent as CheckIcon } from "icons/check-circle-success-outline.svg";
import styles from "../DeveloperQuestion.module.scss";

const DeveloperQuestionFinal = ({
  building,
  analytics,
  onClose,
  callMeBack,
}) => {
  useEffect(() => {
    analytics.sendFinalEvents();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className={cx(styles.step, styles.final)}>
      <div className={styles.finalContent}>
        <CheckIcon />
        <RefreshComponents.Typography variant="h6" className={styles.title}>
          <FormattedMessage
            id={
              callMeBack
                ? "question.final.callMeBackTitle"
                : "question.final.title"
            }
          />
        </RefreshComponents.Typography>
        <RefreshComponents.Typography
          variant="caption"
          className={styles.description}
        >
          <FormattedMessage
            id={
              callMeBack
                ? "question.final.callMeBackDescription"
                : "question.final.description"
            }
          />
        </RefreshComponents.Typography>
      </div>
      <div className={styles.bottomAction}>
        <AnalyticsElement
          element={RefreshComponents.Button}
          variant="primary"
          fullWidth
          eventCategory={analytics.eventCategory}
          eventAction={analytics.eventAction}
          eventLabel="close_click"
          eventOptions={analytics.eventOptions}
          onClick={onClose}
        >
          <FormattedMessage
            id="back.building"
            values={{ building: building.building_name }}
          />
        </AnalyticsElement>
      </div>
    </div>
  );
};

export default DeveloperQuestionFinal;
