export const CONFIRMATION_TYPES = {
  SUCCESS_PHONE: "success_phone",
  SUCCESS_EMAIL: "success_email",
};

export const COMMUNICATION_TYPE = {
  EMAIL: "by_email",
  VIBER: "by_viber",
  TELEGRAM: "by_telegram",
  PHONE: "by_phone",
};

export const NORMALIZED_SOCIAL_EVENTS = {
  [COMMUNICATION_TYPE.EMAIL]: "email",
  [COMMUNICATION_TYPE.VIBER]: "viber",
  [COMMUNICATION_TYPE.TELEGRAM]: "telegram",
};

export const COMMUNICATION_TYPE_LABELS = {
  [COMMUNICATION_TYPE.EMAIL]: "E-mail",
  [COMMUNICATION_TYPE.VIBER]: "Viber",
  [COMMUNICATION_TYPE.TELEGRAM]: "Telegram",
  [COMMUNICATION_TYPE.PHONE]: "По телефону",
};
