import React, { createContext, useState } from "react";

export const FormContext = createContext({});

const Form = ({ initialValues = {}, onSubmit, children }) => {
  const [values, setValues] = useState(initialValues);
  const [validators, setValidators] = useState({});

  const handleChange = (name, value) => {
    setValues({
      ...values,
      [name]: value,
    });
  };

  const handleSubmit = () => {
    const isValid = Object.values(validators).map((fn) => Boolean(fn?.())).every(v => v);
    if (isValid && onSubmit) {
      onSubmit(values);
    }
  };

  const handleAddValidator = (value) => {
    setValidators((prev) => ({
      ...prev,
      ...value,
    }));
  };

  const value = {
    value: values,
    onChange: handleChange,
    onSubmit: handleSubmit,
    addValidator: handleAddValidator,
  }

  return (
    <FormContext.Provider value={value}>
      {typeof children === 'function' ? children(value) : children}
    </FormContext.Provider>
  );
};

export default Form;
