import React from "react";
import { useFavoriteContext, useUserContext } from "helpers/hooks";
import HomeGreetings from "./HomeGreetings/HomeGreetings";
import LastVisited from "../LastVisited/LastVisited";
import {
  GridPlaceholder,
  TwoColumnGridPlaceholder,
} from "../placeholders/Placeholder";
import Subscription from "../Subscription/Subscription";
import NewBuildings from "./NewBuildings";
import FavoriteBuildings from "../FavoriteBuildings";
import { UIGrid } from "../components/UIKit";
import { ContactUs } from "../ContactUs";
import styles from "./Home.module.scss";

const Home = () => {
  const user = useUserContext();
  const favorites = useFavoriteContext();

  if (!user.state.isLoaded) {
    return (
      <div>
        <GridPlaceholder />
        <GridPlaceholder />
        <GridPlaceholder wide />
      </div>
    );
  }

  const homeFooter = (
    <>
      <LastVisited gaCategory="mylun_main" />
      <NewBuildings />
      <UIGrid container alwaysWrapLg alwaysWrapMd alwaysWrapSm noMobileGallery>
        <UIGrid item size={8} md={12}>
          <Subscription />
        </UIGrid>
        <UIGrid item size={4} md={12}>
          <ContactUs />
        </UIGrid>
      </UIGrid>
    </>
  );

  if (favorites.idsLoading) {
    return (
      <>
        <TwoColumnGridPlaceholder />
        {homeFooter}
      </>
    );
  }

  if (favorites.ids.length === 0) {
    return (
      <div className={styles.root}>
        <HomeGreetings />
        {homeFooter}
      </div>
    );
  }

  return (
    <div className={styles.root}>
      <FavoriteBuildings />
      {homeFooter}
    </div>
  );
};

export default Home;
