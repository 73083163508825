import React, { useEffect, useCallback } from "react";
import { useUrlParams } from "helpers/hooks";
import { FormattedMessage } from "react-intl";
import { useList } from "react-use";
import { Chips, RefreshComponents } from "@flatfy/supernova-new";
import styles from "./DeveloperQuestionChips.module.scss";
import AnalyticsElement from "../../../components/AnalyticsElement";

const MULTILFOOR_ROOM_COUNT = 9;

const DeveloperQuestionChips = ({
  building,
  onChange,
  analytics,
  isQuestionFromFlats,
}) => {
  const { urlParams } = useUrlParams();
  const CHIP_LIST = [
    { id: "installments", visible: building.installments },
    { id: "credit", visible: building.credit },
    { id: "promo", visible: true },
  ];
  const [chips, chipsActions] = useList([]);
  const [rooms, roomsActions] = useList([]);

  const handleChipClick = useCallback(
    (id) => {
      const chipIndex = chips.indexOf(id);
      if (chipIndex === -1) {
        chipsActions.push(id);
      } else {
        chipsActions.removeAt(chipIndex);
      }
    },
    [chips, chipsActions]
  );

  const handleRoomClick = useCallback(
    (id) => {
      const roomIndex = rooms.indexOf(id);
      if (roomIndex === -1) {
        roomsActions.push(id);
      } else {
        roomsActions.removeAt(roomIndex);
      }
    },
    [rooms, roomsActions]
  );

  useEffect(() => {
    if (
      urlParams.placement &&
      CHIP_LIST.some((e) => e.id === urlParams.placement && e.visible)
    ) {
      chipsActions.push(urlParams.placement);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    onChange("chips", [
      ...[...chips].map((chipId) => ({ label: chipId })),
      ...[...rooms].map((roomId) => ({ label: "rooms", params: roomId })),
    ]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rooms, chips]);

  return (
    <div className={styles.chips}>
      <Chips wrap>
        {CHIP_LIST.filter((chip) => chip.visible).map((chip) => {
          return (
            <AnalyticsElement
              element={RefreshComponents.Chip}
              active={chips.includes(chip.id)}
              onClick={() => handleChipClick(chip.id)}
              key={chip.id}
              eventCategory={analytics.eventCategory}
              eventAction={analytics.eventAction}
              eventLabel={`${chip.id}_click`}
              eventOptions={analytics.eventOptions}
            >
              <FormattedMessage id={`question.chips.${chip.id}`} />
            </AnalyticsElement>
          );
        })}
        {!isQuestionFromFlats &&
          building.rooms &&
          !building.is_cottage &&
          building.rooms.map((roomCount, index) => (
            <AnalyticsElement
              element={RefreshComponents.Chip}
              active={rooms.includes(roomCount)}
              onClick={() => handleRoomClick(roomCount)}
              key={roomCount}
              eventCategory={analytics.eventCategory}
              eventAction={analytics.eventAction}
              eventLabel={`room_${roomCount}_click`}
              eventOptions={analytics.eventOptions}
            >
              {roomCount === MULTILFOOR_ROOM_COUNT ? (
                <FormattedMessage id="question.chips.room.multifloor" />
              ) : (
                <FormattedMessage
                  id={`question.chips.room${index === 0 ? ".full" : ""}`}
                  values={{ room: roomCount }}
                />
              )}
            </AnalyticsElement>
          ))}
      </Chips>
    </div>
  );
};

export default DeveloperQuestionChips;
