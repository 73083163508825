import React, { useState } from "react";
import { useUserContext, useNotification, useForm } from "helpers/hooks";
import { FormattedMessage } from "react-intl";
import { UITypography } from "../components/UIKit/Typography";
import { ERROR_LOCALE_CODE, ERROR_CODE } from "constants/auth";
import { UILoader } from "../components/UIKit/Loader";
import { UIForm } from "../components/UIKit/Form";
import { UIButton } from "../components/UIKit/Button";
import { UIInput } from "../components/UIKit/Input";
import { sendEvent } from "helpers/analytics";
import { gaEventActions as gaActions } from "../analytics";
import styles from "./Authorization.module.scss";
import { useGaCategory } from "../analytics/useCategory";

const AuthorizationPhoneCode = ({ phone, onClose }) => {
  const user = useUserContext();
  const form = useForm({ phone });
  const notification = useNotification();
  const [loading, setLoading] = useState();
  const { getGaEventString: gaStr } = useGaCategory();

  const handleSubmit = async () => {
    try {
      setLoading(true);
      const response = await user.actions.phone(form.state);
      setLoading(false);
      if (response?.status === "error") {
        notification.showMessage(ERROR_LOCALE_CODE[response.message]);

        const isCodeError = [
          ERROR_CODE.CODE_INVALID,
          ERROR_CODE.CODE_EXPIRE,
        ].includes(response.message);

        if (isCodeError) {
          sendEvent(
            gaStr({
              ...gaActions.auth.phone_verification.error,
              label: response.message,
            })
          );
        } else {
          sendEvent(gaStr(gaActions.auth.phone_verification.error));
        }
        throw new Error();
      }
      sendEvent(gaStr(gaActions.auth.success_type.phone_success));
      onClose();
    } catch (err) {
      setLoading(false);
    }
  };

  return (
    <>
      <UILoader show={loading} />
      <UITypography className={styles.section}>
        <FormattedMessage id="login.response.phone" values={{ phone }} />
      </UITypography>
      <UIForm.Group>
        <UIForm.Row>
          <UIInput
            name="code"
            placeholder="X X X X"
            type="tel"
            maxLength={4}
            autofocus="true"
            ref={form.onInputRef}
            onChange={form.handleChangeInput}
            value={form.state.code}
            event={gaActions.auth.phone_verification.code_type}
            required
          />
        </UIForm.Row>
        <UIButton
          appearance="primary"
          event={gaActions.auth.phone_verification.submit_click}
          onClick={handleSubmit}
          loading={loading}
        >
          <FormattedMessage id="confirm" />
        </UIButton>
      </UIForm.Group>
    </>
  );
};

export default AuthorizationPhoneCode;
