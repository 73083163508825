import cx from "classnames";
import styles from "./LunMarketFavoritesCard.module.scss";
import { useMarketFavorites } from "../../helpers/hooks";
import React, { useMemo } from "react";
import UIFavoriteButton from "components/UIKit/FavoriteButton/FavoriteButton";
import { ReactComponent as FavIconFlat } from "icons/favorite.svg";

export const Favorite = ({ className, id }) => {
  const { favorites, add, remove } = useMarketFavorites();
  const active = useMemo(() => favorites.includes(id), [favorites, id]);

  return (
    <UIFavoriteButton
      appearance="secondary"
      activePrimary={false}
      icon={<FavIconFlat />}
      active={active}
      onClick={() => (active ? remove(id) : add(id))}
      className={cx(styles.favorite, className)}
    />
  );
};
