import React, { useState, useEffect } from "react";
import { useUserContext } from "helpers/hooks";
import ProjectCard from "components/ProjectCard";
import { FormattedMessage } from "react-intl";
import { getLastVisited } from "../helpers/api";
import { GridPlaceholder } from "../placeholders/Placeholder";
import GridBlockExpand from "../components/GridBlock/GridBlockExpand";
import GridBlock from "../components/GridBlock/GridBlock";
import { gaEventActions as gaActions } from "../analytics";
import { useGaCategory } from "../analytics/useCategory";

const LAST_SEEN_TYPE = "last-seen";
const POPULAR_TYPE = "popular";

const LastVisited = () => {
  const { getGaEvent: ga } = useGaCategory();

  const {
    lastVisited: [, lastVisitedRef, setLastVisited],
    popular: [, , setPopular],
  } = useUserContext();
  const [loading, setLoading] = useState(true);
  const [type, setType] = useState();
  const [data, setData] = useState({});

  useEffect(() => {
    if (lastVisitedRef.current) {
      setData(lastVisitedRef.current);
      setType(LAST_SEEN_TYPE);
      setLoading(false);
      return;
    }
    getLastVisited().then((response) => {
      if (response.status !== "ok") return;
      setType(response.data.type);
      if (response.data.type === LAST_SEEN_TYPE) {
        setLastVisited(response.data.cards);
        setData({ cards: response.data.cards });
      }
      if (response.data.type === POPULAR_TYPE) {
        setPopular({ ...response.data, data: response.data.cards });
        setData(response.data);
      }
      setLoading(false);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (loading) return <GridPlaceholder />;
  if (!data.cards || data.cards.length === 0) return null;

  if (type === POPULAR_TYPE) {
    return (
      <GridBlock.Wrapper>
        <GridBlock.Header
          title={{ id: "popular.geo", values: { geo: data.geo_object_name } }}
          withChip
          chip={{
            children: <FormattedMessage id="showAll" />,
            event: ga(gaActions.last_seen_projects_list.show_all_click),
            href: data.geo_object_url,
            withLink: true,
          }}
        />
        <GridBlock.List>
          {data.cards.map((item) => (
            <GridBlock.ListItem key={item.id}>
              <ProjectCard
                {...item}
                gaAction={gaActions.last_seen_projects_list.name}
              />
            </GridBlock.ListItem>
          ))}
        </GridBlock.List>
      </GridBlock.Wrapper>
    );
  }

  return (
    <GridBlockExpand
      title={{ id: "lastVisited" }}
      chip={{
        children: (
          <FormattedMessage
            id="showAllCount"
            values={{ count: data.cards.length }}
          />
        ),
        event: ga(gaActions.last_seen_projects_list.show_all_click),
      }}
      list={data.cards}
      listItemNode={(props) => (
        <ProjectCard
          {...props}
          gaAction={gaActions.last_seen_projects_list.name}
        />
      )}
    />
  );
};

export default LastVisited;
