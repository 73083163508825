import { Currency } from "../../constants/base";
import useBaseContext from "./useBaseContext";

export const useCurrency = () => {
  const { currency, handleCurrencyChange } = useBaseContext();
  const handleCurrencyToggle = () => {
    handleCurrencyChange(
      currency === Currency.USD ? Currency.UAH : Currency.USD
    );
  };
  return {
    value: currency,
    onChange: handleCurrencyChange,
    onToggle: handleCurrencyToggle,
  };
};

export const useUsdCurrency = () => {
  const currency = useCurrency();
  return currency.value === Currency.USD;
};

export const useUahCurrency = () => {
  const currency = useCurrency();
  return currency.value === Currency.UAH;
};
