import { useLocale } from "../../helpers/hooks";

export const useUrl = (id) => {
  const [locale] = useLocale();
  const base = `${process.env.REACT_APP_LUN_MARKET_LINK}${
    locale === "uk" ? "/uk" : ""
  }`;

  return {
    redirect: `${base}/redirect/${id}`,
    realty: `${base}/realty/${id}`,
  };
};
