import React, { useMemo } from "react";
import cx from "classnames";
import { useBaseContext } from "helpers/hooks";
import { ReactComponent as Logo } from "icons/logo.svg";
import { ReactComponent as Close } from "icons/close.svg";
import { attachGaDataAttributes } from "helpers/analytics";
import { FormattedMessage } from "react-intl";
import { TELEGRAM_SUPPORT_LINK } from "constants/base";
import { ReactComponent as FbIcon } from "icons/facebook-outline.svg";
import { ReactComponent as YoutubeIcon } from "icons/youtube.svg";
import { ReactComponent as InstagramIcon } from "icons/instagram.svg";
import { ReactComponent as TwitterIcon } from "icons/twitter.svg";
import { ReactComponent as TelegramRoundedIcon } from "icons/telegram-rounded.svg";
import { useGaCategory } from "../../analytics/useCategory";
import { gaEventActions as gaActions } from "../../analytics";
import { UIButton } from "../../components/UIKit/Button";
import * as Icons from "./icons";
import styles from "./NavigationSidebar.module.scss";
import AppLinks from "components/AppLinks";

const SOCIALS = [
  {
    icon: <InstagramIcon />,
    link: "https://www.instagram.com/lun.ua",
    id: "instagram",
  },
  {
    icon: <TwitterIcon />,
    link: "https://twitter.com/lun_ua",
    id: "twitter",
  },
  {
    icon: <YoutubeIcon />,
    link: "https://www.youtube.com/c/lunua",
    id: "youtube",
  },
  { icon: <FbIcon />, link: "https://www.facebook.com/lun.ua", id: "fb" },
];

const PROJECTS = [
  {
    icon: <Icons.Misto />,
    id: "footer.misto",
    href: "https://misto.lun.ua/",
    event: gaActions.navigation_menu.goto_lunmisto,
  },
  {
    icon: <Icons.Rieltor />,
    id: "footer.rieltor",
    href: "https://rieltor.ua/",
    event: gaActions.navigation_menu.goto_rieltor,
  },
  {
    icon: <Icons.Bird />,
    id: "footer.bird",
    href: "https://apps.apple.com/app/apple-store/id1446261104?pt=102173849&ct=lun-footer&mt=8",
    event: gaActions.navigation_menu.goto_bird,
  },
  {
    icon: <Icons.Korter />,
    id: "footer.korter",
    href: "https://korter.com/",
    event: gaActions.navigation_menu.goto_korter,
  },
  {
    icon: <Icons.Red />,
    id: "footer.redCommunity",
    href: "https://redcommunity.ua/",
    event: gaActions.navigation_menu.goto_red,
  },
  {
    icon: <Icons.Dobovo />,
    id: "footer.dobovo",
    href: "https://www.dobovo.com/ua/",
    event: gaActions.navigation_menu.goto_dobovo,
  },
];

const NavigationSidebar = ({ opened, onClose, navigation }) => {
  const { getGaEvent: ga } = useGaCategory();
  const { locale } = useBaseContext();
  const siteLink = `${process.env.REACT_APP_SITE_LINK}/${locale}`;

  const LINKS = useMemo(
    () => [
      {
        href: "https://cosmos.lun.ua/boost",
        el: "for_developers_placement",
        id: "footer.for_developers_placement",
      },
      {
        href: "https://my.rieltor.ua/",
        el: "for_rieltors",
        id: "footer.for_rieltor",
      },
      {
        href: "https://lun.page.link/lun_create_ads_button/",
        el: "for_owners",
        id: "footer.for_owners",
      },
      {
        href: "https://misto.lun.ua/pr",
        el: "contacts_for_mass_media",
        id: "footer.contacts_for_mass_media",
      },
      { href: `${siteLink}/faq`, el: "faq", id: "FAQ" },
      { href: "https://team.lun.ua/", el: "vacancies", id: "footer.vacancies" },
      {
        href: TELEGRAM_SUPPORT_LINK,
        el: "telegram_support",
        id: "footer.telegram_support",
      },
    ],
    []
  );

  return (
    <>
      <div
        className={cx(styles.backdrop, { [styles.hidden]: !opened })}
        onClick={onClose}
      />
      <div className={cx(styles.root, { [styles.opened]: opened })}>
        <div className={styles.header}>
          <a href={navigation.home.url}>
            <Logo />
          </a>
          <UIButton
            appearance="ghost-dark-new"
            size="small"
            event={ga(gaActions.navigation_menu.close)}
            onClick={onClose}
            icon={<Close />}
          />
        </div>
        <hr />
        <div className={styles.links}>
          {LINKS.map((item) => (
            <a
              target="_blank"
              key={item.id}
              href={item.href}
              {...attachGaDataAttributes(
                ga(gaActions.navigation_menu[item.el])
              )}
            >
              <FormattedMessage id={item.id} />
              {item.id === "footer.telegram_support" && <TelegramRoundedIcon />}
            </a>
          ))}
        </div>
        <hr />
        <div className={styles.socials}>
          <div className={styles.socialsTitle}>
            <FormattedMessage id="footer.social" />
          </div>
          {SOCIALS.map((item) => (
            <UIButton
              key={item.id}
              appearance="ghost-dark-new"
              size="small"
              event={ga(gaActions.navigation_menu.goto_away)}
              icon={item.icon}
              className={styles.socialsButton}
              attributes={{
                href: item.link,
                target: "_blank",
              }}
            />
          ))}
        </div>
        <hr />
        <div className={styles.appLinksWrapper}>
          <AppLinks
            classes={{ root: styles.appLinks, link: styles.appLinksLink }}
            eventAction="navigation_menu"
          />
        </div>
        <hr />
        <div className={styles.projects}>
          <div className={styles.projectsTitle}>
            <FormattedMessage id="footer.other_projects" />
            <div className={styles.projectsLogo}>
              <FormattedMessage tagName="span" id="from" />
              <Logo />
            </div>
          </div>
          <div className={styles.projectsList}>
            {PROJECTS.map((item) => (
              <a
                key={item.id}
                href={item.href}
                target="_blank"
                {...attachGaDataAttributes(ga(item.event))}
                className={cx({ [styles.bird]: item.id === "footer.bird" })}
              >
                {item.icon}
                <FormattedMessage
                  id={item.id}
                  tagName="span"
                  values={{ br: <br /> }}
                />
              </a>
            ))}
          </div>
        </div>
      </div>
    </>
  );
};

export default NavigationSidebar;
