import React from "react";
import { useUrl } from "../hooks";
import { attachGaDataAttributes } from "../../helpers/analytics";
import { useGaCategory } from "../../analytics/useCategory";
import { gaEventActions as gaActions } from "../../analytics";

export const RedirectUrl = ({ className, children, id, isRealtyUrl }) => {
  const { redirect, realty } = useUrl(id);
  const { getGaEvent: ga } = useGaCategory();
  return (
    <a
      href={isRealtyUrl ? realty : redirect}
      target="_blank"
      rel="nofollow noopener"
      className={className}
      {...attachGaDataAttributes(ga(gaActions.market.goto_realty))}
    >
      {children}
    </a>
  );
};
