import { useCallback, useContext, useMemo } from "react";
import { LayoutContext } from "../../features/layouts";

const useFavoriteLayouts = ({ entity, layoutId }) => {
  const { layoutIds, add, remove } = useContext(LayoutContext);
  const active = useMemo(
    () => layoutIds.indexOf(layoutId) > -1,
    [layoutIds, layoutId]
  );

  const handleChange = useCallback(() => {
    if (active) {
      remove.mutate({ entity, id: layoutId });
    } else {
      add.mutate({ entity, id: layoutId });
    }
  }, [active, layoutId, entity]);

  const handleFavoriteClick = (e) => {
    e.preventDefault();
    e.stopPropagation();
    handleChange();
  };

  return {
    isActiveFavorite: active,
    onFavoriteClick: handleFavoriteClick,
    onChange: handleChange,
  };
};

export default useFavoriteLayouts;
