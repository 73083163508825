import React from "react";
import { UIButton } from "components/UIKit/Button";
import * as Icon from "icons";
import { FormattedMessage } from "react-intl";
import { useGaCategory } from "../analytics/useCategory";
import { gaEventActions as gaActions } from "../analytics";
import { TELEGRAM_SUPPORT_LINK } from "constants/base";
import styles from "./ContactUs.module.scss";

export const ContactUs = () => {
  const { getGaEvent: ga } = useGaCategory();
  return (
    <div className={styles.root}>
      <div className={styles.content}>
        <div className={styles.icon}>
          <Icon.EarphonesLetter />
        </div>
        <div className={styles.text}>
          <div className={styles.title}>
            <FormattedMessage id="contactUs.title" />
          </div>
          <div className={styles.description}>
            <FormattedMessage id="contactUs.text.p1" tagName="span" />
            <FormattedMessage id="contactUs.text.p2" tagName="span" />
          </div>
        </div>
      </div>
      <UIButton
        appearance="primary"
        event={ga(gaActions.support.click)}
        icon={<Icon.TelegramWhite />}
        fullWidth
        attributes={{
          href: TELEGRAM_SUPPORT_LINK,
          target: "_blank",
        }}
      >
        <FormattedMessage id="actions.write" />
      </UIButton>
    </div>
  );
};
