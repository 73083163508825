import React, { createContext } from "react";
import { useMutationApi, useUserContext, useQueryApi } from "../helpers/hooks";
import * as Api from "../helpers/api";
import { useList } from "react-use";

const LayoutContext = createContext();

const LayoutContextProvider = ({ children }) => {
  const [layoutIds, layoutIdsActions] = useList([]);
  const {
    state: { layoutId },
  } = useUserContext();

  const layouts = useQueryApi(
    ["favorite-layouts", { layoutId }],
    () => Api.getFavoriteLayouts(),
    {
      keepPreviousData: true,
      onSuccess(data) {
        layoutIdsActions.set(data.map((e) => e.layoutId));
      },
    }
  );

  const add = useMutationApi(Api.addFavorite, {
    /* Здесь и дальше в remove мутации меняем сразу массив layoutIds,
     * чтоб кнопка избранного ♥ реагировала сразу, а не ждала, пока отработает запрос
     * (в кнопке состояние active меняется от layoutIds)
     */
    onMutate(data) {
      layoutIdsActions.push(data.id);
    },
    onSuccess() {
      layouts.refetch();
    },
  });

  const remove = useMutationApi(Api.removeFavorite, {
    onMutate(data) {
      layoutIdsActions.removeAt(layoutIds.indexOf(data.id));
    },
    /*
     * При удалении чего-либо из избранного, карточка не должна убираться.
     * За это отвечает хук useGridData.
     * Поэтому здесь layouts.refetch() будет лишним запросом
     */
  });

  const value = {
    layouts: layouts.data,
    loading: layouts.isLoading,
    layoutIds,
    add,
    remove,
  };

  return (
    <LayoutContext.Provider value={value}>{children}</LayoutContext.Provider>
  );
};

export { LayoutContext, LayoutContextProvider };
