import * as Sentry from "@sentry/browser";
import SentryRRWeb from "@sentry/rrweb";
import LogRocket from "logrocket";
import { Breakpoints } from "../constants/breakpoints";

export const initLoggers = () => {
  if (process.env.NODE_ENV === "production") {
    Sentry.init({
      dsn: "https://bd32a7c4635b4dbb9abeae9d0fee9161@sentry.io/1432189",
      integrations: [new SentryRRWeb()],
    });
  }
};

export const initLogRocket = (user) => {
  if (process.env.NODE_ENV !== "production") return;
  const isTouch = "ontouchstart" in document.documentElement;
  const isMobile = window.innerWidth < Breakpoints.MEDIUM;
  if (isTouch || isMobile) return;

  LogRocket.init("gyapck/mylun");

  LogRocket.identify(user?.id, {
    username: user?.username,
    email: user?.email,
  });

  LogRocket.getSessionURL((sessionURL) => {
    Sentry.configureScope((scope) => {
      scope.setExtra("sessionURL", sessionURL);
    });
  });
};
