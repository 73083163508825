import React, { createContext, useReducer } from "react";
import { useUrlParams } from "helpers/hooks";
import { compareReducer, initialCompares, getInitialHidden } from "./reducer";
import * as compareActions from "./actions";
import { composeDispatch } from "../utils";
import * as shortid from "shortid";

const isHiddenBlock = (block, hidden) => {
  if (!block.props || !hidden) return false;
  return Object.keys(block.props).every(
    (e) => hidden[block.label] && hidden[block.label].indexOf(e) !== -1
  );
};

const isHiddenProp = (prop, block, hidden) => {
  if (!block || !block.label || !hidden) return false;
  return hidden[block.label] && hidden[block.label].indexOf(prop) !== -1;
};

const CompareContext = createContext({});

const CompareContextProvider = ({ children }) => {
  const { urlParams } = useUrlParams();
  const [compares, dispatch] = useReducer(compareReducer, {
    ...initialCompares,
    hiddenProps: getInitialHidden(urlParams),
  });

  const value = {
    compares,
    filteredBlocks: [...compares.blocks]
      .filter((attr) => !isHiddenBlock(attr, compares.hiddenProps))
      .map((attr) => {
        const nextAttr = { ...attr, id: shortid.generate(), props: {} };
        Object.keys(attr.props).forEach((key) => {
          if (!isHiddenProp(key, attr, compares.hiddenProps)) {
            nextAttr.props[key] = attr.props[key];
          }
        });
        return nextAttr;
      }),
    actions: composeDispatch(compareActions, dispatch),
  };

  return (
    <CompareContext.Provider value={value}>{children}</CompareContext.Provider>
  );
};

export { CompareContext, CompareContextProvider };
