import React, { useCallback, useRef, useState } from "react";
import { ReactComponent as LibraIcon } from "icons/libra.svg";
import {
  useCompareBuildings,
  useBaseContext,
  useCompareBuildingIdsContext,
} from "helpers/hooks";
import { FormattedMessage } from "react-intl";
import UIFavoriteButton from "components/UIKit/FavoriteButton/FavoriteButton";
import FavoriteButton from "components/FavoriteButton/FavoriteButton";
import { UIPopup } from "components/UIKit/Popup";
import { UIButton } from "components/UIKit/Button";
import { sendEvent } from "helpers/analytics";
import styles from "./FavoriteBuildingsCard.module.scss";
import { useGaCategory } from "../../analytics/useCategory";
import { gaEventActions as gaActions } from "../../analytics";

const FavoriteBuildingsCardActions = ({ appearance, building }) => {
  const { routes } = useBaseContext();
  const { getGaEventString: gaStr, getGaEvent: ga } = useGaCategory();
  const { isActiveCompare, onCompareClick } = useCompareBuildings(building.id, {
    onAdd: () =>
      sendEvent(gaStr(gaActions.favorite_projects_list.comparison_add_click)),
    onRemove: () =>
      sendEvent(
        gaStr(gaActions.favorite_projects_list.comparison_remove_click)
      ),
  });
  const { compareIds } = useCompareBuildingIdsContext();
  const [comparePopupOpened, setComparePopupOpened] = useState(false);
  const actionsAnchorRef = useRef();

  const handleCompareClick = useCallback(
    (e) => {
      if (!isActiveCompare) {
        setComparePopupOpened(true);
      }
      onCompareClick(e);
    },
    [isActiveCompare, onCompareClick]
  );

  return (
    <>
      <div className={styles.favoriteActions}>
        {building.type === "buildings" && (
          <UIFavoriteButton
            icon={<LibraIcon />}
            onClick={handleCompareClick}
            active={isActiveCompare}
            appearance={appearance}
            className={styles.favoriteAction}
          />
        )}
        <FavoriteButton
          appearance={appearance}
          activePrimary={false}
          className={styles.favoriteAction}
          id={building.id}
          options={{
            onAdd: () =>
              sendEvent(
                gaStr(gaActions.favorite_projects_list.favorites_add_click)
              ),
            onRemove: () =>
              sendEvent(
                gaStr(gaActions.favorite_projects_list.favorites_remove_click)
              ),
          }}
        />
        <div className={styles.compareAnchor} ref={actionsAnchorRef} />
        <UIPopup
          opened={comparePopupOpened}
          anchorEl={actionsAnchorRef.current}
          onClose={() => setComparePopupOpened(false)}
          placementX="right"
        >
          <div className={styles.popup}>
            <div className={styles.popupTitle}>
              <FormattedMessage
                id="compare.popup.title"
                values={{ building: building.name }}
              />
            </div>
            <div className={styles.popupDescription}>
              <FormattedMessage
                id="compare.popup.subtitle"
                values={{ count: compareIds.length }}
              />
            </div>
            <UIButton
              appearance="primary"
              event={ga(gaActions.favorite_projects_list.goto_my_comparison)}
              fullWidth
              attributes={{
                href: routes.compare,
              }}
            >
              <FormattedMessage id="compare.popup.action" />
            </UIButton>
          </div>
        </UIPopup>
      </div>
    </>
  );
};

export default FavoriteBuildingsCardActions;
