import React from "react";
import "@flatfy/supernova2/src/UIKit/MainTitle/MainTitle.scss";
import styles from "./MainTitle.module.scss";

import MainTitle from "@flatfy/supernova2/src/UIKit/MainTitle";
import { UIReportError } from "../ReportError";

export const UIMainTitle = ({ withReportError, ...props }) => (
  <>
    <MainTitle {...props} />
    {withReportError && (
      <div className={styles.report}>
        <UIReportError />
      </div>
    )}
  </>
);
