import React from "react";
import * as Sentry from "@sentry/browser";
import Bowser from "bowser";
import styles from "./ErrorBoundary.module.scss";

const browserValidList = {
  chrome: ">=50",
  firefox: ">=52",
  opera: ">=52",
  safari: ">=10.1",
  edge: ">=17",
  mobile: {
    opera: "",
  },
};

export default class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { error: null };
  }

  componentDidCatch(error, errorInfo) {
    this.setState({ error });
    const browser = Bowser.getParser(window.navigator.userAgent);
    if (
      process.env.NODE_ENV === "production" &&
      browser.satisfies(browserValidList)
    ) {
      Sentry.withScope((scope) => {
        Sentry.captureException(error);
        scope.setExtras(errorInfo);
      });
    }
  }

  render() {
    const { error } = this.state;
    const { children } = this.props;

    if (error) {
      // render fallback UI
      return (
        <div className={styles.root}>
          <div className={styles.content}>
            <div variant="h1" className={styles.title}>
              Вибачте, щось пішло не так
            </div>
            <div className={styles.subtitle}>
              Спробуйте, будь ласка, пізніше
            </div>
          </div>
        </div>
      );
    }
    // when there's not an error, render children untouched
    return children;
  }
}
