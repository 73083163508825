import { useState } from "react";
import * as RealtyCookies from "features/realtyFavorites";

export const useMarketFavorites = () => {
  const [favorites, setFavorites] = useState(RealtyCookies.getCookies());

  const add = (realtyId) => {
    const nextValue = RealtyCookies.addItem(realtyId);
    setFavorites(nextValue);
  };

  const remove = (realtyId) => {
    const nextValue = RealtyCookies.removeItem(realtyId);
    setFavorites(nextValue);
  };

  const updateByIds = (ids) => {
    RealtyCookies.updateCookieByDeletedRealties(ids);
  };

  return { favorites, add, remove, updateByIds };
};
