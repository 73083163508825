import React from "react";
import ReactDOM from "react-dom";
import "@flatfy/supernova2/src/UIKit/Modal/Modal.scss";
import { CSSTransition } from "react-transition-group";
import UIModalContent from "@flatfy/supernova2/src/UIKit/Modal";

export const UIModal = (props) => {
  const root = document.getElementById("modal");
  const modal = (
    <div style={{ position: "relative", zIndex: 10000 }}>
      <CSSTransition
        in={props.opened}
        unmountOnExit={props.unmountOnClose}
        timeout={{
          exit: 0.4 * 2 * 1000,
        }}
      >
        <UIModalContent {...props} />
      </CSSTransition>
    </div>
  );

  if (root) {
    return ReactDOM.createPortal(modal, root);
  }

  return modal;
};
