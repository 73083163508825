import { useState, useEffect } from "react";
import useFavoriteContext from "./useFavoriteContext";
import { sendFavoritesChangeEvent } from "helpers/analytics";

const useFavoriteButton = ({ id, options = {} }) => {
  const favorites = useFavoriteContext();
  const [active, setActive] = useState(true);
  const [analytics, setAnalytics] = useState(false);

  const setAnalyticsData = (actionType) => {
    setAnalytics({
      entity: "buildings",
      buildingId: id,
      actionType,
    });
  };

  const handleChange = () => {
    setActive(!active);
    if (active) {
      favorites.actions.removeFavorite(id);
      setAnalyticsData("remove");
      if (options.onAdd) options.onAdd();
    } else {
      favorites.actions.addFavorite(id);
      if (options.onRemove) options.onRemove();
      setAnalyticsData("add");
    }
  };

  const handleFavoriteClick = (e) => {
    e.preventDefault();
    e.stopPropagation();
    handleChange();
  };

  useEffect(() => {
    if (analytics) {
      sendFavoritesChangeEvent({
        ...analytics,
        favorites: favorites.data,
      });
      setAnalytics(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [favorites.ids]);

  useEffect(() => {
    setActive(favorites.ids.some((elem) => Number(elem) === Number(id)));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [favorites.ids]);

  return {
    isActiveFavorite: active,
    onFavoriteClick: handleFavoriteClick,
    onChange: handleChange,
  };
};

export default useFavoriteButton;
