import React, { createContext, useEffect, useReducer, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { useMixedRefState, useUrlParams, useBaseContext } from "helpers/hooks";
import { userReducer } from "./reducer";
import {
  fetchReducer,
  initialState,
} from "components/FetchHelper/fetchReducer";
import { getCachedUser, userActions, userPostActions } from "./actions";
import { initUserAnalytics, sendEvent } from "helpers/analytics";
import { getUpdates } from "helpers/api";
import { EVENT_CATEGORY, ERROR_CODE } from "constants/auth";

const UserContext = createContext({});

const UserContextProvider = ({ children }) => {
  const history = useHistory();
  const { pathname } = useLocation();
  const [state, dispatch] = useReducer(userReducer, getCachedUser());
  const [tokenLoading, setTokenLoading] = useState(false);
  const { routes, showMessage } = useBaseContext();
  const { urlParams } = useUrlParams();
  /* Fetch helpers global and for login panel */
  const [, fetchDispatch] = useReducer(fetchReducer, {
    ...initialState,
    loading: false,
  });
  const [fetchStatePost, fetchDispatchPost] = useReducer(fetchReducer, {
    ...initialState,
    loading: false,
  });
  /* Init actions with different fetch helpers */
  const actions = {};
  Object.keys(userActions).forEach((key) => {
    actions[key] = userActions[key]({ helper: fetchDispatch, dispatch });
  });
  Object.keys(userPostActions).forEach((key) => {
    actions[key] = userPostActions[key]({
      helper: fetchDispatchPost,
      dispatch,
    });
  });

  const [updates, setUpdates] = useState(0);
  const popular = useMixedRefState();
  const lastVisited = useMixedRefState();
  const lastVisitedLayouts = useState();

  const value = {
    state,
    actions,
    fetchStatePost,
    tokenLoading,
    popular,
    lastVisited,
    lastVisitedLayouts,
    updates: [updates, setUpdates],
  };

  useEffect(() => {
    if (urlParams.token && pathname === routes.main) {
      setTokenLoading(true);
      actions
        .token({ token: urlParams.token })
        .then((res) => {
          setTokenLoading(false);
          history.push({ pathname, search: "" });
          return res;
        })
        .then((res) => {
          if (!res.success) {
            actions.fetch();
            showMessage(res.message);
            const isTokenInvalidError =
              res.message === ERROR_CODE.TOKEN_INVALID;
            sendEvent(
              `${EVENT_CATEGORY}|email_verification|${
                isTokenInvalidError ? res.message : "server_error"
              }`
            );
          } else {
            sendEvent(`${EVENT_CATEGORY}|success_type|email_success`);
          }
        });
    } else {
      actions.fetch();
    }

    getUpdates().then((response) => {
      if (response.status !== "ok") return;
      setUpdates(response.data);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!state.isLoaded) return;
    initUserAnalytics(state);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.isGuest, state.isLoaded]);

  useEffect(() => {
    window.isGuest = state.isGuest;
  }, [state.isGuest]);

  return <UserContext.Provider value={value}>{children}</UserContext.Provider>;
};

export { UserContext, UserContextProvider };
