import { useMobile } from "helpers/hooks";
import { useIntl } from "react-intl";

export const useFloor = ({ number, total }) => {
  const { isTouch } = useMobile();
  const intl = useIntl();

  if (!number && !total) return null;

  if (number && total) {
    return intl.formatMessage(
      {
        id: isTouch
          ? "market.floor.totalNumberShort"
          : "market.floor.totalNumber",
      },
      { total, number }
    );
  }

  if (number && !total) {
    return isTouch
      ? number
      : intl.formatMessage({ id: "market.floor.number" }, { number });
  }

  return intl.formatMessage({ id: "market.floor.total" }, { total });
};
