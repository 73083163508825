import useBaseContext from "./useBaseContext";
import Cookies from "js-cookie";
import { Language, COOKIE_NAME_USER_LANGUAGE } from "../../constants/base";

export const useLocale = () => {
  const { locale } = useBaseContext();

  const switchLanguage = () => {
    Cookies.set(
      COOKIE_NAME_USER_LANGUAGE,
      locale === Language.Uk ? Language.Ru : Language.Uk,
      {
        expires: 365,
        domain: process.env.REACT_APP_COOKIE_DOMAIN_NAME,
      }
    );
    window.location.reload();
  };

  return [locale, switchLanguage];
};
