import { useIntl } from "react-intl";
import * as BUILDING_TYPE from "../constants/buildingType";

export const BUILDING_TYPE_MESSAGE = {
  [BUILDING_TYPE.APPS]: "apps",
  [BUILDING_TYPE.APPS_LUX]: "appsLuxury",
  [BUILDING_TYPE.BPS]: "bps",
  [BUILDING_TYPE.HOSTINKA]: "studioApartment",
  [BUILDING_TYPE.PRE_REVOLUTIONARY]: "preRevolutionary",
  [BUILDING_TYPE.RADMIN]: "sovmin",
  [BUILDING_TYPE.SERIES_134]: "series134",
  [BUILDING_TYPE.SERIES_215]: "series215",
  [BUILDING_TYPE.SERIES_87]: "series87",
  [BUILDING_TYPE.SERIES_96]: "series96",
  [BUILDING_TYPE.SERIES_ES]: "seriesES",
  [BUILDING_TYPE.SERIES_KP]: "seriesKP",
  [BUILDING_TYPE.SERIES_KT]: "seriesKT",
  [BUILDING_TYPE.SERIES_KS]: "seriesKS",
  [BUILDING_TYPE.SERIES_T]: "seriesT",
  [BUILDING_TYPE.SPECIAL_PROJECT]: "specialProject",
  [BUILDING_TYPE.STALINKA]: "theStalinproject",
  [BUILDING_TYPE.KHRUSHCHEVKA]: "khrushevProject",
  [BUILDING_TYPE.CZECH_PROJECT]: "czechProject",
  [BUILDING_TYPE.BELGIAN_PROJECT]: "belgianProject",
  [BUILDING_TYPE.POLISH_HOUSE]: "polishProject",
  [BUILDING_TYPE.AUSTRIAN_LUX]: "austriaLuxury",
  [BUILDING_TYPE.POLISH_LUX]: "polishLuxury",
  [BUILDING_TYPE.MALOSIMEYKA]: "bachelorette",
};

export const useBuildingType = (buildingType) => {
  const intl = useIntl();
  if (!buildingType) return null;

  return intl.formatMessage({
    id: `market.buildingType.${BUILDING_TYPE_MESSAGE[buildingType]}`,
  });
};
