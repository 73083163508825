import React from "react";
import { CSSTransition, TransitionGroup } from "react-transition-group";
import uuid from "uuid";
import Message from "./Message";

import styles from "./Message.module.scss";

class MessageList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      messages: [],
    };
    this.removeMessageFromList = this.removeMessageFromList.bind(this);
  }

  showMessage = (message) => {
    this.setState(({ messages }) => ({
      messages: [
        ...messages,
        {
          message,
          id: uuid(),
        },
      ],
    }));
  };

  showMessageStr = (message) => {
    this.setState(({ messages }) => ({
      messages: [
        ...messages,
        {
          messageStr: message,
          id: uuid(),
        },
      ],
    }));
  };

  removeMessageFromList = (id) => {
    this.setState(({ messages }) => {
      const index = messages.findIndex((e) => e.id === id);
      return {
        messages: [...messages.slice(0, index), ...messages.slice(index + 1)],
      };
    });
  };

  render() {
    return (
      <TransitionGroup className={styles.list}>
        {this.state.messages.map(({ message, id, messageStr }) => (
          <CSSTransition key={id} timeout={500}>
            <Message
              messageStr={messageStr}
              message={message}
              onHide={this.removeMessageFromList}
              id={id}
            />
          </CSSTransition>
        ))}
      </TransitionGroup>
    );
  }
}

export default MessageList;
