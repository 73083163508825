import React, { useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";
import { UIButton } from "../components/UIKit/Button";
import {
  useFavoriteContext,
  useGridData,
  useUserContext,
} from "../helpers/hooks";
import styles from "./FavoriteBuildings.module.scss";
import FavoriteBuildingsCard from "./FavoriteBuildingsCard";
import { TwoColumnGridPlaceholder } from "../placeholders/Placeholder";
import { useGaCategory } from "../analytics/useCategory";
import { gaEventActions as gaActions } from "../analytics";

const LIMIT = 16;

const FavoriteBuildings = () => {
  const user = useUserContext();
  const favorites = useFavoriteContext();
  const [allLoading, setAllLoading] = useState(false);
  const { getGaEvent: ga } = useGaCategory();

  useEffect(() => {
    favorites.actions.fetchBuildings({ limit: LIMIT });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user.isGuest]);

  const handleShowAllClick = async () => {
    try {
      setAllLoading(true);
      await favorites.actions.fetchBuildings();
      setAllLoading(false);
    } catch (error) {
      setAllLoading(false);
    }
  };

  return (
    <div className={styles.root}>
      {favorites.loading && !allLoading ? (
        <TwoColumnGridPlaceholder noTitle />
      ) : (
        <FavoriteBuildingsGrid />
      )}
      {!favorites.loading && favorites.ids.length > favorites.data.length && (
        <UIButton
          fullWidth
          event={ga(gaActions.favorite_projects_list.show_more_click)}
          onClick={handleShowAllClick}
        >
          <FormattedMessage
            id="showAllCount"
            values={{ count: favorites.ids.length }}
          />
        </UIButton>
      )}
      {allLoading && <TwoColumnGridPlaceholder noTitle />}
    </div>
  );
};

const FavoriteBuildingsGrid = () => {
  const favorites = useFavoriteContext();
  const data = useGridData(favorites.data);

  return (
    <div className={styles.grid}>
      {data.map((building) => (
        <div className={styles.cell} key={building.id}>
          <FavoriteBuildingsCard building={building} />
        </div>
      ))}
    </div>
  );
};

export default FavoriteBuildings;
