import { IntlProvider } from "react-intl";
import { Route, Switch, useHistory } from "react-router-dom";
import PropTypes from "prop-types";
import React, { useEffect } from "react";
import Helmet from "react-helmet";
import moment from "moment";
import { UserContextProvider } from "./features/user/context";
import { FavoriteContextProvider } from "./features/favorites/context";
import Main from "./Main";
import messages_uk from "./locale/uk.json";
import messages_ru from "./locale/ru.json";
import ErrorBoundary from "./components/ErrorBoundary";
import { ABTestingContextProvider } from "./features/testing/context";
import { initAppVersion } from "./helpers/analytics";
import "moment/locale/uk";
import "moment/locale/ru";
import { BaseContextProvider } from "./features/base/context";
import DeveloperQuestionPage from "./Messages/DeveloperQuestion/index";
import { QueryClient, QueryClientProvider } from "react-query";
import AuthorizationPage from "./Authorization/AuthorizationPage";
import { NavigationProfileMenuContent } from "./Navigation/NavigationProfileMenu/NavigationProfileMenuContent";
import { LANGUAGE_PATH, getLanguage } from "./helpers/utils/language";
import { useGaCategory } from "./analytics/useCategory";

const messages = {
  uk: messages_uk,
  ru: messages_ru,
};

const queryClient = new QueryClient();

const Root = ({
  location: { pathname, search, hash },
  locale,
  match: { url, path },
}) => {
  const history = useHistory();
  const { setGaCategory } = useGaCategory();

  useEffect(() => {
    // eslint-disable-next-line global-require
    if (!window.Intl) require("intl");
  }, []);

  const language = getLanguage();

  if (language !== locale) {
    history.replace(
      pathname.replace(url, LANGUAGE_PATH[language]) + hash + search
    );
  }

  const routes = {
    main: path,
    message: `${path}message`,
    question: `${path}question/:id`,
    questionSupport: `${path}question/:id/support`,
    questionFormOnly: `${path}question/:id/only-form`,
    appointment: `${path}appointment/:id`,
    cancelAppointment: `${path}cancel-appointment`,
    appointmentFeedback: `${path}appointment-feedback`,
    unsubscribe: `${path}unsubscribe`,
    unsubscribeBuilding: `${path}unsubscribe-building`,
    unsubscribeRegion: `${path}unsubscribe-region`,
    unsubscribeCompany: `${path}unsubscribe-company`,
    compare: `${path}buildings-compare`,
    shareCompare: `${path}buildings-compare-share`,
    plans: `${path}plans`,
    news: `${path}news`,
    contacts: `${path}contacts`,
    login: `${path}login`,
    profileMenu: `${path}profile-menu`,
    settings: `${path}settings`,
    market: `${path}market`,
  };

  initAppVersion();

  moment.locale(locale);

  const getCategory = (route) => {
    switch (route) {
      case routes.main:
        return "my_favorites";
      case routes.question:
      case routes.questionSupport:
      case routes.questionFormOnly:
        return "developer_question_form";
      case routes.appointment:
        return "visit_form";
      case routes.cancelAppointment:
        return "cancel_visit";
      case routes.appointmentFeedback:
        return "visit_feedback";
      case routes.unsubscribe:
      case routes.unsubscribeBuilding:
      case routes.unsubscribeRegion:
      case routes.unsubscribeCompany:
        return "unsubscribe";
      case routes.compare:
      case routes.shareCompare:
        return "my_comparison";
      case routes.plans:
        return "my_layouts";
      case routes.news:
        return "my_updates";
      case routes.login:
        return "auth";
      case routes.profileMenu:
        return "profile_menu";
      case routes.settings:
        return "my_profile";
      case routes.contacts:
        return "my_requests";
      default:
        return "my_favorites";
    }
  };

  useEffect(() => {
    setGaCategory(getCategory(pathname));
    // eslint-disable-next-line
  }, [pathname]);

  return (
    <>
      <Helmet htmlAttributes={{ lang: locale }}>
        <title>{messages[locale].title}</title>
      </Helmet>
      <ErrorBoundary>
        <IntlProvider locale={locale} messages={messages[locale]}>
          <QueryClientProvider client={queryClient}>
            <BaseContextProvider locale={locale} routes={routes}>
              <ABTestingContextProvider>
                <UserContextProvider>
                  <Switch>
                    <Route
                      path={routes.questionFormOnly}
                      component={DeveloperQuestionPage}
                      exact
                    />
                    <Route
                      path={routes.profileMenu}
                      component={NavigationProfileMenuContent}
                      exact
                    />
                    <Route
                      path={routes.login}
                      component={AuthorizationPage}
                      exact
                    />
                    <Route
                      path="*"
                      render={() => (
                        <FavoriteContextProvider>
                          <Main />
                        </FavoriteContextProvider>
                      )}
                    />
                  </Switch>
                </UserContextProvider>
              </ABTestingContextProvider>
            </BaseContextProvider>
          </QueryClientProvider>
        </IntlProvider>
      </ErrorBoundary>
    </>
  );
};

Root.propTypes = {
  locale: PropTypes.oneOf(["uk", "ru"]),
  location: PropTypes.shape({
    pathname: PropTypes.string,
    search: PropTypes.string,
  }),
  match: PropTypes.shape({
    url: PropTypes.string,
  }),
};

export default Root;
