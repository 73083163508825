import React from "react";
import cn from "classnames";
import styles from "./UserAvatar.module.scss";

export const UserAvatar = ({ className, size, src, children }) => {
  return (
    <div
      className={cn(styles.root, className, {
        [styles.small]: size === "small",
        [styles.large]: size === "large",
      })}
    >
      {src ? <img src={src} alt="" /> : <div>U</div>}
      {children}
    </div>
  );
};
