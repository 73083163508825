import React from "react";
import PropTypes from "prop-types";
import { ReactComponent as FavIconFlat } from "icons/favorite.svg";
import UIFavoriteButton, {
  UIFavoriteButtonAppearance,
} from "../UIKit/FavoriteButton/FavoriteButton";

import { useFavoriteButton } from "helpers/hooks";

const FavoriteButton = ({
  className,
  appearance = "ghost-light",
  icon,
  id,
  options,
}) => {
  const { isActiveFavorite, onFavoriteClick } = useFavoriteButton({
    id,
    options,
  });

  if (
    !icon &&
    (appearance === UIFavoriteButtonAppearance.SECONDARY ||
      appearance === UIFavoriteButtonAppearance.OVERLAY)
  ) {
    icon = <FavIconFlat />;
  }

  return (
    <div className={className}>
      <UIFavoriteButton
        appearance={appearance}
        onClick={onFavoriteClick}
        active={isActiveFavorite}
        icon={icon}
      />
    </div>
  );
};

FavoriteButton.propTypes = {
  id: PropTypes.number.isRequired,
  className: PropTypes.string,
  icon: UIFavoriteButton.propTypes.icon,
  appearance: UIFavoriteButton.propTypes.appearance,
};

export default FavoriteButton;
