import React, { useState, useRef, useEffect } from "react";
import { FormattedMessage } from "react-intl";
import { useUserContext, useBaseContext } from "helpers/hooks";
import UITab from "../components/UIKit/Tab";
import styles from "./Tabs.module.scss";
import Popup from "../components/Popup";
import Authorization from "../Authorization";
import { useLocation } from "react-router-dom";
import { useWindowSize } from "react-use";
import { useGaCategory } from "../analytics/useCategory";
import { gaEventActions } from "../analytics";

const Tabs = () => {
  const {
    state: { isGuest, id },
    updates: [updates],
  } = useUserContext();
  const location = useLocation();
  const windowSize = useWindowSize();
  const { routes } = useBaseContext();
  const [authOpened, setAuthOpened] = useState(false);
  const authAnchorEl = useRef(null);
  const tabsRef = useRef();
  const [indicatorStyles, setIndicatorStyles] = useState({
    width: 0,
    left: 0,
    transition: "",
  });
  const { getGaEvent: ga } = useGaCategory();

  useEffect(() => {
    if (!tabsRef.current) return;
    const activeTab = tabsRef.current.querySelector(".-active");
    if (!activeTab) return;
    setIndicatorStyles((prev) => ({
      width: activeTab ? activeTab.offsetWidth : 0,
      left: activeTab ? activeTab.offsetLeft : 0,
      transition: prev.left === 0 || !activeTab ? "" : "0.25s all ease-in-out",
    }));
  }, [location.pathname, windowSize.width, isGuest, updates]);

  useEffect(() => {
    if (!tabsRef.current) return;
    const activeTab = tabsRef.current.querySelector(".-active");
    if (!activeTab) return;
    tabsRef.current.scrollTo({
      behavior: "smooth",
      left: activeTab.offsetLeft,
    });
  }, []);

  if (!isGuest && !id) return null;

  return (
    <div className={styles.tabs}>
      <div className="UITabs -centered -with-border" ref={tabsRef}>
        <UITab
          navLink={routes.main}
          isActive={(_, { pathname }) =>
            [routes.main, routes.plans, routes.market].includes(pathname)
          }
          event={ga(gaEventActions.navigation_mylun.goto_my_favorites)}
        >
          <FormattedMessage id="favorites" />
        </UITab>
        <UITab
          navLink={routes.compare}
          event={ga(gaEventActions.navigation_mylun.goto_my_comparison)}
        >
          <FormattedMessage id="buildings-compare" />
        </UITab>
        <UITab
          navLink={routes.news}
          label={updates > 0 ? updates : undefined}
          event={ga(gaEventActions.navigation_mylun.goto_my_updates)}
        >
          <FormattedMessage id="news" />
        </UITab>
        {id && (
          <>
            <UITab
              navLink={routes.contacts}
              event={ga(gaEventActions.navigation_mylun.goto_my_requests)}
            >
              <FormattedMessage id="contacts.tab" />
            </UITab>
            <UITab
              navLink={routes.settings}
              event={ga(gaEventActions.navigation_mylun.goto_my_updates)}
            >
              <FormattedMessage id="settings.tab" />
            </UITab>
          </>
        )}
        {isGuest && (
          <div style={{ position: "relative" }}>
            <UITab
              primary
              onClick={() => setAuthOpened(true)}
              event={ga(gaEventActions.navigation_mylun.login_click)}
              ref={authAnchorEl}
            >
              <FormattedMessage id="login" />
            </UITab>
            <Popup
              opened={authOpened}
              onClose={() => setAuthOpened(false)}
              anchorEl={authAnchorEl.current}
              unmountOnClose
            >
              <Authorization onClose={() => setAuthOpened(false)} />
            </Popup>
          </div>
        )}
        <div
          className="UITabs-indicator"
          style={{
            "--width": `${indicatorStyles.width}px`,
            transform: `translateX(${indicatorStyles.left}px)`,
            transition: indicatorStyles.transition,
          }}
        />
      </div>
    </div>
  );
};

export default Tabs;
