import React from "react";
import { FormattedMessage } from "react-intl";
import { UIButton } from "../components/UIKit/Button";
import { UITypography } from "../components/UIKit/Typography";
import styles from "./Authorization.module.scss";
import { gaEventActions as gaActions } from "../analytics";

const AuthorizationEmail = ({ onClose }) => (
  <>
    <UITypography className={styles.section}>
      <FormattedMessage id="login.response.email" />
    </UITypography>
    <div className={styles.emailActions}>
      <UIButton
        event={gaActions.auth.email_verification.close_click}
        onClick={onClose}
      >
        <FormattedMessage id="ok" />
      </UIButton>
    </div>
  </>
);

export default AuthorizationEmail;
