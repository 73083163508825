import React from "react";
import { RefreshComponents } from "@flatfy/supernova-new";
import { FormattedMessage } from "react-intl";
import { useBaseContext } from "helpers/hooks";
import cx from "classnames";
import styles from "./FormTerms.module.scss";

const FormTerms = ({ isAuth, className }) => {
  const { locale } = useBaseContext();
  const termsLink = `${process.env.REACT_APP_SITE_LINK}/${locale}/terms`;

  const onClick = (e) => {
    e.preventDefault();
    e.stopPropagation();
    window.open(termsLink, "_blank");
  };

  return (
    <RefreshComponents.Typography variant="tiny" component="div" className={cx(styles.wrapper, className)}>
      <FormattedMessage id={`form.terms${isAuth ? ".auth" : ""}`} />
      <a href={termsLink} className={styles.link} onClick={onClick}>
        <FormattedMessage id="form.terms.link" />
      </a>
    </RefreshComponents.Typography>
  );
};

export default FormTerms;
