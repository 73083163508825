import React, { useMemo } from "react";
import cx from "classnames";
import styles from "./NavigationChips.module.scss";
import { useBaseContext, useMarketFavorites } from "../helpers/hooks";
import { useRouteMatch, NavLink } from "react-router-dom";
import { FormattedMessage } from "react-intl";

const NavigationChips = () => {
  const { routes } = useBaseContext();
  const mainMatch = useRouteMatch(routes.main).isExact;
  const showChips =
    mainMatch || [routes.plans, routes.market].some(useRouteMatch);
  const marketFavorites = useMarketFavorites();

  const chips = useMemo(
    () => [
      { name: "buildings", id: 1, to: routes.main },
      {
        name: "flats",
        id: 2,
        to: routes.market,
      },
      { name: "plans", id: 3, to: routes.plans },
    ],
    [routes]
  );

  if (!showChips) return null;

  return (
    <div className="wrapper">
      <div className={cx(styles.root, "UIChips")}>
        {chips.map((chip) => (
          <NavLink
            className={styles.chip}
            key={chip.id}
            to={chip.to}
            activeClassName={styles.active}
            exact
          >
            <FormattedMessage id={chip.name} />
          </NavLink>
        ))}
      </div>
    </div>
  );
};

export default NavigationChips;
