import React from "react";
import { FormattedMessage } from "react-intl";
import styles from "./HomeGreetings.module.scss";
import { ReactComponent as Logo } from "../../icons/mylun-logo-icon.svg";

const HomeGreetings = () => {
  return (
    <div className={styles.wrapper}>
      <div className={styles.icon}>
        <Logo />
      </div>
      <div className={styles.content}>
        <h3>
          <FormattedMessage id="home.guest.title" />
        </h3>
        <span>
          <FormattedMessage id="home.guest.subtitle" />
        </span>
      </div>
    </div>
  );
};

export default HomeGreetings;
