import React, { useState } from "react";
import { UIPopup } from "components/UIKit/Popup";
import { UIModal } from "components/UIKit/Modal";
import Authorization from "../../Authorization";
import styles from "./NavigationProfileMenu.module.scss";
import { NavigationProfileMenuContent } from "./NavigationProfileMenuContent";

const noop = () => {};

const NavigationProfileMenu = ({ opened, anchorEl, onClose = noop }) => {
  const [loginOpened, setLoginOpened] = useState(false);

  const handleLoginOpen = () => {
    setLoginOpened(true);
    onClose(); // закрываем текущий попап, чтоб overlay на мобилке не накладывался
  };

  return (
    <div className={styles.root}>
      <UIPopup
        opened={opened}
        onClose={onClose}
        anchorEl={anchorEl}
        className={styles.popup}
        classes={{ content: styles.popupContent }}
        placementX="right"
      >
        <NavigationProfileMenuContent
          onClose={onClose}
          onLoginClick={handleLoginOpen}
        />
      </UIPopup>
      <UIModal
        opened={loginOpened}
        onClose={() => setLoginOpened(false)}
        modifiers={{ centered: true }}
        width="332px"
        closeButton
        unmountOnClose
      >
        <div className={styles.modal}>
          <Authorization onClose={() => setLoginOpened(false)} />
        </div>
      </UIModal>
    </div>
  );
};

export default NavigationProfileMenu;
