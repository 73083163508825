export const GEO_TYPE = {
  REGION: "region",
  REGION_DISTRICT: "region_district",
  MUNICIPALITY: "municipality",
  AGGLOMERATION: "agglomeration",
  LOCALITY: "city",
  COUNTY: "county",
  DISTRICT: "district",
  MICRODISTRICT: "microdistrict",
  RESIDENTAL_COMPLEX: "residential_complex",
  SUBWAY_ROUTE: "subway_route",
  SUBWAY: "subway",
  STREET: "street",
  HOUSE: "house",
};
