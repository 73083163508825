import React, { useRef, useState } from "react";
import PropTypes from "prop-types";
import cn from "classnames";
import { FormattedMessage } from "react-intl";
import { useFavoriteLinkActive, useBaseContext } from "helpers/hooks";
import { useHoverDirty } from "react-use";
import UITab from "../components/UIKit/Tab";
import { UIButton } from "../components/UIKit/Button";
import NavigationProfileMenu from "./NavigationProfileMenu";
import { useGaCategory } from "../analytics/useCategory";
import { gaEventActions as gaActions } from "../analytics";

import { ReactComponent as Logo } from "../icons/logo.svg";
import { ReactComponent as MenuIcon } from "../icons/menu.svg";

import style from "./Navigation.module.scss";
import NavigationSidebar from "./NavigationSidebar";

const Navigation = ({ links }) => {
  const { getGaEvent: ga } = useGaCategory();
  const { routes } = useBaseContext();
  const isFavoritesActive = useFavoriteLinkActive();
  const myLunTabRef = useRef();
  const isMyLunTabHovering = useHoverDirty(myLunTabRef);
  const [sidebarOpened, setSidebarOpened] = useState(false);

  return (
    <>
      <div className={cn(style.navigation, "nav")}>
        <div className={style.bar}>
          <div className="wrapper">
            <div className="nav-main">
              <a href={process.env.REACT_APP_SITE_LINK} className="nav-logo">
                <Logo />
              </a>
            </div>
            <div className="nav-links">
              <div className="UITabs -restyled">
                {/* UITabs */}
                <UITab
                  href={links.home.url}
                  event={ga(gaActions.navigation_top.goto_home)}
                  classes={{
                    tab: style.tab,
                  }}
                >
                  <FormattedMessage id="navigation.home" />
                </UITab>
                <UITab
                  href={links.building.url}
                  event={ga(gaActions.navigation_top.goto_catalog)}
                  classes={{
                    tab: style.tab,
                  }}
                >
                  <FormattedMessage id="navigation.catalog" />
                </UITab>
                <UITab
                  href={links.flats_buy.url}
                  event={ga(gaActions.navigation_top.goto_flat_sale)}
                  classes={{
                    tab: style.tab,
                    label: style.label,
                  }}
                >
                  <FormattedMessage id="navigation.flats_buy" />
                </UITab>
                <UITab
                  href={links.flats_rent.url}
                  event={ga(gaActions.navigation_top.goto_flat_rent)}
                  classes={{
                    tab: style.tab,
                    label: style.label,
                  }}
                >
                  <FormattedMessage id="navigation.flats_rent" />
                </UITab>
                <div ref={myLunTabRef} className={style.profile}>
                  <UITab
                    active={isFavoritesActive}
                    hovered={isMyLunTabHovering}
                    navLink={routes.main}
                    event={ga(gaActions.navigation_top.goto_my_favorites)}
                    classes={{
                      tab: style.tab,
                    }}
                  >
                    Мій ЛУН
                  </UITab>
                  <NavigationProfileMenu
                    opened={isMyLunTabHovering}
                    anchorEl={myLunTabRef.current}
                  />
                  <div
                    className={cn("UITabs-indicator", style.profileIndicator)}
                  />
                </div>
              </div>
            </div>
            <div className="nav-actions">
              <UIButton
                appearance="ghost-dark-new"
                event={ga(gaActions.navigation_top.goto_my_favorites)}
                icon={<MenuIcon />}
                size="small"
                className={style.burger}
                onClick={() => setSidebarOpened(true)}
              />
            </div>
          </div>
        </div>
      </div>
      <NavigationSidebar
        opened={sidebarOpened}
        onClose={() => setSidebarOpened(false)}
        navigation={links}
      />
    </>
  );
};

const linkShape = PropTypes.shape({ url: PropTypes.string });

const linkDefault = { url: "#" };

export const navigationPropTypes = {
  links: PropTypes.shape({
    building: linkShape,
    flats_buy: linkShape,
    flats_rent: linkShape,
    home: linkShape,
  }),
};

export const navigationDefaultProps = {
  links: {
    building: linkDefault,
    flats_buy: linkDefault,
    flats_rent: linkDefault,
    home: linkDefault,
  },
};

Navigation.propTypes = navigationPropTypes;

Navigation.defaultProps = navigationDefaultProps;

export default Navigation;
