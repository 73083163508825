import React, { useEffect, useState, lazy, Suspense } from "react";
import { Switch, Route, useLocation, useRouteMatch } from "react-router-dom";
import cx from "classnames";
import { getNavigation } from "helpers/api";
import { useBaseContext } from "helpers/hooks";
import Navigation from "./Navigation/Navigation";
import BottomNavigation from "./Navigation/BottomNavigation/BottomNavigation";
import {
  UnsubscribeBuilding,
  UnsubscribeRegion,
  UnsubscribeCompany,
} from "./Messages/Unsubscribe/types";
import Footer from "./Footer/Footer";
import Home from "./Home/Home";
import AppointmentFeedback from "./Messages/AppointmentFeedback/AppointmentFeedback";
import { useTabsRouteMatch, useMobile } from "./helpers/hooks";
import Tabs from "./Tabs/Tabs";
import EmptyPlaceholder from "./EmptyPlaceholder";
import { UILoader } from "./components/UIKit/Loader";
import { LayoutContextProvider } from "./features/layouts";
import { CompareBuildingIdsContextProvider } from "./features/compareBuildingIds";
import NavigationChips from "./NavigationChips";
import LunMarketFavorites from "./LunMarketFavorites";
import FooterMobile from "./Footer/FooterMobile";

require("dotenv").config();

const DeveloperQuestion = lazy(() =>
  import(
    /* webpackChunkName: "DeveloperQuestionPage" */ "./Messages/DeveloperQuestion/index"
  )
);

const Appointment = lazy(() =>
  import(
    /* webpackChunkName: "Appoinment" */ "./Messages/Appointment/Appoinment"
  )
);

const AppointmentCancel = lazy(() =>
  import(
    /* webpackChunkName: "AppointmentCancel" */ "./Messages/AppointmentCancel/AppointmentCancel"
  )
);

const UnsubscribeGeneral = lazy(() =>
  import(
    /* webpackChunkName: "UnsubscribeGeneral" */ "./Messages/Unsubscribe/UnsubscribeGeneral"
  )
);

const ComparePage = lazy(() =>
  import(/* webpackChunkName: "ComparePage" */ "./pages/ComparesPage")
);

const NewsPage = lazy(() =>
  import(/* webpackChunkName: "NewsPage" */ "./pages/NewsPage")
);

const ContactsPage = lazy(() =>
  import(/* webpackChunkName: "ContactsPage" */ "./pages/ContactsPage")
);

const Layouts = lazy(() =>
  import(/* webpackChunkName: "Layouts" */ "./Layouts/index")
);

const ProfileSettings = lazy(() =>
  import(/* webpackChunkName: "ProfileSettings" */ "./ProfileSettings")
);

const Main = () => {
  const { region, regionActions: actions, routes } = useBaseContext();
  const [links, setLinks] = useState();
  const [search, setSearch] = useState(false);
  const showTabs = useTabsRouteMatch();
  const location = useLocation();
  const withoutWrapperClassName = useRouteMatch(routes.market);
  const { isMobile } = useMobile();

  useEffect(() => {
    getNavigation().then((response) => {
      if (response.status !== "ok") return;
      setLinks(response.data);
      actions.setActiveLink(response.data.building.url);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [region.active.region_id]);

  useEffect(() => {
    window.ga("send", "pageview", location.pathname + location.search);
  }, [location.pathname, location.search]);

  return (
    <>
      <Navigation links={links} search={search} setSearch={setSearch} />
      {showTabs && <Tabs />}
      <div className="layout">
        <Suspense fallback={<UILoader show />}>
          <NavigationChips />
          <div className={cx({ wrapper: !withoutWrapperClassName })}>
            <Switch>
              <Route
                path={routes.questionSupport}
                render={() => <DeveloperQuestion />}
                exact
              />

              <Route path={routes.question} component={DeveloperQuestion} />

              <Route path={routes.appointment} component={Appointment} />
              <Route
                path={routes.appointmentFeedback}
                component={AppointmentFeedback}
              />
              <Route
                path={routes.cancelAppointment}
                component={AppointmentCancel}
              />
              <Route path={routes.unsubscribe} component={UnsubscribeGeneral} />
              <Route
                path={routes.unsubscribeBuilding}
                component={UnsubscribeBuilding}
              />
              <Route
                path={routes.unsubscribeRegion}
                component={UnsubscribeRegion}
              />
              <Route
                path={routes.unsubscribeCompany}
                component={UnsubscribeCompany}
              />
              <Route
                path={[routes.main, routes.compare, routes.shareCompare]}
                exact
                render={() => (
                  <Switch>
                    <CompareBuildingIdsContextProvider>
                      <Route path={routes.main} component={Home} exact />
                      <Route path={routes.compare} component={ComparePage} />
                      <Route
                        path={routes.shareCompare}
                        component={ComparePage}
                      />
                    </CompareBuildingIdsContextProvider>
                  </Switch>
                )}
              />
              <Route path={routes.contacts} component={ContactsPage} />
              <Route path={routes.news} component={NewsPage} />
              <Route path={routes.settings} component={ProfileSettings} />
              <Route
                path={routes.plans}
                render={() => (
                  <LayoutContextProvider>
                    <Layouts />
                  </LayoutContextProvider>
                )}
              />
              <Route path={routes.market} component={LunMarketFavorites} />
              <Route
                path="*"
                render={() => (
                  <EmptyPlaceholder
                    title="notFound.title"
                    subtitle="notFound.subtitle"
                    icon="search"
                    action={{
                      event: {
                        category: "not_found",
                        action: "home",
                        label: "click",
                      },
                      title: "notFound.toMain",
                      link: "/",
                    }}
                  />
                )}
              />
            </Switch>
          </div>
        </Suspense>
      </div>
      {isMobile ? <FooterMobile /> : <Footer />}
      <BottomNavigation links={links} />
    </>
  );
};

export default Main;
