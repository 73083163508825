import * as Sentry from "@sentry/browser";
import { COMPARE_TYPES } from "./reducer";
import { getFavoriteBuildingsTable } from "../../helpers/api";

const add = (dispatch) => (ids) => {
  dispatch({ type: COMPARE_TYPES.ADD_LOADING, payload: { ids } });
  getFavoriteBuildingsTable({ buildings_id: ids }).then((res) => {
    dispatch({ type: COMPARE_TYPES.ADD, payload: res.data });
  });
};
const remove = (dispatch) => (id) => {
  dispatch({ type: COMPARE_TYPES.REMOVE, payload: { id } });
};

const fetch = (dispatch) => (ids) => {
  dispatch({ type: COMPARE_TYPES.FETCH_LOADING });
  getFavoriteBuildingsTable({ buildings_id: ids })
    .then((res) => {
      dispatch({ type: COMPARE_TYPES.FETCH, payload: res.data });
    })
    .catch(Sentry.captureException);
};

const reorder = (dispatch) => ({ startIndex, endIndex }) => {
  dispatch({ type: COMPARE_TYPES.REORDER, payload: { startIndex, endIndex } });
};

const reset = (dispatch) => () => {
  dispatch({ type: COMPARE_TYPES.RESET });
};

const filter = (dispatch) => (hiddenProps) => {
  dispatch({ type: COMPARE_TYPES.SET_HIDDEN_PROPS, payload: { hiddenProps } });
};

export { fetch, add, remove, filter, reorder, reset };
