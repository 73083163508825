import React from "react";
import { attachGaDataAttributes } from "../../helpers/analytics";
import { useGaCategory } from "../../analytics/useCategory";

const AnalyticsElement = ({
  eventLabel,
  eventAction,
  eventCategory,
  eventOptions,
  element: Root = "div",
  ...props
}) => {
  const { category } = useGaCategory();
  return (
    <Root
      {...attachGaDataAttributes({
        category: eventCategory || category,
        action: eventAction,
        label: eventLabel,
        options: eventOptions,
      })}
      {...props}
    />
  );
};

export default AnalyticsElement;
