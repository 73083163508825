import React from "react";
import cx from "classnames";
import { Support, Viber, TelegramSimple } from "icons";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";
import { RefreshComponents } from "@flatfy/supernova-new";
import AnalyticsElement from "components/AnalyticsElement";
import {
  COMMUNICATION_TYPE,
  COMMUNICATION_TYPE_LABELS,
  NORMALIZED_SOCIAL_EVENTS,
} from "../../../constants/developer-question";
import styles from "./DeveloperQuestionSocial.module.scss";

const DeveloperQuestionSocial = ({ link, type, analytics, buildingName }) => {
  // Fix for viber chrome ios
  const handleOpenLink = (evt) => {
    evt.preventDefault();
    window.open(link);
  };

  return (
    <div className={styles.root}>
      <div className={styles.content}>
        <div className={styles.icon}>
          <Support />
        </div>
        <div className={styles.title}>
          <RefreshComponents.Typography variant="h6">
            <FormattedMessage id="communication.social.title" />
            {`${buildingName} через ${COMMUNICATION_TYPE_LABELS[type]}`}
          </RefreshComponents.Typography>
        </div>
      </div>

      <div className={styles.bottomAction}>
        <AnalyticsElement
          element={RefreshComponents.Button}
          eventCategory={analytics.eventCategory}
          eventAction={analytics.eventAction}
          eventLabel={`goto_${NORMALIZED_SOCIAL_EVENTS[type]}`}
          eventOptions={analytics.eventOptions}
          href={link}
          component="a"
          className={cx({
            [styles.viberAction]: type === COMMUNICATION_TYPE.VIBER,
            [styles.telegramAction]: type === COMMUNICATION_TYPE.TELEGRAM,
          })}
          fullWidth
          leftIcon={
            type === COMMUNICATION_TYPE.VIBER ? <Viber /> : <TelegramSimple />
          }
          variant="primary"
          onClick={handleOpenLink}
        >
          <FormattedMessage id="writeIn" /> {COMMUNICATION_TYPE_LABELS[type]}
        </AnalyticsElement>
      </div>
    </div>
  );
};

DeveloperQuestionSocial.propTypes = {
  link: PropTypes.string,
  type: PropTypes.oneOf([
    COMMUNICATION_TYPE.VIBER,
    COMMUNICATION_TYPE.TELEGRAM,
  ]),
  analytics: PropTypes.any,
  buildingName: PropTypes.string,
};

export default DeveloperQuestionSocial;
