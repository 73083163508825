import React from "react";
import PropTypes from "prop-types";
import cn from "classnames";
import "./FavoriteButton.scss";
import { ReactComponent as FavIconWithShadow } from "icons/favorite-shadow.svg";

export const UIFavoriteButtonAppearance = {
  SECONDARY: "secondary",
  GHOST_LIGHT: "ghost-light",
  GHOST_DARK: "ghost-dark",
  OVERLAY: "overlay",
};

const UIFavoriteButton = ({
  appearance = UIFavoriteButtonAppearance.SECONDARY,
  active,
  onClick,
  icon = <FavIconWithShadow />,
  className,
}) => (
  <div
    className={cn(
      "UIFavoriteButton",
      {
        "-overlay": appearance === UIFavoriteButtonAppearance.OVERLAY,
        "-ghost-light": appearance === UIFavoriteButtonAppearance.GHOST_LIGHT,
        "-ghost-dark": appearance === UIFavoriteButtonAppearance.GHOST_DARK,
        "-secondary": appearance === UIFavoriteButtonAppearance.SECONDARY,
        "-active": active,
      },
      className
    )}
    onClick={onClick}
  >
    {icon}
  </div>
);

UIFavoriteButton.propTypes = {
  appearance: PropTypes.oneOf(Object.values(UIFavoriteButtonAppearance)),
  active: PropTypes.bool,
  className: PropTypes.string,
  onClick: PropTypes.func,
  icon: PropTypes.element,
};

export default UIFavoriteButton;
