import * as Sentry from "@sentry/browser";
import * as Api from "helpers/api";
import { FAVORITE_TYPES } from "./constants";
import { initFavoriteEntityAnalytics } from "helpers/analytics";

export const getBuildingCache = () =>
  JSON.parse(localStorage.getItem("buildings") || "[]");

const initBuildingsCache = (data) => {
  localStorage.setItem("buildings", JSON.stringify(data));
};

const setBuildingsCache = ({ data, type }) => {
  const cached = getBuildingCache();
  let newCache;
  switch (type) {
    case "add":
      newCache = [...cached, data];
      break;
    case "remove":
      newCache = cached.filter((e) => e.id === data.id);
      break;
    default:
      throw new Error();
  }
  localStorage.setItem("buildings", JSON.stringify(newCache));
};

const addFavorite = (dispatch) => async (id) => {
  await apiWrapper(
    () => Api.addFavorite({ entity: "buildings", id }),
    (data) => {
      dispatch({ type: FAVORITE_TYPES.ADD_FAVORTIE, payload: { data } });
      setBuildingsCache({ data, type: "add" });
    }
  );
};

const removeFavorite = (dispatch) => async (id) => {
  await apiWrapper(
    () => Api.removeFavorite({ entity: "buildings", id }),
    () => {
      dispatch({ type: FAVORITE_TYPES.REMOVE_FAVORTIE, payload: { id } });
      setBuildingsCache({ data: { id }, type: "remove" });
    }
  );
};

const apiWrapper = async (request, callback, errorCallback = () => {}) => {
  try {
    const res = await request();
    if (res.status === "error") throw new Error(res.message);
    callback(res.data);
  } catch (err) {
    Sentry.captureException(err);
    errorCallback();
  }
};

const fetchBuildings = (dispatch) => async (params) => {
  dispatch({ type: FAVORITE_TYPES.IS_LOADING });
  /**
   * При каждом fetchBuildings синхронизируем также ids
   * На случай, если параллельно пользователь добавил ЖК в избранное из каталога
   */
  await Promise.all([
    apiWrapper(
      () => Api.getFavoriteBuildings(params),
      (data) => {
        dispatch({
          type: FAVORITE_TYPES.SET_FAVORITES,
          payload: { data },
        });
        initBuildingsCache(data);
        initFavoriteEntityAnalytics("buildings", data);
        dispatch({ type: FAVORITE_TYPES.LOADING_DONE });
      },
      () => {
        dispatch({
          type: FAVORITE_TYPES.SET_FAVORITES,
          payload: { data: getBuildingCache() },
        });
        dispatch({ type: FAVORITE_TYPES.LOADING_DONE });
      }
    ),
    apiWrapper(Api.getFavoriteBuildingIds, (data) => {
      dispatch({ type: FAVORITE_TYPES.SET_FAVORITE_IDS, payload: { data } });
    }),
  ]);
};

const reset = (dispatch) => () => {
  dispatch({ type: FAVORITE_TYPES.RESET });
};

const fetchBuildingIds = (dispatch) => async () => {
  dispatch({ type: FAVORITE_TYPES.IS_IDS_LOADING, payload: { loading: true } });
  await apiWrapper(
    () => Api.getFavoriteBuildingIds(),
    (data) => {
      dispatch({ type: FAVORITE_TYPES.SET_FAVORITE_IDS, payload: { data } });
    }
  );
  dispatch({
    type: FAVORITE_TYPES.IS_IDS_LOADING,
    payload: { loading: false },
  });
};

export { addFavorite, removeFavorite, reset, fetchBuildings, fetchBuildingIds };
