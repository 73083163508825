import React from "react";
import cn from "classnames";
import { useUserContext } from "helpers/hooks";
import { ReactComponent as FavIcon } from "../icons/favorite.svg";

import styles from "./Navigation.module.scss";

const ProfileIcon = ({ defaultIcon = <FavIcon />, className }) => {
  const {
    state: { avatar, id },
  } = useUserContext();

  if (!id) return defaultIcon;

  return (
    <div className={cn(styles.avatar, className)}>
      {avatar ? <img src={avatar} alt="" /> : <div>U</div>}
    </div>
  );
};

export default ProfileIcon;
