import React from "react";
import "./UIButton.scss";
import cx from "classnames";
import Button from "@flatfy/supernova2/src/UIKit/Button";

export const UIButton = (props) => (
  <Button
    {...props}
    className={cx(props.className, {
      "-ghost-dark-new": props.appearance === "ghost-dark-new",
    })}
  />
);

UIButton.propTypes = Button.propTypes;
