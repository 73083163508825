import { useNotification } from "helpers/hooks";
import { useCallback } from "react";
import { useMutation } from "react-query";

const useMutationApi = (queryFnApi, config) => {
  const notifications = useNotification();

  const queryFn = useCallback(
    async (params) => {
      const res = await queryFnApi(params);
      if (res.status !== "ok") throw new Error(res.message);
      return res.data;
    },
    [queryFnApi]
  );

  return useMutation(queryFn, {
    onError: (err) => {
      notifications.showMessage("error");
      if (config.onError) config.onError(err);
    },
    ...config,
  });
};

export default useMutationApi;
