import React from "react";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";
import { IEvent } from "types/event";
import { UITypography } from "../components/UIKit/Typography";
import { UIButton } from "../components/UIKit/Button";
import styles from "./EmptyPlaceholder.module.scss";

import { ReactComponent as HappyIcon } from "./icons/happy.svg";
import { ReactComponent as KissIcon } from "./icons/kiss.svg";
import { ReactComponent as MailingIcon } from "./icons/mailing.svg";
import { ReactComponent as SearchIcon } from "./icons/search.svg";

const EmptyPlaceholderIcon = {
  HAPPY: "happy",
  KISS: "kiss",
  MAILING: "mailing",
  SEARCH: "search",
};

const EmptyPlaceholder = ({ title, subtitle, icon, action }) => (
  <div className={styles.root}>
    <div className={styles.icon}>
      {icon === EmptyPlaceholderIcon.KISS && <KissIcon />}
      {icon === EmptyPlaceholderIcon.HAPPY && <HappyIcon />}
      {icon === EmptyPlaceholderIcon.SEARCH && <SearchIcon />}
      {icon === EmptyPlaceholderIcon.MAILING && <MailingIcon />}
    </div>
    <UITypography variant="h1" className={styles.title}>
      <FormattedMessage id={title} />
    </UITypography>
    <UITypography className={styles.subtitle}>
      <FormattedMessage id={subtitle} />
    </UITypography>
    {action && (
      <div className={styles.action}>
        <UIButton attributes={{ href: action.link }} event={action.event}>
          <FormattedMessage id={action.title} />
        </UIButton>
      </div>
    )}
  </div>
);

EmptyPlaceholder.propTypes = {
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string.isRequired,
  icon: PropTypes.oneOf(Object.values(EmptyPlaceholderIcon)),
  action: PropTypes.shape({
    title: PropTypes.string.isRequired,
    link: PropTypes.string.isRequired,
    event: PropTypes.shape(IEvent).isRequired,
  }),
};

EmptyPlaceholder.defaultProps = {
  icon: "kiss",
};

export default EmptyPlaceholder;
