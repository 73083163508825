import React, { useEffect } from "react";
import "@flatfy/supernova2/src/UIKit/Input/Input.scss";
import Input from "@flatfy/supernova2/src/UIKit/Input";
import { FormattedMessage } from "react-intl";

export const UIInput = React.forwardRef((props, ref) => {
  const inputRef = React.useRef();

  const onInputRef = React.useCallback((node) => {
    if (node) {
      if (ref) {
        ref(node);
      }
      inputRef.current = node;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!inputRef.current) return;
    if (!inputRef.current.state.validity.valid) {
      const nextValidity = inputRef.current.getValidity();
      if (nextValidity.valid) {
        inputRef.current.validate();
      }
    }
  }, [props.value]);

  return (
    <Input
      renderError={(err) => <FormattedMessage id={`form.error.${err}`} />}
      ref={onInputRef}
      {...props}
    />
  );
});
