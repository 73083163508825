import React, { useContext } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { RefreshComponents } from "@flatfy/supernova-new";
import DeveloperQuestionChips from "../DeveloperQuestionChips";
import styles from "../DeveloperQuestion.module.scss";
import AnalyticsElement from "../../../components/AnalyticsElement";
import { FormTextField } from "../../../components/FormRefresh/Field";
import { FormContext } from "../../../components/FormRefresh";
import { attachGaDataAttributes } from "../../../helpers/analytics";

const DeveloperQuestionStart = ({
  building,
  analytics,
  onSubmit,
  isQuestionFromFlats,
}) => {
  const intl = useIntl();
  const form = useContext(FormContext);
  return (
    <>
      <RefreshComponents.Typography
        variant="subtitle2"
        component="div"
        className={styles.title}
      >
        <FormattedMessage id="question.popup.question.title" />
      </RefreshComponents.Typography>
      <DeveloperQuestionChips
        building={building}
        analytics={analytics}
        onChange={form.onChange}
        isQuestionFromFlats={isQuestionFromFlats}
      />
      <div className={styles.row}>
        <FormTextField
          name="message"
          label={intl.formatMessage({ id: "comment" })}
          required={!form.value.chips}
          rows={4}
          multiline
          TextareaProps={{
            rows: 4,
            ...attachGaDataAttributes(
              analytics.getEvent("question_type")
            ),
          }}
          variant="outline"
        />
      </div>
      <div className={styles.submit}>
        <div className={styles.bottomAction}>
          <AnalyticsElement
            element={RefreshComponents.Button}
            variant="primary"
            onClick={onSubmit}
            type="button"
            disabled={!form.value.message && form.value.chips.length === 0}
            eventCategory={analytics.eventCategory}
            eventAction={analytics.eventAction}
            eventLabel="goto_personal_info"
            eventOptions={analytics.eventOptions}
            fullWidth
          >
            <FormattedMessage id="next" />
          </AnalyticsElement>
        </div>
      </div>
    </>
  );
};

export default DeveloperQuestionStart;
