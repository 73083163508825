import { useState } from "react";
import { useHistory } from "react-router-dom";
import useBaseContext from "./useBaseContext";
import useSharedBuildingIds from "./useSharedBuildingIds";
import { useCompareBuildingIdsContext } from ".";

const useAddSharedCompares = () => {
  const history = useHistory();
  const compares = useCompareBuildingIdsContext();
  const { showMessage, routes } = useBaseContext();
  const [disabledAction, setDisabledAction] = useState(false);
  const sharedBuildingIds = useSharedBuildingIds();

  const addSharedCompares = async () => {
    setDisabledAction(true);
    await compares.add.mutate(sharedBuildingIds);
    history.push(routes.compare);
    showMessage("compare.added.shared");
  };

  return {
    disabledAction,
    addSharedCompares,
  };
};

export default useAddSharedCompares;
