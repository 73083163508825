import React from "react";
import uuid from "uuid";
import style from "./Placeholder.module.scss";
import { HomeCardGrid } from "../Home/HomeCard/HomeCard";
import cn from "classnames";

export const TabPlaceholder = () => <div className={style.tab} />;

export const RowPlaceholder = () => <div className={style.title} />;

export const CardPlaceholder = ({
  subtitle = true,
  className,
  children,
  size = "large",
}) => (
  <div
    className={cn(
      style.card,
      {
        [style.cardMedium]: size === "medium",
        [style.cardSmall]: size === "small",
      },
      className
    )}
  >
    <div className={style.content}>
      <RowPlaceholder />
      {subtitle && <div className={style.subtitle} />}
      {children}
    </div>
  </div>
);

export const GridPlaceholder = ({ wide, noTitle }) => (
  <div className={style.block}>
    {!noTitle && <RowPlaceholder />}
    <div className={style.grid}>
      <HomeCardGrid>
        {Array.from({ length: wide ? 3 : 4 }, uuid).map((id) => (
          <div className={`${style.cell} ${wide && style.wide}`} key={id}>
            <CardPlaceholder />
          </div>
        ))}
      </HomeCardGrid>
    </div>
  </div>
);

const NewsBlockPlaceholder = () => (
  <div className={style.block}>
    <div>
      <RowPlaceholder />
    </div>
    <div>
      <CardPlaceholder />
      <CardPlaceholder />
    </div>
  </div>
);

export const NewsPlaceholder = () => (
  <div className={style.news}>
    {Array.from({ length: 4 }, uuid).map((key) => (
      <NewsBlockPlaceholder key={key} />
    ))}
  </div>
);

export const TwoColumnGridPlaceholder = ({ noTitle }) => (
  <div className={style.block}>
    {!noTitle && <RowPlaceholder />}
    <div className={style.row}>
      {Array.from({ length: 4 }, uuid).map((id) => (
        <div className={style.contactsCell} key={id}>
          <CardPlaceholder />
        </div>
      ))}
    </div>
  </div>
);

export const ComparePlaceholder = () => (
  <div className={style.compares}>
    <RowPlaceholder />
    <div className={style.row}>
      {Array.from({ length: 5 }, uuid).map((id) => (
        <div className={style.compareCell} key={id}>
          <CardPlaceholder subtitle={false} />
        </div>
      ))}
    </div>
    {Array.from({ length: 4 }, uuid).map((id) => (
      <div className={style.compareRow} key={id}>
        <div className={style.card} />
      </div>
    ))}
  </div>
);

export const BackgroundPlaceholder = () => <div className={style.background} />;
