import { useState } from "react";
import { Breakpoints } from "../../constants/breakpoints";

const useMobile = () => {
  const [isMobile, setIsMobile] = useState(
    window.innerWidth < Breakpoints.MEDIUM
  );

  const mediaHandler = (e) => {
    setIsMobile(e.matches);
  };

  return {
    isMobile,
    mediaHandler,
    isTouch: "ontouchstart" in document.documentElement,
  };
};

export default useMobile;
