import React from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import ReactDOM from "react-dom";
import "./index.scss";
import { initAnalytics } from "helpers/analytics";
import { initLoggers } from "helpers/loggers";
import Root from "./Root";

initAnalytics();
initLoggers();

const App = () => (
  <Router>
    <Switch>
      <Route
        path="/ru/"
        render={(props) => <Root key="ru" locale="ru" {...props} />}
      />
      <Route
        path="/"
        render={(props) => <Root key="uk" locale="uk" {...props} />}
      />
    </Switch>
  </Router>
);

ReactDOM.render(<App />, document.getElementById("root"));
