import { useState, useEffect } from "react";
import { useAnalyticsTimeEvent, useUrlParams } from "helpers/hooks";
import { sendEvent } from "helpers/analytics";

export const APPOINTMENT_CATEGORY = "visit_form";

export const STEPS = {
  START: "start",
  FORM: "personal_info",
  DATE_TIME: "date_time",
  PHONE: "phone_validation",
  FINAL: "final",
};

export const STEPS_LIST = [
  STEPS.START,
  STEPS.DATE_TIME,
  STEPS.FORM,
  STEPS.PHONE,
  STEPS.FINAL,
];

const useAppointmentEvents = (stepIndex) => {
  const { urlParams } = useUrlParams();
  const [stepName, setStepName] = useState(STEPS.START);

  const startTtf = useAnalyticsTimeEvent(STEPS.START);
  const formTtf = useAnalyticsTimeEvent(STEPS.FORM);
  const dateTimeTtf = useAnalyticsTimeEvent(STEPS.DATE_TIME);
  const phoneTtf = useAnalyticsTimeEvent(STEPS.PHONE);

  const stepsTtf = {
    [STEPS.START]: startTtf,
    [STEPS.PHONE]: phoneTtf,
    [STEPS.DATE_TIME]: dateTimeTtf,
    [STEPS.FORM]: formTtf,
  };

  const [dayIndex, setDayIndex] = useState(null);
  const [timeType, setTimeType] = useState(null);
  const [formFieldsTtf, setFormFieldsTtf] = useState({});

  const getEventStr = (label, action = stepName) =>
    `${APPOINTMENT_CATEGORY}|${action}|${label}`;
  const getEvent = (label, action = stepName) => ({
    category: APPOINTMENT_CATEGORY,
    action,
    label,
  });

  const getBackLabel = () => {
    switch (stepName) {
      case STEPS.START:
      case STEPS.FINAL:
        return "close";
      case STEPS.DATE_TIME:
        return "goto_start";
      case STEPS.FORM:
        return "goto_date_time";
      case STEPS.PHONE:
        return "goto_form";
      default:
        return null;
    }
  };

  const sendFinalEvents = (values) => {
    sendEvent(getEventStr("success_all", STEPS.FINAL));
    if (urlParams.eventCategory && urlParams.eventAction) {
      sendEvent(
        `${urlParams.eventCategory}|${urlParams.eventAction}|success_visit_form`
      );
    }

    /* TTF STEPS events */
    startTtf.send(`${APPOINTMENT_CATEGORY}|${STEPS.START}`);
    formTtf.send(`${APPOINTMENT_CATEGORY}|${STEPS.FORM}`);
    dateTimeTtf.send(`${APPOINTMENT_CATEGORY}|${STEPS.DATE_TIME}`);
    phoneTtf.send(`${APPOINTMENT_CATEGORY}|${STEPS.PHONE}`);
    sendEvent(getEventStr("time_complete", STEPS.FINAL), {
      eventValue:
        startTtf.seconds +
        formTtf.seconds +
        dateTimeTtf.seconds +
        phoneTtf.seconds,
    });

    /* TTF Fields events */
    Object.entries(formFieldsTtf).forEach(([name, seconds]) => {
      sendEvent(
        `${APPOINTMENT_CATEGORY}|${
          name === "code" ? STEPS.PHONE : STEPS.FORM
        }|ttf_${name}_type`,
        { eventValue: seconds }
      );
    });

    /* Final appointment data based on user choice */
    sendEvent(getEventStr(`${values.communication}_comms_final`, STEPS.FORM));
    sendEvent(getEventStr(`day_${dayIndex}_final`, STEPS.DATE_TIME));
    sendEvent(
      getEventStr(`day_week_${values.weekIndex}_final`, STEPS.DATE_TIME)
    );
    sendEvent(getEventStr(`taxi_${values.isTaxi}_final`, STEPS.FORM));
    sendEvent(getEventStr(`${timeType}_time_final`, STEPS.DATE_TIME));
    if (values.isAnotherSalesCenter)
      sendEvent(getEventStr("another_office_final", STEPS.START));

    if (values.opportunities) {
      values.opportunities.forEach((opportunityId) => {
        sendEvent(getEventStr(`${opportunityId}_final`, STEPS.START));
      });
    }

    if (values.rooms) {
      values.rooms.forEach((room) => {
        sendEvent(getEventStr(`${room}_room_final`, STEPS.START));
      });
    }

    const isChips = Boolean(
      (values.opportunities && values.opportunities.length) ||
        (values.rooms && values.rooms.length)
    );
    sendEvent(getEventStr(`chips_${isChips}_final`, STEPS.START));
  };

  const setFormFieldTtf = (name, { fillingTime: fillingTimeMs }) => {
    setFormFieldsTtf((prevState) => ({
      ...prevState,
      [name]: (prevState[name] || 0) + fillingTimeMs / 1000,
    }));
  };

  useEffect(() => {
    setStepName((prevStep) => {
      const nextStep = STEPS_LIST[stepIndex - 1];
      stepsTtf[prevStep].stop();
      if (stepsTtf[nextStep]) {
        stepsTtf[nextStep].start();
      }
      return nextStep;
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stepIndex]);

  return {
    setDayIndex,
    setTimeType,
    sendFinalEvents,
    getEvent,
    getEventStr,
    stepName,
    getBackLabel,
    sendEvent: (label) => sendEvent(getEventStr(label)),
    setFormFieldTtf,
    eventCategory: urlParams.eventCategory,
  };
};

export default useAppointmentEvents;
