export const AUTH_STEP = {
  MAIN: "MAIN",
  CODE: "CODE",
  EMAIL: "EMAIL",
};

export const EVENT_CATEGORY = "auth";

export const ERROR_CODE = {
  CODE_INVALID: "code_invalid",
  CODE_EXPIRE: "code_expire",
  TOKEN_INVALID: "token_invalid",
};

export const ERROR_LOCALE_CODE = {
  [ERROR_CODE.CODE_INVALID]: "login.error.invalidCode",
  [ERROR_CODE.CODE_EXPIRE]: "login.error.expiredCode",
};
