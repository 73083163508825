import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
} from "react";
import mergeClasses from "helpers/mergeClasses";
import maxSize from "popper-max-size-modifier";
import { useClickAway } from "react-use";
import { CSSTransition } from "react-transition-group";
import cx from "classnames";
import { usePopper } from "react-popper";
import styles from "./Popup.module.scss";

const MAX_HEIGHT_OFFSET = 16;

const Popup = forwardRef(
  (
    {
      opened = false,
      placement = "bottom-start",
      fullWidth = false,
      limitHeight = false,
      unmountOnClose = false,
      classes = {},
      onClose = () => {},
      anchorEl,
      children,
      className,
      maxHeight,
      wrapperEl,
    },
    ref
  ) => {
    const unmountTimeout = useRef();
    const rootRef = useRef(null);
    const childrenRef = useRef(null);
    const classNames = useMemo(() => mergeClasses(styles, classes), [classes]);

    useImperativeHandle(ref, () => rootRef.current);

    useClickAway(rootRef, (e) => {
      if (!opened || anchorEl?.contains(e.target)) return;
      onClose();
    });

    useEffect(() => () => {
      unmountTimeout.current = undefined;
      clearTimeout(unmountTimeout.current);
    });

    const applyMaxSize = useMemo(() => {
      if (!limitHeight || !!wrapperEl) return [];
      return [
        maxSize,
        {
          name: "applyMaxSize",
          enabled: true,
          phase: "beforeWrite",
          requires: ["maxSize"],
          fn({ state }) {
            const { height } = state.modifiersData.maxSize;
            state.styles.popper.maxHeight = `${height - MAX_HEIGHT_OFFSET}px`;
          },
        },
      ];
    }, [limitHeight, wrapperEl]);

    const { styles: popperStyles, attributes, state } = usePopper(
      anchorEl,
      rootRef.current,
      {
        placement,
        modifiers: [
          {
            name: "flip",
            options: {
              allowedAutoPlacements: ["top", "bottom"],
            },
            enabled: !(limitHeight && wrapperEl),
          },
          ...applyMaxSize,
        ],
      }
    );

    const maxHeightValue = useMemo(() => {
      if (maxHeight) return `${maxHeight}px`;
      if (!wrapperEl || !limitHeight || !anchorEl)
        return popperStyles.popper.maxHeight;
      const anchorRect = anchorEl.getBoundingClientRect();
      const wrapperRect = wrapperEl.getBoundingClientRect();
      const distanceToTop = anchorRect.top - wrapperRect.top;
      const distanceToBottom =
        wrapperRect.height - (distanceToTop + anchorRect.height);
      const result =
        (state?.placement.includes("top") ? distanceToTop : distanceToBottom) -
        MAX_HEIGHT_OFFSET;
      return `${result}px`;
    }, [
      maxHeight,
      wrapperEl,
      limitHeight,
      anchorEl,
      popperStyles.popper.maxHeight,
      state?.placement,
    ]);

    return (
      <>
        <div
          className={cx(classNames.backdrop, {
            [classNames.backdropOpened]: opened,
          })}
        />
        <div
          className={cx(
            classNames.root,
            {
              [classNames.opened]: opened,
              [classNames.top]: opened && state?.placement.includes("top"),
              [classNames.bottom]:
                opened && state?.placement.includes("bottom"),
              [classNames.fullWidth]: fullWidth,
            },
            className
          )}
          ref={rootRef}
          style={{
            "--transform": popperStyles.popper.transform,
            "--inset": popperStyles.popper.inset,
            "--right": popperStyles.popper.right,
            "--top": popperStyles.popper.top,
            "--bottom": popperStyles.popper.bottom,
            "--left": popperStyles.popper.left,
            "--max-height": maxHeightValue,
          }}
          {...attributes.popper}
        >
          <CSSTransition
            nodeRef={childrenRef}
            in={opened}
            timeout={{
              exit: 0.4 * 2 * 1000,
            }}
            unmountOnExit={unmountOnClose}
            classNames={{
              exit: styles.exit,
              enterDone: styles.enterDone,
            }}
          >
            <div ref={childrenRef}>
              <div className={classNames.content}>{children}</div>
            </div>
          </CSSTransition>
        </div>
      </>
    );
  }
);

Popup.displayName = "Popup";

export default Popup;
