import React, { useEffect, useState } from "react";
import {
  useBaseContext,
  useCurrency,
  useMarketFavorites,
} from "../helpers/hooks";
import {
  fetchLunMarketCurrencies,
  fetchLunMarketFavorites,
} from "../helpers/api";
import LunMarketFavoritesCard from "./LunMarketFavoritesCard";
import { UIGrid, UILoader } from "../components/UIKit";
import { TwoColumnGridPlaceholder } from "../placeholders/Placeholder";
import styles from "./LunMarketFavorites.module.scss";
import EmptyPlaceholder from "../EmptyPlaceholder";

const LunMarketFavorites = () => {
  const { locale } = useBaseContext();
  const currency = useCurrency();
  const [data, setData] = useState([]);
  const { favorites, updateByIds } = useMarketFavorites();
  const [loading, setLoading] = useState(false);
  const [currencyConfig, setCurrencyConfig] = useState();
  const isLoading = (loading && data.length === 0) || !currencyConfig;

  const getMarketCurrencies = async () => {
    try {
      const res = await fetchLunMarketCurrencies();
      setCurrencyConfig(res.data);
    } catch (err) {}
  };

  const fetchFavorites = async () => {
    setLoading(true);
    try {
      const res = await fetchLunMarketFavorites({
        id: favorites,
        lang: locale,
        currency: currency.value,
      });
      setData(res.data);
      updateByIds(res.data.map((e) => e.id));
      setLoading(false);
    } catch (err) {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchFavorites();
  }, [favorites, locale]);

  useEffect(() => {
    getMarketCurrencies();
  }, []);

  return (
    <div className={styles.root}>
      <UILoader show={isLoading} />
      {isLoading && <TwoColumnGridPlaceholder noTitle />}
      <UIGrid container alwaysWrapLg alwaysWrapMd alwaysWrapSm noMobileGallery>
        {currencyConfig &&
          data.map((card) => (
            <UIGrid item size={6} md={12} key={card.id}>
              <LunMarketFavoritesCard
                {...card}
                currencyConfig={currencyConfig}
              />
            </UIGrid>
          ))}
        {data.length === 0 && !loading && (
          <EmptyPlaceholder
            title="market.empty.title"
            subtitle="market.empty.subtitle"
          />
        )}
      </UIGrid>
    </div>
  );
};

export default LunMarketFavorites;
