import React, { useState } from "react";
import { useMobile } from "helpers/hooks";
import GridBlock from "./GridBlock";
import { GridPlaceholder } from "../../placeholders/Placeholder";

const GridBlockExpand = ({
  isLoading,
  title,
  list,
  initialLength,
  chip,
  listItemNode,
}) => {
  const { isMobile } = useMobile();
  const [isExpanded, setIsExpanded] = useState(isMobile);

  const onLinkClick = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setIsExpanded(true);
  };

  return (
    <GridBlock.Wrapper>
      {isLoading ? (
        <GridPlaceholder />
      ) : (
        <>
          <GridBlock.Header
            title={title}
            withChip={!isExpanded && list.length > initialLength}
            chip={{
              ...chip,
              onClick: onLinkClick,
            }}
          />
          <GridBlock.List>
            {list.map((item, index) => (
              <GridBlock.ListItem
                itemNode={listItemNode}
                hidden={!isExpanded && index >= initialLength}
                item={item}
                key={item.id}
              />
            ))}
          </GridBlock.List>
        </>
      )}
    </GridBlock.Wrapper>
  );
};

GridBlockExpand.defaultProps = {
  initialLength: 4,
};

export default GridBlockExpand;
