import { useState, useEffect, useRef } from "react";
import { useMobile } from "helpers/hooks";

const useArrows = ({ scroller, cards, data }) => {
  const isArrow = useRef(false);
  const { isTouch } = useMobile();
  const arrowScrollingValue = useRef(null);

  const [isNext, setIsNext] = useState(isArrow.current);
  const [isPrev, setIsPrev] = useState(false);

  useEffect(() => {
    if (isTouch) return;
    if (scroller.current) {
      setIsNext(
        scroller.current.scrollLeft + scroller.current.offsetWidth <
          scroller.current.scrollWidth
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [scroller, cards, data]);

  const handlePrev = () => {
    const scrollPosition = scroller.current.scrollLeft;
    const card = [
      ...cards.current.slice().reverse(),
      { offsetLeft: 0, offsetWidth: 0 },
    ].find((curr) => curr.offsetLeft + curr.offsetWidth - 12 < scrollPosition);
    arrowScrollingValue.current = card.offsetLeft + card.offsetWidth;
    scroller.current.scrollTo({
      behavior: "smooth",
      left: arrowScrollingValue.current - 12,
    });
  };

  const handleNext = () => {
    const scrollPosition = scroller.current.scrollLeft;
    const card = cards.current.find(
      (curr) => curr.offsetLeft + curr.offsetWidth - 12 > scrollPosition
    );
    if (!card) return;
    arrowScrollingValue.current = card.offsetLeft + card.offsetWidth;
    scroller.current.scrollTo({
      behavior: "smooth",
      left: arrowScrollingValue.current - 12,
    });
  };

  const handleScroll = (evt) => {
    const { scrollLeft, scrollWidth, offsetWidth } = evt.target;
    if (arrowScrollingValue.current !== null) {
      setIsPrev(arrowScrollingValue.current > 0);
      setIsNext(arrowScrollingValue.current + offsetWidth < scrollWidth);
      if (
        arrowScrollingValue.current === scrollLeft ||
        scrollLeft + offsetWidth === scrollWidth
      ) {
        arrowScrollingValue.current = null;
      }
    } else {
      setIsPrev(scrollLeft > 0);
      setIsNext(scrollLeft + offsetWidth < scrollWidth);
    }
  };

  return {
    isPrev,
    isNext,
    handleScroll,
    handlePrev,
    handleNext,
  };
};

export default useArrows;
