import React, {
  createContext,
  useRef,
  useEffect,
  useReducer,
  useState,
} from "react";
import { useLocation } from "react-router-dom";
import { usePrevious } from "react-use";
import { forceCheck } from "react-lazyload";
import MessageList from "../../components/Message/MessageList";
import { regionReducer, initialRegions } from "./region.reducer";
import * as pureRegionActions from "./region.actions";
import { composeDispatch } from "../utils";
import * as CurrencyService from "../../services/currency";

const BaseContext = createContext({});

const BaseContextProvider = ({ children, locale, routes }) => {
  const location = useLocation();
  const prevPath = usePrevious(location.pathname);
  const msgRef = useRef();
  const [region, dispatchRegion] = useReducer(regionReducer, initialRegions);
  const regionActions = composeDispatch(pureRegionActions, dispatchRegion);
  const [currency, setCurrency] = useState();

  const initCurrency = () => {
    const value = CurrencyService.get();
    setCurrency(value);
  };

  const handleCurrencyChange = (value) => {
    try {
      CurrencyService.set(value);
      setCurrency(value);
    } catch (error) {}
  };

  const showMessage = (msg) => {
    msgRef.current.showMessage(msg);
  };

  const showMessageStr = (msg) => {
    msgRef.current.showMessageStr(msg);
  };

  useEffect(() => {
    forceCheck();
  }, [location]);

  useEffect(() => {
    regionActions.getRegions();
    initCurrency();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const value = {
    routes,
    locale,
    showMessage,
    showMessageStr,
    prevPath,
    region,
    dispatchRegion,
    regionActions,
    currency,
    handleCurrencyChange,
  };

  return (
    <BaseContext.Provider value={value}>
      {children}
      <MessageList ref={msgRef} />
    </BaseContext.Provider>
  );
};

export { BaseContext, BaseContextProvider };
