import { useCallback, useMemo, useState } from "react";

const useForm = (initialValue) => {
  const [state, setState] = useState(initialValue || {});
  const [inputs, setInputs] = useState([]);
  const valid = useMemo(() => {
    return inputs.reduce(
      (prev, input) => prev && !input.getValidationValueMissing(),
      true
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state, inputs]);

  const handleChangeInput = (name, value) => {
    setState({
      ...state,
      [name]: value,
    });
  };

  const clear = () => {
    setState(initialValue || {});
  };

  const onInputRef = useCallback((node) => {
    if (node) {
      setInputs((prev) => [...prev, node]);
    }
  }, []);

  const validate = () => {
    return inputs.reduce((prevValidation, input) => {
      const validation = input.validate();
      return prevValidation && validation.valid;
    }, true);
  };

  return {
    state,
    handleChangeInput,
    clear,
    onInputRef,
    validate,
    clearInputRefs: () => setInputs([]),
    valid,
  };
};

export default useForm;
