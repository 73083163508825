import { useState, useRef, useEffect } from "react";

const useGridData = (data) => {
  const [items, setItems] = useState(data);
  const itemsRef = useRef(data);

  const setItemsRef = (state) => {
    itemsRef.current = state;
    setItems(state);
  };

  useEffect(() => {
    if (!data) return;
    data.forEach((item) => {
      if (!itemsRef.current) {
        setItemsRef([item]);
      } else if (!itemsRef.current.find((e) => e.id === item.id)) {
        setItemsRef([...itemsRef.current, item]);
      }
    });
  }, [data]);

  return items;
};

export default useGridData;
