import React, { createContext, useState, useEffect, useRef } from "react";

const ABTestingContext = createContext();

// Здесь храним id текущих экспериментов и потом везде вытаскиваем отсюда, не хардкодим
export const EXPERIMENT_ID = {
  question: "XQAOK0Z6R9CwTe8YfJ9VEQ", // example
};

const ABTestingContextProvider = ({ children }) => {
  const [experiments, setExperiment] = useState({
    [EXPERIMENT_ID.question]: null,
  });
  const tick = useRef(0);

  const optimizeFallback = (id) => {
    setExperiment({
      ...experiments,
      [id]: undefined,
    });
  };

  const activateExperiment = (experimentId) => {
    if (window.dataLayer) {
      window.dataLayer.push({ event: "optimize.activate" });
    }
    const initOptimizeIntervalId = setInterval(() => {
      if (window.google_optimize === undefined) {
        if (tick.current < 50) {
          /* 5 sec */
          tick.current += 1;
        } else {
          clearInterval(initOptimizeIntervalId);
          optimizeFallback(experimentId);
        }
      } else {
        clearInterval(initOptimizeIntervalId);
        const intervalId = setInterval(() => {
          try {
            setExperiment({
              ...experiments,
              [experimentId]: window.google_optimize.get(experimentId),
            });
          } catch (e) {
            // eslint-disable-next-line no-console
            console.error(e);
            optimizeFallback(experimentId);
          }
          clearInterval(intervalId);
        }, 100);
      }
    }, 100);
  };

  useEffect(() => {
    const analytics = [];
    Object.keys(experiments).forEach((key) => {
      if (typeof experiments[key] === "string") {
        analytics.push(`${key}: ${experiments[key]}`);
      }
    });
    if (analytics.length === 0) return;
    /* app + web */
    window.gtag("set", "user_properties", { ab_version: analytics.join(";") });
    /* amplitude */
    const identify = new window.amplitude.Identify().set(
      "ab_test",
      analytics.join(";")
    );
    window.amplitude.getInstance().identify(identify);
    /* amplitude end */
  }, [experiments]);

  const value = {
    experiments,
    activateExperiment,
  };
  return (
    <ABTestingContext.Provider value={value}>
      {children}
    </ABTestingContext.Provider>
  );
};

export { ABTestingContext, ABTestingContextProvider };
