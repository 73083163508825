import moment from "moment";
import { useIntl } from "react-intl";

export const useDate = (value) => {
  const momentValue = moment(value);
  const now = moment();
  const intl = useIntl();

  if (!momentValue.isValid()) return null;

  const diff = momentValue.diff(now, "days");

  if (diff === 0) {
    return `${intl.formatMessage({ id: "market.today" })} ${momentValue.format(
      "HH:mm"
    )}`;
  }
  if (diff === 1) {
    return `${intl.formatMessage({
      id: "market.yesterday",
    })} ${momentValue.format("HH:mm")}`;
  }

  return momentValue.format("DD MMMM");
};
