import { AppointmentCancelType } from "../constants/appointment-cancel";

const getEvent = (action, label, category = window.gaEventCategory) => ({
  category,
  action,
  label,
});

export const gaEventActions = {
  favorites_menu: {
    name: "navigation_menu",
    get chip_click() {
      return getEvent(this.name, "chip_click");
    },
  },
  market: {
    name: "market",
    get goto_realty() {
      return getEvent(this.name, "goto_realty");
    },
    get prev_slide_click() {
      return getEvent(this.name, "prev_slide_click");
    },
    get next_slide_click() {
      return getEvent(this.name, "next_slide_click");
    },
  },
  navigation_menu: {
    name: "navigation_menu",
    get goto_home() {
      return getEvent(this.name, "goto_home");
    },
    get goto_away() {
      return getEvent(this.name, "goto_away");
    },
    get close() {
      return getEvent(this.name, "close");
    },
    get faq() {
      return getEvent(this.name, "faq");
    },
    get vacancies() {
      return getEvent(this.name, "vacancies");
    },
    get telegram_support() {
      return getEvent(this.name, "telegram_support");
    },
    get for_developers_placement() {
      return getEvent(this.name, "for_developers_placement");
    },
    get for_rieltors() {
      return getEvent(this.name, "for_rieltors");
    },
    get for_owners() {
      return getEvent(this.name, "for_owners");
    },
    get goto_lunmisto() {
      return getEvent(this.name, "goto_lunmisto");
    },
    get goto_korter() {
      return getEvent(this.name, "goto_korter");
    },
    get goto_bird() {
      return getEvent(this.name, "goto_bird");
    },
    get goto_rieltor() {
      return getEvent(this.name, "goto_rieltor");
    },
    get goto_red() {
      return getEvent(this.name, "goto_red");
    },
    get goto_dobovo() {
      return getEvent(this.name, "goto_dobovo");
    },
  },
  navigation_top: {
    name: "navigation_top",
    get goto_home() {
      return getEvent(this.name, "goto_home");
    },
    get goto_catalog() {
      return getEvent(this.name, "goto_catalog");
    },
    get goto_map() {
      return getEvent(this.name, "goto_map");
    },
    get goto_developers() {
      return getEvent(this.name, "goto_developers");
    },
    get goto_actions() {
      return getEvent(this.name, "goto_actions");
    },
    get open_search_click() {
      return getEvent(this.name, "open_search_click");
    },
    get goto_my_favorites() {
      return getEvent(this.name, "goto_my_favorites");
    },
    get goto_flat_rent() {
      return getEvent(this.name, "goto_flat_rent");
    },
    get goto_flat_sale() {
      return getEvent(this.name, "goto_flat_sale");
    },
    get open_sidebar() {
      return getEvent(this.name, "open_sidebar");
    },
  },
  navigation_top_mylun: {
    name: "navigation_top_mylun",
    get login_click() {
      return getEvent(this.name, "login_click");
    },
    get logout_click() {
      return getEvent(this.name, "logout_click");
    },
  },
  favorite_projects_list: {
    name: "favorite_projects_list",
    get goto_view_project_render() {
      return getEvent(this.name, "goto_view_project_render");
    },
    get goto_view_project_button() {
      return getEvent(this.name, "goto_view_project_button");
    },
    get goto_project_site() {
      return getEvent(this.name, "goto_project_site");
    },
    get favorites_add_click() {
      return getEvent(this.name, "favorites_add_click");
    },
    get favorites_remove_click() {
      return getEvent(this.name, "favorites_remove_click");
    },
    get comparison_add_click() {
      return getEvent(this.name, "comparison_add_click");
    },
    get comparison_remove_click() {
      return getEvent(this.name, "comparison_remove_click");
    },
    get goto_my_comparison() {
      return getEvent(this.name, "goto_my_comparison");
    },
    get next_photo_click() {
      return getEvent(this.name, "next_photo_click");
    },
    get prev_photo_click() {
      return getEvent(this.name, "prev_photo_click");
    },
    get call_show_click() {
      return getEvent(this.name, "call_show_click");
    },
    get call_try_click() {
      return getEvent(this.name, "call_try_click");
    },
    get goto_developer_question_form() {
      return getEvent(this.name, "goto_developer_question_form");
    },
    get goto_visit_form() {
      return getEvent(this.name, "goto_visit_form");
    },
    get show_more_click() {
      return getEvent(this.name, "show_more_click");
    },
  },
  popular_projects_list: {
    name: "popular_projects_list",
    get goto_catalog() {
      return getEvent(this.name, "goto_catalog");
    },
    get goto_view_project() {
      return getEvent(this.name, "goto_view_project");
    },
    get favorites_add_click() {
      return getEvent(this.name, "favorites_add_click");
    },
    get favorites_remove_click() {
      return getEvent(this.name, "favorites_remove_click");
    },
  },
  new_projects_list: {
    name: "new_projects_list",
    get goto_catalog() {
      return getEvent(this.name, "goto_catalog");
    },
    get goto_view_project() {
      return getEvent(this.name, "goto_view_project");
    },
    get favorites_add_click() {
      return getEvent(this.name, "favorites_add_click");
    },
    get favorites_remove_click() {
      return getEvent(this.name, "favorites_remove_click");
    },
  },
  subscription: {
    name: "subscription",
    get email_input_click() {
      return getEvent(this.name, "email_input_click");
    },
    get subscribe_email_click() {
      return getEvent(this.name, "subscribe_email_click");
    },
    get subscribe_telegram_click() {
      return getEvent(this.name, "subscribe_telegram_click");
    },
    get email_success() {
      return getEvent(this.name, "email_success");
    },
    get email_error() {
      return getEvent(this.name, "email_error");
    },
  },
  [AppointmentCancelType.AlreadyCanceled]: {
    name: AppointmentCancelType.AlreadyCanceled,
    get goto_visit_form() {
      return getEvent(this.name, "goto_visit_form");
    },
  },
  [AppointmentCancelType.CancelConfirmation]: {
    name: AppointmentCancelType.CancelConfirmation,
    get submit_click() {
      return getEvent(this.name, "submit_click");
    },
  },
  [AppointmentCancelType.SuccessCanceled]: {
    name: AppointmentCancelType.CancelConfirmation,
    get goto_visit_form() {
      return getEvent(this.name, "goto_visit_form");
    },
  },
  [AppointmentCancelType.VisitTimeInPast]: {
    name: AppointmentCancelType.CancelConfirmation,
    get goto_visit_form() {
      return getEvent(this.name, "goto_visit_form");
    },
  },
  cancel_request: {
    name: "cancel_request",
    get success() {
      return getEvent(this.name, "success");
    },
    get error() {
      return getEvent(this.name, "server_error");
    },
  },
  footer: {
    name: "footer",

    get goto_main_logo() {
      return getEvent(this.name, "goto_main_logo");
    },
    get currency_click() {
      return getEvent(this.name, "currency_click");
    },
    get goto_away() {
      return getEvent(this.name, "goto_away");
    },
    get language_change_click() {
      return getEvent(this.name, "language_change_click");
    },
    get goto_faq() {
      return getEvent(this.name, "goto_faq");
    },
    get goto_careers() {
      return getEvent(this.name, "goto_careers");
    },
    get goto_facebook() {
      return getEvent(this.name, "goto_facebook");
    },
    get goto_youtube() {
      return getEvent(this.name, "goto_youtube");
    },
    get goto_instagram() {
      return getEvent(this.name, "goto_instagram");
    },
    get goto_twitter() {
      return getEvent(this.name, "goto_twitter");
    },
    get goto_terms() {
      return getEvent(this.name, "goto_terms");
    },
    get contacts_for_mass_media() {
      return getEvent(this.name, "contacts_for_mass_media");
    },
    get telegram_support() {
      return getEvent(this.name, "telegram_support");
    },
    get for_developers_placement() {
      return getEvent(this.name, "for_developers_placement");
    },
    get for_rieltors() {
      return getEvent(this.name, "for_rieltors");
    },
    get for_owners() {
      return getEvent(this.name, "for_owners");
    },
    get goto_lunmisto() {
      return getEvent(this.name, "goto_lunmisto");
    },
    get goto_korter() {
      return getEvent(this.name, "goto_korter");
    },
    get goto_bird() {
      return getEvent(this.name, "goto_bird");
    },
    get goto_rieltor() {
      return getEvent(this.name, "goto_rieltor");
    },
    get goto_red() {
      return getEvent(this.name, "goto_red");
    },
    get goto_dobovo() {
      return getEvent(this.name, "goto_dobovo");
    },
  },
  email_communication: {
    name: "email_communication",

    get message_input_click() {
      return getEvent(this.name, "message_input_click");
    },
    get email_input_click() {
      return getEvent(this.name, "email_input_click");
    },
    get attach_photo_click() {
      return getEvent(this.name, "attach_photo_click");
    },
    get submit_click() {
      return getEvent(this.name, "submit_click");
    },
    get close_click() {
      return getEvent(this.name, "close_click");
    },
  },
  navigation_mylun: {
    name: "navigation_mylun",

    get goto_my_favorites() {
      return getEvent(this.name, "goto_my_favorites");
    },
    get goto_my_comparison() {
      return getEvent(this.name, "goto_my_comparison");
    },
    get goto_my_layouts() {
      return getEvent(this.name, "goto_my_layouts");
    },
    get goto_my_updates() {
      return getEvent(this.name, "goto_my_updates");
    },
    get goto_my_requests() {
      return getEvent(this.name, "goto_my_requests");
    },
    get goto_my_profile() {
      return getEvent(this.name, "goto_my_profile");
    },
    get login_click() {
      return getEvent(this.name, "login_click");
    },
  },
  comparison_projects_list: {
    name: "comparison_projects_list",

    get parameters_click() {
      return getEvent(this.name, "parameters_click");
    },
    get parameters_chip_click() {
      return getEvent(this.name, "parameters_chip_click");
    },
    get share_click() {
      return getEvent(this.name, "share_click");
    },
    get search_comparison_click() {
      return getEvent(this.name, "search_comparison_click");
    },
    get search_input_click() {
      return getEvent(this.name, "search_input_click");
    },
    get comparison_add_checkbox_click() {
      return getEvent(this.name, "comparison_add_checkbox_click");
    },
    get comparison_remove_checkbox_click() {
      return getEvent(this.name, "comparison_remove_checkbox_click");
    },
    get comparison_remove_cross_click() {
      return getEvent(this.name, "comparison_remove_cross_click");
    },
    get comparison_submit_click() {
      return getEvent(this.name, "comparison_submit_click");
    },
    get goto_view_project() {
      return getEvent(this.name, "goto_view_project");
    },
    get favorites_add_click() {
      return getEvent(this.name, "favorites_add_click");
    },
    get favorites_remove_click() {
      return getEvent(this.name, "favorites_remove_click");
    },
    get open_contacts_click() {
      return getEvent(this.name, "open_contacts_click");
    },
    get call_show_click() {
      return getEvent(this.name, "call_show_click");
    },
    get call_try_click() {
      return getEvent(this.name, "call_try_click");
    },
    get goto_developer_question_form() {
      return getEvent(this.name, "goto_developer_question_form");
    },
    get goto_visit_form() {
      return getEvent(this.name, "goto_visit_form");
    },
    get comparison_remove_click() {
      return getEvent(this.name, "comparison_remove_click");
    },
    get next_project_click() {
      return getEvent(this.name, "next_project_click");
    },
    get prev_project_click() {
      return getEvent(this.name, "prev_project_click");
    },
  },
  last_seen_projects_list: {
    name: "last_seen_projects_list",

    get show_all_click() {
      return getEvent(this.name, "show_all_click");
    },
    get goto_view_project() {
      return getEvent(this.name, "goto_view_project");
    },
    get favorites_add_click() {
      return getEvent(this.name, "favorites_add_click");
    },
    get favorites_remove_click() {
      return getEvent(this.name, "favorites_remove_click");
    },
  },
  favorite_layouts_list: {
    name: "favorite_layouts_list",
    get goto_view_layout() {
      return getEvent(this.name, "goto_view_layout");
    },
    get goto_view_project() {
      return getEvent(this.name, "goto_view_project");
    },
    get favorites_add_click() {
      return getEvent(this.name, "favorites_add_click");
    },
    get favorites_remove_click() {
      return getEvent(this.name, "favorites_remove_click");
    },
  },
  last_seen_layouts_list: {
    name: "last_seen_layouts_list",
    get show_all_click() {
      return getEvent(this.name, "show_all_click");
    },
    get goto_view_layout() {
      return getEvent(this.name, "goto_view_layout");
    },
    get favorites_add_click() {
      return getEvent(this.name, "favorites_add_click");
    },
    get favorites_remove_click() {
      return getEvent(this.name, "favorites_remove_click");
    },
  },
  pagination: {
    name: "pagination",
    get page_click() {
      return getEvent(this.name, "page_click");
    },
    get next_click() {
      return getEvent(this.name, "next_click");
    },
    get prev_click() {
      return getEvent(this.name, "prev_click");
    },
  },
  requests_list: {
    name: "requests_list",
    get action_click() {
      return getEvent(this.name, "action_click");
    },
    get actions_open_click() {
      return getEvent(this.name, "actions_open_click");
    },
  },
  profile_summary: {
    name: "profile_summary",
    get change_photo_click() {
      return getEvent(this.name, "change_photo_click");
    },
    get goto_my_favorites() {
      return getEvent(this.name, "goto_my_favorites");
    },
    get goto_my_comparison() {
      return getEvent(this.name, "goto_my_comparison");
    },
    get goto_my_layouts() {
      return getEvent(this.name, "goto_my_layouts");
    },
    get my_cities_click_test() {
      return getEvent(this.name, "my_cities_click_test");
    },
  },
  profile_summary_photo: {
    name: "profile_summary_photo",
    get photo_click() {
      return getEvent(this.name, "photo_click");
    },
    get submit_click() {
      return getEvent(this.name, "submit_click");
    },
  },
  profile_details: {
    name: "profile_details",
    get name_input_click() {
      return getEvent(this.name, "name_input_click");
    },
    get phone_input_click() {
      return getEvent(this.name, "phone_input_click");
    },
    get email_input_click() {
      return getEvent(this.name, "email_input_click");
    },
  },
  profile_settings: {
    name: "profile_settings",
    get currency_change_click() {
      return getEvent(this.name, "currency_change_click");
    },
    get language_change_click() {
      return getEvent(this.name, "language_change_click");
    },
    get city_change_click() {
      return getEvent(this.name, "city_change_click");
    },
    get channel_change_click() {
      return getEvent(this.name, "channel_change_click");
    },
  },
  profile_settings_currency: {
    name: "profile_settings_currency",
    get option_click() {
      return getEvent(this.name, "option_click");
    },
  },
  profile_settings_language: {
    name: "profile_settings_language",
    get option_click() {
      return getEvent(this.name, "option_click");
    },
  },
  profile_settings_city: {
    name: "profile_settings_city",
    get option_click() {
      return getEvent(this.name, "option_click");
    },
  },
  profile_settings_channel: {
    name: "profile_settings_channel",
    get option_click() {
      return getEvent(this.name, "option_click");
    },
  },
  profile_save: {
    name: "profile_save",
    get save_click() {
      return getEvent(this.name, "save_click");
    },
  },
  news_list: {
    name: "news_list",
    get news_item_click() {
      return getEvent(this.name, "news_item_click");
    },
    get go_to_catalog() {
      return getEvent(this.name, "go_to_catalog");
    },
  },
  news_filters: {
    name: "news_filters",
    get filter_click() {
      return getEvent(this.name, "filter_click");
    },
    get show_all() {
      return getEvent(this.name, "show_all");
    },
  },
  support: {
    name: "support",
    get click() {
      return getEvent(this.name, "click");
    },
  },
  navigation_bottom: {
    name: "navigation_bottom",
    get goto_home() {
      return getEvent(this.name, "goto_home");
    },
    get goto_catalog() {
      return getEvent(this.name, "goto_catalog");
    },
    get goto_map() {
      return getEvent(this.name, "goto_map");
    },
    get goto_developers() {
      return getEvent(this.name, "goto_developers");
    },
    get goto_actions() {
      return getEvent(this.name, "goto_actions");
    },
    get open_auth_click() {
      return getEvent(this.name, "open_auth_click");
    },
    get goto_flat_sale() {
      return getEvent(this.name, "goto_flat_sale");
    },
    get goto_flat_rent() {
      return getEvent(this.name, "goto_flat_rent");
    },
  },
  appointment_feedback: {
    name: "appointment_feedback_form",
    mark(markId) {
      return getEvent(this.name, `mark_${markId}_click`);
    },
    get message_type() {
      return getEvent(this.name, "message_type");
    },
    get submit_click() {
      return getEvent(this.name, "submit_click");
    },
    get form_success() {
      return getEvent(this.name, "form_success");
    },
    get form_error() {
      return getEvent(this.name, "form_error");
    },
  },
  unsubscribe: {
    subscribe_click: "subscribe_click",
    submit_click: "submit_click",
    comment_type: "comment_type",
    goto_catalog: "goto_catalog",
    success: "success",
    error: "server_error",
  },
  auth: {
    email_verification: {
      close_click: "close_click",
    },
    phone_verification: {
      error: "server_error",
      code_type: "code_type",
      submit_click: "submit_click",
    },
    login_type: {
      phone_email_type: "phone/email_type",
      submit_click: "submit_click",
      facebook_click: "facebook_click",
      google_click: "google_click",
      telegram_click: "telegram_click",
      error: "server_error",
    },
    google_type: {
      error: "server_error",
    },
    telegram_type: {
      error: "server_error",
    },
    one_tap_type: {
      error: "server_error",
    },
    facebook_type: {
      error: "server_error",
    },
    success_type: {
      telegram_success: "telegram_success",
      one_tap_success: "one_tap_success",
      google_success: "google_success",
      facebook_success: "facebook_success",
      phone_success: "phone_success",
    },
  },
};

gaEventActions.auth = Object.entries(gaEventActions.auth).reduce(
  (prev, [actionName, labelsObject]) => {
    return {
      ...prev,
      [actionName]: Object.entries(labelsObject).reduce(
        (prevLabel, [labelKey, labelValue]) => {
          return {
            ...prevLabel,
            [labelKey]: {
              category: "auth",
              action: actionName,
              label: labelValue,
            },
          };
        },
        {}
      ),
    };
  },
  {}
);

gaEventActions.unsubscribe = [
  "general",
  "company",
  "building",
  "region",
].reduce((prev, unsubscribeType) => {
  return {
    ...prev,
    [unsubscribeType]: {
      ...Object.entries(gaEventActions.unsubscribe).reduce(
        (prev, [labelKey, labelValue]) => ({
          ...prev,
          [labelKey]: getEvent(unsubscribeType, labelValue),
        }),
        {}
      ),
      reason_click: (reasonName) =>
        getEvent(unsubscribeType, `reason_${reasonName}_click`),
    },
  };
}, {});
