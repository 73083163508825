import React from "react";
import cn from "classnames";
import { UIMainTitle } from "../UIKit/MainTitle";

import style from "./GridBlock.module.scss";
import { HomeCardGrid } from "../../Home/HomeCard/HomeCard";
import { useIntl } from "react-intl";

const Wrapper = ({ children }) => (
  <div className={style.wrapper}>{children}</div>
);

const Header = ({ title, chip, withChip }) => {
  const intl = useIntl();
  return (
    <div className={style.header}>
      <UIMainTitle
        title={{
          content: intl.formatMessage({ id: title.id }, title.values),
          size: title.size,
        }}
        withChip={withChip}
        chip={chip}
      />
    </div>
  );
};

const List = ({ children }) => <HomeCardGrid>{children}</HomeCardGrid>;

const ListItem = ({ itemNode: Item, hidden, item, children }) => {
  return (
    <div className={cn(style.listItem, { [style.hidden]: hidden })}>
      {item ? <Item {...item} /> : children}
    </div>
  );
};

Header.defaultProps = {
  title: {
    values: {},
  },
};

export default {
  Wrapper,
  Header,
  List,
  ListItem,
};
