import React from "react";
import cn from "classnames";
import { attachGaDataAttributes } from "helpers/analytics";
import { ReactComponent as InfoIcon } from "icons/alert_info.svg";
import { ReactComponent as WarningIcon } from "icons/alert_warning.svg";
import "./Alert.scss";
import { useIntl } from "react-intl";

const ALERT_TYPES = {
  WARNING: "warning",
  INFO: "info",
};

const UIAlert = ({
  withHover,
  className,
  href,
  event,
  type = ALERT_TYPES.WARNING,
  title,
  content,
}) => {
  const intl = useIntl();
  const TagName = href ? "a" : "div";
  let attributes = {};
  if (href) {
    attributes.href = href;
  }
  if (event) {
    attributes = {
      ...attributes,
      ...attachGaDataAttributes(event),
    };
  }
  return (
    <TagName
      className={cn(
        "UIAlert",
        {
          "-with-hover": withHover,
        },
        className
      )}
      {...attributes}
    >
      <div
        className={cn("UIAlert-container", {
          "-warning": type === ALERT_TYPES.WARNING,
          "-info": type === ALERT_TYPES.INFO,
        })}
      />
      <div className="UIAlert-icon">
        {type === ALERT_TYPES.WARNING && <WarningIcon />}
        {type === ALERT_TYPES.INFO && <InfoIcon />}
      </div>
      <div className="UIAlert-title">
        {title || intl.formatMessage({ id: "alert.defaultMessage" })}
      </div>
      {content && (
        <div class="UIAlert-content">
          <div class="UIAlert-content-item">{content}</div>
        </div>
      )}
    </TagName>
  );
};

export default UIAlert;
