import React, { useEffect } from "react";
import { useUserContext } from "helpers/hooks";
import styles from "./Authorization.module.scss";
import cn from "classnames";
import Authorization from "./index";
import { UIButton } from "components/UIKit/Button";
import { UITypography } from "components/UIKit/Typography";
import { ReactComponent as CloseIcon } from "icons/close.svg";
import { RowPlaceholder, CardPlaceholder } from "placeholders/Placeholder";
import { FormattedMessage } from "react-intl";

const AuthorizationPage = () => {
  const user = useUserContext();

  const sendClosePopupMessage = () => {
    window.parent.postMessage("closePopup", "*");
  };

  const sendAuthDoneMessage = () => {
    window.parent.postMessage("authDone", "*");
  };

  useEffect(() => {
    if (!user.state.id && !user.state.isGuest) return; // Еще нет данных о пользователе
    if (user.state.isGuest) return; // Прогружаем форму
    sendAuthDoneMessage(); // Отправляем ивент в каталог, что пользователь залогинен
  }, [user.state.isGuest, user.state.id]);

  if (!user.state.id && !user.state.isGuest) {
    return (
      <div className={cn(styles.authPage)}>
        <div className={styles.root}>
          <RowPlaceholder />
          <RowPlaceholder />
          <CardPlaceholder />
        </div>
      </div>
    );
  }

  if (!user.state.isGuest) {
    return (
      <div className={styles.authPage}>
        <div className={styles.root}>
          <UITypography variant="h4" className={styles.authPageSuccess}>
            <FormattedMessage id="login.popup.success" />
          </UITypography>
        </div>
      </div>
    );
  }

  return (
    <div className={styles.authPage}>
      <div className={cn(styles.root, styles.authPageHeader)}>
        <UITypography
          variant="h4"
          component="div"
          className={styles.authPageTitle}
        >
          <FormattedMessage id="login.popup.title" />
        </UITypography>
        <UITypography component="div">
          <FormattedMessage id="login.popup.subtitle" />
        </UITypography>
        <UIButton
          className={styles.authPageClose}
          appearance="ghost-dark"
          size="small"
          icon={<CloseIcon />}
          event={{
            category: "mylun_login",
            action: "catalog_popup",
            label: "close",
          }}
          onClick={sendClosePopupMessage}
        />
      </div>
      <Authorization />
    </div>
  );
};

export default AuthorizationPage;
