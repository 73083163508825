import React, { useMemo, useState } from "react";
import cn from "classnames";
import qs from "qs";
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Lazy } from "swiper";
import { useMobile, useSwiper, usePhone, useUsdCurrency } from "helpers/hooks";
import { FormattedMessage } from "react-intl";
import { attachGaDataAttributes } from "helpers/analytics";
import { useRouteMatch } from "react-router";
import { UITypography } from "components/UIKit/Typography";
import { UIButton } from "components/UIKit/Button";
import UIAlert from "components/UIKit/Alert";
import { UINavigationArrow } from "components/UIKit/NavigationArrow";
import { DeveloperQuestionPopup } from "Messages/DeveloperQuestion";
import { Image, ImagePlaceholder } from "components/Image/Image";
import * as developerEmojiSrc from "icons/developer-emoji.png";
import * as clipboardEmojiSrc from "icons/clipboard-emoji.png";
import * as constructionEmojiSrc from "icons/construction-emoji.png";
import { ReactComponent as PhoneIcon } from "icons/phone.svg";
import { ReactComponent as CalendarIcon } from "icons/calendar-contact.svg";
import { ReactComponent as LetterIcon } from "icons/email.svg";
import { ReactComponent as WebIcon } from "icons/web.svg";
import { ReactComponent as FlatfyIcon } from "icons/flatfy-logo.svg";
import { BuildingType } from "../../constants/favorites";
import FavoriteBuildingsCardActions from "./FavoriteBuildingsCardActions";

import "swiper/swiper.scss";
import styles from "./FavoriteBuildingsCard.module.scss";
import { gaEventActions as gaActions } from "../../analytics";
import { useGaCategory } from "../../analytics/useCategory";
import { getImage, ImageSize } from "helpers/getImageUrl";

SwiperCore.use([Lazy]);

const getDocumentsLabel = (building) => {
  if (!building.documents_available) {
    return <FormattedMessage id="building.documentsUndefined" />;
  }
  if (building.documents_available === building.documents_available_max) {
    return <FormattedMessage id="building.documentsAll" />;
  }
  return (
    <FormattedMessage
      id="building.documents"
      values={{
        count: building.documents_available,
        max: building.documents_available_max,
      }}
    />
  );
};

const getPriceLabel = (building, isUsdCurrency) => {
  if (building.secondary_market) {
    return <FormattedMessage id="allSold" />;
  }
  const undefinedPriceMessage = (
    <FormattedMessage id="building.priceUndefined" />
  );
  if (building.type === BuildingType.COTTAGES) {
    if (!building.price.value) return undefinedPriceMessage;
    return isUsdCurrency ? building.price.value_usd : building.price.value;
  }
  const priceSqm = isUsdCurrency ? building.price_sqm_usd : building.price_sqm;
  return priceSqm || undefinedPriceMessage;
};

const FavoriteBuildingsCard = ({ building }) => {
  const isUsdCurrency = useUsdCurrency();
  const { path } = useRouteMatch();
  const { isTouch } = useMobile();
  const { isPrev, isNext, onSwiper, onPrevSlide, onNextSlide } = useSwiper();
  const { getGaEvent: ga, category } = useGaCategory();
  const { phoneValue, onPhoneClick } = usePhone({
    phone: building.phone_number?.phone,
    buildingId: building.id,
    gaAction: gaActions.favorite_projects_list.name,
  });
  const [questionPopupOpened, setQuestionPopupOpened] = useState(false);

  const isSiteEnabled = building.site && building.site.url !== building.url;
  const isDeveloperQuestion =
    !building.is_risky && building.developer_question_url;

  const defaultImageSrc = useMemo(() => {
    if (building.images.length > 0) return null;
    if (!building.small_round_logo)
      return { src: "https://lun.ua/images/default_building.svg" };
    const getImageBySize = getImage(
      building.small_round_logo.image_id,
      building.small_round_logo.image_format
    );

    return {
      src: getImageBySize(ImageSize.Building300),
      srcset: getImageBySize(ImageSize.Building600),
    };
  }, [building]);

  return (
    <div className={styles.root}>
      <div className={styles.content}>
        <div className={styles.images}>
          <a
            href={building.url}
            className={styles.imagesContent}
            {...attachGaDataAttributes(
              ga(gaActions.favorite_projects_list.goto_view_project_render)
            )}
          >
            {building.images.length === 0 && (
              <section>
                {!building.small_round_logo ? (
                  <img {...defaultImageSrc} alt="" />
                ) : (
                  <Image {...defaultImageSrc} entity="buildings" useWebp />
                )}
              </section>
            )}
            {building.images.length > 0 && (
              <>
                <Swiper
                  slidesPerView={1}
                  spaceBetween={8}
                  onSwiper={onSwiper}
                  simulateTouch={false}
                  lazy={{
                    threshold: 50,
                    loadPrevNext: true,
                  }}
                  className={styles.swiper}
                >
                  {building.images.map((image) => (
                    <SwiperSlide tag="section" key={image.small}>
                      <picture className="Card-picture">
                        <source
                          data-srcset={`${image.small}.webp 1x, ${image.large}.webp 2x`}
                          type="image/webp"
                        />
                        <img
                          data-src={image.small}
                          data-srcset={`${image.small} 1x ${image.large} 2x`}
                          className="swiper-lazy"
                          alt=""
                        />
                      </picture>
                      <ImagePlaceholder
                        id={building.id}
                        entity="buildings"
                        className="swiper-lazy-preloader"
                      />
                    </SwiperSlide>
                  ))}
                </Swiper>
                {!isTouch && (
                  <>
                    {isPrev && (
                      <div className={cn(styles.arrow, styles.prev)}>
                        <UINavigationArrow
                          direction="left"
                          onClick={onPrevSlide}
                          event={ga(
                            gaActions.favorite_projects_list.prev_photo_click
                          )}
                        />
                      </div>
                    )}
                    {isNext && (
                      <div className={cn(styles.arrow, styles.next)}>
                        <UINavigationArrow
                          direction="right"
                          onClick={onNextSlide}
                          event={ga(
                            gaActions.favorite_projects_list.next_photo_click
                          )}
                        />
                      </div>
                    )}
                  </>
                )}
              </>
            )}
          </a>
          <FavoriteBuildingsCardActions
            building={building}
            appearance="overlay"
          />
        </div>
        <div className={styles.details}>
          <div className={styles.title}>
            <UITypography
              variant="h3"
              component="div"
              className={styles.buildingName}
            >
              {building.name}
            </UITypography>
            <FavoriteBuildingsCardActions
              building={building}
              appearance="secondary"
            />
          </div>
          <UITypography className={styles.address} component="div">
            {building.address}
          </UITypography>
          <UITypography className={styles.price} component="div">
            {getPriceLabel(building, isUsdCurrency)}
          </UITypography>
          <div className={styles.row}>
            <img src={developerEmojiSrc} alt="" />
            <UITypography className={styles.rowContent}>
              {building.developer?.name || building.other_companies?.names || (
                <FormattedMessage id="building.developerUndefined" />
              )}
            </UITypography>
          </div>
          <div className={styles.row}>
            <img src={constructionEmojiSrc} alt="" />
            <UITypography className={styles.rowContent}>
              {building.construction_status}
            </UITypography>
          </div>
          {building.type !== BuildingType.COTTAGES &&
            building.are_documents_available_in_region && (
              <div className={styles.row}>
                <img src={clipboardEmojiSrc} alt="" />
                <UITypography className={styles.rowContent}>
                  {getDocumentsLabel(building)}
                </UITypography>
              </div>
            )}
        </div>
        <div className={styles.actions}>
          <div
            className={cn(styles.action, {
              [styles.actionHalf]: !isDeveloperQuestion,
            })}
          >
            <UIButton
              event={ga(
                gaActions.favorite_projects_list.goto_view_project_button
              )}
              fullWidth
              icon={<WebIcon />}
              classes={{ content: styles.actionContent }}
              attributes={{
                href: `${building.site.url}${
                  isSiteEnabled ? "&" : "?"
                }${qs.stringify({
                  event_category: category,
                })}`,
              }}
            >
              {isSiteEnabled ? (
                building.site.name
              ) : (
                <FormattedMessage id="actions.url" />
              )}
            </UIButton>
          </div>
          <div
            className={cn(styles.action, {
              [styles.actionHalf]: !isDeveloperQuestion,
            })}
          >
            <UIButton
              event={ga(gaActions.favorite_projects_list.call_try_click)}
              fullWidth
              icon={<PhoneIcon />}
              classes={{ content: styles.actionContent }}
              disabled={!building.phone_number?.phone}
              onClick={onPhoneClick}
            >
              {building.phone_number?.phone ? (
                phoneValue
              ) : (
                <FormattedMessage id="building.phoneUndefinded" />
              )}
            </UIButton>
          </div>

          {!building.is_risky && (
            <>
              {isDeveloperQuestion && (
                <div className={styles.action}>
                  <UIButton
                    event={ga(
                      gaActions.favorite_projects_list
                        .goto_developer_question_form
                    )}
                    fullWidth
                    icon={<LetterIcon />}
                    classes={{ content: styles.actionContent }}
                    onClick={() => setQuestionPopupOpened(true)}
                  >
                    <FormattedMessage id="actions.write" />
                  </UIButton>
                </div>
              )}
              {building.appointments_url && (
                <div className={cn(styles.action, styles.primaryAction)}>
                  <UIButton
                    appearance="primary"
                    event={ga(gaActions.favorite_projects_list.goto_visit_form)}
                    fullWidth
                    icon={<CalendarIcon />}
                    classes={{ content: styles.actionContent }}
                    attributes={{
                      href: `${path}appointment/${building.id}?${qs.stringify({
                        eventCategory: category,
                        eventAction: gaActions.favorite_projects_list.name,
                      })}`,
                    }}
                  >
                    <FormattedMessage id="actions.appointments" />
                  </UIButton>
                </div>
              )}
            </>
          )}
        </div>
        {building.is_risky && <UIAlert className={styles.alert} />}
        {building.developer_question_url && !building.secondary_market && (
          <DeveloperQuestionPopup
            id={building.id}
            opened={questionPopupOpened}
            setOpened={setQuestionPopupOpened}
          />
        )}
        {building.secondary_market && (
          <a
            href={building.secondary_market}
            className={cn(styles.action, styles.secondaryMarket)}
          >
            <div className={styles.secondaryMarketContent}>
              <FormattedMessage id="secondaryMarket.subtitle" />
              <div className={styles.secondaryMarketTitle}>
                <FormattedMessage id="secondaryMarket.title" />
              </div>
            </div>
            <FlatfyIcon />
          </a>
        )}
      </div>
    </div>
  );
};

export default FavoriteBuildingsCard;
