import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import cn from "classnames";
import { useDeveloperFormPopup, useUrlParams } from "helpers/hooks";
import { UIModal } from "components/UIKit/Modal";
import DeveloperQuestion from "./DeveloperQuestion";
import styles from "./DeveloperQuestion.module.scss";

export default () => {
  const { id } = useParams();
  const { urlParams } = useUrlParams();
  const {
    onlyForm,
    sendBackPopupMessage,
    getParentOrigin,
  } = useDeveloperFormPopup();

  return (
    <div className={cn({ [styles.onlyForm]: onlyForm })}>
      <DeveloperQuestion
        id={id}
        onClose={onlyForm ? sendBackPopupMessage : null}
        token={urlParams.token}
        page={onlyForm ? getParentOrigin() : null}
      />
    </div>
  );
};

export const DeveloperQuestionPopup = ({
  id,
  opened,
  setOpened,
  modalEvent,
}) => {
  const isModalRendered = id && opened;

  useEffect(() => {
    if (!isModalRendered) {
      document.body.classList.remove("UIModal-no-scroll");
    }
  }, [isModalRendered]);

  if (!isModalRendered) return null;
  return (
    <UIModal
      analytics={modalEvent}
      opened={opened}
      onClose={() => setOpened(false)}
      modifiers={{ centered: true }}
      width="385px"
      closeButton
    >
      <DeveloperQuestion
        id={id}
        onClose={() => setOpened(false)}
        page={window.location.href}
      />
    </UIModal>
  );
};
