import { useState } from "react";
import { savePhoneClick } from "helpers/api";
import { sendEvent } from "helpers/analytics";
import { phonePrettier } from "helpers/utils";
import { useMobile } from "helpers/hooks";
import { useIntl } from "react-intl";
import { useGaCategory } from "../../analytics/useCategory";

const usePhone = ({ phone, buildingId, gaAction }) => {
  const category = useGaCategory();
  const intl = useIntl();
  const [isPhoneShown, setIsPhoneShown] = useState(false);
  const { isTouch } = useMobile();

  const sendClick = () => {
    savePhoneClick({
      buildingId,
      phone,
      userAgent: window.navigator.userAgent,
      page: document.location.href,
      event_category: category,
    });
  };

  const onButtonClick = () => {
    setTimeout(() => {
      setIsPhoneShown(true);
    }, 100);
    sendClick();
    sendEvent(`${category}|${gaAction}|show`);
  };

  const onLinkClick = () => {
    window.location.replace(`tel:${phone}`);
    sendClick();
    sendEvent(`${category}|${gaAction}|call`);
  };

  const getPhoneValue = () => {
    if (isTouch) return intl.formatMessage({ id: "phone.action.mobile" });
    if (isPhoneShown) return phonePrettier(phone);
    return intl.formatMessage(
      { id: "phone.action.desktop" },
      { phone: phone.slice(0, 3) }
    );
  };

  if (!phone) return {};

  return {
    phoneValue: getPhoneValue(),
    onPhoneClick: isPhoneShown || isTouch ? onLinkClick : onButtonClick,
  };
};

export default usePhone;
