import React from "react";
import styles from "./HomeCard.module.scss";

export const HomeCardCell = ({ children, isHidden }) => (
  <div className={`${styles.cell} ${isHidden && styles.hidden}`}>
    {children}
  </div>
);

export const HomeCardGrid = ({ children }) => (
  <div className={styles.grid}>{children}</div>
);
