import { useState, useRef } from "react";

const useMixedRefState = (initialValue) => {
  const ref = useRef(initialValue);
  const [state, setState] = useState(initialValue);
  const setStateRef = (value) => {
    setState(value);
    ref.current = value;
  };
  return [state, ref, setStateRef];
};

export default useMixedRefState;
