import { useRouteMatch } from "react-router-dom";
import { useBaseContext } from "helpers/hooks";

const useTabsRouteMatch = () => {
  const { routes } = useBaseContext();
  const excludeTabsRoute = [
    routes.message,
    routes.questionSupport,
    routes.question,
    routes.appointment,
    routes.appointmentFeedback,
  ];

  return !excludeTabsRoute.some(useRouteMatch);
};

export default useTabsRouteMatch;
