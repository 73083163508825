import React from "react";
import cn from "classnames";
import "./Tab.scss";
import { NavLink } from "react-router-dom";
import { attachGaDataAttributes } from "helpers/analytics";

const getUITabNode = (props) => {
  if (props.href) return "a";
  if (props.navLink) return NavLink;
  return "div";
};

const UITab = React.forwardRef((props, ref) => {
  const TabNode = getUITabNode(props);
  let tabProps = {};

  if (props.href) {
    tabProps.href = props.href;
  }
  if (props.navLink) {
    tabProps.to = props.navLink;
    tabProps.isActive = props.isActive;
    tabProps.activeClassName = "-active";
  }
  if (props.event) {
    tabProps = {
      ...tabProps,
      ...attachGaDataAttributes(props.event),
    };
  }

  return (
    <TabNode
      className={cn("UITabs-tab", props.classes?.tab, {
        "-active": props.active,
        "-last": props.last,
        "-primary": props.primary,
        "-hovered": props.hovered,
      })}
      onClick={props.onClick}
      {...tabProps}
      ref={ref}
    >
      <div className={cn("UITabs-content", props.classes?.content)}>
        {props.icon && (
          <div className={cn("UITabs-icon", props.classes?.icon)}>
            {props.icon}
          </div>
        )}
        {props.children}
        {props.label !== undefined && (
          <div className={cn("UITabs-label", props.classes?.label)}>
            {props.label}
          </div>
        )}
      </div>
    </TabNode>
  );
});

export default UITab;
