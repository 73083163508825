export const FAVORITE_TYPES = {
  ADD_FAVORTIE: "ADD_FAVORTIE",
  REMOVE_FAVORTIE: "REMOVE_FAVORTIE",
  RESET: "RESET",
  ADD_FAVORTIE_ID: "ADD_FAVORTIE_ID",
  SET_FAVORITES: "SET_FAVORITES",
  IS_LOADING: "IS_LOADING",
  LOADING_DONE: "LOADING_DONE",
  SET_FAVORITE_IDS: "SET_FAVORITE_IDS",
  IS_IDS_LOADING: "IS_IDS_LOADING",
};

export const INITIAL_FAVORITES_STATE = {
  ids: [],
  data: [],
  loading: false,
  loaded: false,
  idsLoading: false,
};
