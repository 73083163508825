import { COOKIE_NAME_USER_LANGUAGE, Language } from "constants/base";
import Cookies from "js-cookie";

const DEFAULT_LANGUAGE = Language.Uk;

export const LANGUAGE_PATH = {
  [Language.Uk]: "",
  [Language.Ru]: "/ru/",
};

export const setLanguage = (language = DEFAULT_LANGUAGE) => {
  Cookies.set(COOKIE_NAME_USER_LANGUAGE, language, {
    expires: 365,
    domain: process.env.REACT_APP_COOKIE_DOMAIN_NAME,
  });
};

export const getLanguage = () => {
  const cookieLocale = Cookies.get(COOKIE_NAME_USER_LANGUAGE);
  if (!cookieLocale || Object.values(Language).indexOf(cookieLocale) === -1) {
    setLanguage();
    return DEFAULT_LANGUAGE;
  }
  return cookieLocale;
};
