import Cookies from "js-cookie";
import { COOKIE_NAME_CURRENCY, Currency } from "../constants/base";

export const get = () => {
  const cookiesCurrency = Cookies.get(COOKIE_NAME_CURRENCY);
  if (!cookiesCurrency) {
    setCookie(Currency.UAH);
    return Currency.UAH;
  } else {
    return cookiesCurrency;
  }
};

export const set = (value) => {
  const availableCurrencies = Object.values(Currency);
  if (availableCurrencies.includes(value)) {
    setCookie(value);
  } else {
    throw new Error("Unknown currency value");
  }
};

const setCookie = (value) => {
  Cookies.set(COOKIE_NAME_CURRENCY, Currency.UAH, {
    expires: 365,
    domain: process.env.REACT_APP_COOKIE_DOMAIN_NAME,
  });
};
