import { useMemo } from "react";
import { useCurrency } from "../../helpers/hooks";
import { numberPrettier } from "../../helpers/utils";

export const usePrice = ({ price, currency, currencyName }, currencyConfig) => {
  const { value: currentCurrency } = useCurrency();

  const normalizedCurrentCurrency = currentCurrency.toUpperCase();
  const label = useMemo(
    () =>
      currencyConfig.currencies.find(
        (e) => e.currency_id === normalizedCurrentCurrency
      )?.name,
    [(normalizedCurrentCurrency, currencyConfig.currencies)]
  );

  let resultNum = price;

  if (normalizedCurrentCurrency === currency) {
    resultNum = price;
  } else {
    const rate = currencyConfig.rates[normalizedCurrentCurrency][currency];
    if (rate) {
      resultNum = price * rate;
    }
  }

  return `${numberPrettier(resultNum)} ${label || currencyName}`;
};
