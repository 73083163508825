import React, {
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import parsePhoneNumber, { AsYouType } from 'libphonenumber-js';
import { RefreshComponents } from "@flatfy/supernova-new";
import { useIntl } from "react-intl";
import { FormContext } from ".";

export const Field = ({ name, children }) => {
  const form = useContext(FormContext);

  return (
    <>
      {children({
        onChange: (value) => form.onChange(name, value),
        value: form.value[name],
      })}
    </>
  );
};

export const ErrorEnum = {
  Required: 'required',
  TypeMismatch: 'typeMismatch',
}

export const FormTextField = ({ name, isPhone, onError = () => {}, validate, ...props }) => {
  const form = useContext(FormContext);
  const inputRef = useRef();
  const intl = useIntl();
  const [invalid, setInvalid] = useState(false);
  const [helperText, setHelperText] = useState(props.helperText);

  const handlePhoneChange = (value) => {
    const parsedPhoneNumberUA = parsePhoneNumber(value, 'UA');
    if (parsedPhoneNumberUA) {
      form.onChange(name, new AsYouType('UA').input(value));
    } else {
      form.onChange(name, new AsYouType().input(value));
    }
  }

  const handleBlur = (evt) => {
    if (isPhone) {
      const parsedPhoneNumberUA = parsePhoneNumber(form.value[name], 'UA');
      if (parsedPhoneNumberUA && parsedPhoneNumberUA.isValid()) {
        form.onChange(name, parsedPhoneNumberUA.formatInternational());
      }
    }
    if (props.onBlur) {
      props.onBlur(evt);
    }
  };

  const handleChange = (value) => {
    if (isPhone) {
      handlePhoneChange(value);
    } else {
      form.onChange(name, value);
    }
    if (invalid) {
      setInvalid(false);
      setHelperText(props.helperText);
    }
  };

  useEffect(() => {
    if (!validate) return;
    form.addValidator({
      [name]: () => {
        if (
          props.required &&
          (!form.value[name] || form.value[name].length === 0)
        ) {
          setInvalid(true);
          setHelperText(intl.formatMessage({ id: "form.error.required" }));
          onError(ErrorEnum.Required);
          return false;
        }
        if (
          props.InputProps?.type === "email" &&
          inputRef.current &&
          inputRef.current.validity.typeMismatch
        ) {
          setInvalid(true);
          setHelperText(intl.formatMessage({ id: "form.error.typeEmail" }));
          onError(ErrorEnum.TypeMismatch);
          return false;
        }
        if (isPhone) {
          const parsedPhoneNumber = parsePhoneNumber(form.value[name]);
          if (!parsedPhoneNumber || !parsedPhoneNumber.isValid()) {
            setInvalid(true);
            setHelperText(intl.formatMessage({ id: "form.error.typePhone" }));
            onError(ErrorEnum.TypeMismatch);
            return false;
          }
        } 
        return true;
      },
    });
  }, [props.required, form.value[name], validate]);

  return (
    <RefreshComponents.TextField
      name={name}
      value={form.value[name]}
      onChange={handleChange}
      inputRef={inputRef}
      helperText={helperText}
      invalid={invalid}
      onBlur={handleBlur}
      {...props}
    />
  );
};
