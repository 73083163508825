import { useMemo, useState, useEffect } from "react";
import { useCompareBuildingIdsContext } from "helpers/hooks";
import { sendCompareChangeEvent } from "helpers/analytics";

const useCompareBuildings = (buildingId, options = {}) => {
  const { compareIds, add, remove } = useCompareBuildingIdsContext();

  const isActiveCompare = useMemo(() => compareIds.indexOf(buildingId) !== -1, [
    compareIds,
    buildingId,
  ]);

  const [needSendAnalytics, setNeedSendAnalytics] = useState(false);

  const onCompareClick = (e) => {
    e.preventDefault();
    e.stopPropagation();

    if (isActiveCompare) {
      remove.mutate(buildingId);
      if (options.onAdd) options.onAdd();
    } else {
      add.mutate([buildingId]);
      if (options.onRemove) options.onRemove();
    }

    setNeedSendAnalytics(true);
  };

  useEffect(() => {
    if (!needSendAnalytics) return;
    sendCompareChangeEvent({
      type: isActiveCompare ? "add" : "remove",
      buildingId,
      compares: compareIds.length,
    });
    setNeedSendAnalytics(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [compareIds]);

  return {
    isActiveCompare,
    onCompareClick,
  };
};

export default useCompareBuildings;
