export const FavoriteEntity = {
  BUILDINGS: "buildings",
  LAYOUTS: "layouts",
  COMPARES: "compares",
};

export const FAVORITE_ENTITIES = Object.values(FavoriteEntity);

export const BuildingType = {
  BUILDINGS: "buildings",
  COTTAGES: "cottages",
};
