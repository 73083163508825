import React, { useState } from "react";
import LazyLoad from "react-lazyload";

const Webp = ({
  srcset,
  src,
  imageSrc,
  imageSrcset,
  alt = "",
  className,
  placeholder,
}) => {
  const [loaded, setLoaded] = useState(false);
  return (
    <LazyLoad placeholder={placeholder}>
      <picture className={className}>
        <source
          srcSet={srcset ? `${src} 1x, ${srcset} 2x` : src}
          type="image/webp"
          onLoad={() => setLoaded(true)}
        />
        <img
          src={src}
          srcSet={imageSrcset ? `${imageSrc} 1x ${imageSrcset} 2x` : imageSrc}
          alt={alt}
          loading="lazy"
          onLoad={() => setLoaded(true)}
        />
      </picture>
      {!loaded && placeholder}
    </LazyLoad>
  );
};

export default Webp;
