import { useBaseContext } from "helpers/hooks";

export const useNotification = () => {
  const { showMessage, showMessageStr } = useBaseContext();
  return {
    showMessage,
    showMessageStr,
  };
};

export default useNotification;
