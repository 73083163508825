import React, { useEffect, useRef } from "react";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";
import { ReactComponent as CloseIcon } from "../../icons/close.svg";

import styles from "./Message.module.scss";
import "./animation.scss";

const Message = ({ messageStr, message, onHide, id }) => {
  const timeout = useRef();

  const handleClose = () => {
    timeout.current = null;
    onHide(id);
  };

  useEffect(() => {
    timeout.current = setTimeout(() => {
      onHide(id);
    }, 5000);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className={`${styles.item} MessageItem`} tabIndex="-1">
      {messageStr || <FormattedMessage id={message} />}
      <div className={styles.close} onClick={handleClose}>
        <CloseIcon />
      </div>
    </div>
  );
};

Message.propTypes = {
  message: PropTypes.string,
  messageStr: PropTypes.string,
  onHide: PropTypes.func.isRequired,
  id: PropTypes.string,
};

export default Message;
