import React from "react";
import { useGaCategory } from "../analytics/useCategory";
import { UIChip, UIToggleChip } from "../components/UIKit";
import { Currency, CURRENCY_SIGN } from "../constants/base";
import { useCurrency } from "../helpers/hooks";
import { gaEventActions as gaActions } from "../analytics";

import "./FooterCurrency.scss";

const CURRENCY_LIST = Object.values(Currency);

export const FooterCurrency = () => {
  const currency = useCurrency();
  const { getGaEvent: ga } = useGaCategory();

  return (
    <UIToggleChip
      attributes={{ onClick: currency.onToggle }}
      className="FooterCurrency"
    >
      {CURRENCY_LIST.map((value) => (
        <UIChip
          key={value}
          event={ga(gaActions.footer.currency_click)}
          active={currency.value === value}
        >
          {CURRENCY_SIGN[value]}
        </UIChip>
      ))}
    </UIToggleChip>
  );
};
