import React from "react";
import cx from "classnames";
import * as Icon from "./icons";
import { FormattedMessage } from "react-intl";
import AnalyticsElement from "../AnalyticsElement";
import styles from "./AppLinks.module.scss";

const GOOGLE_PLAY_LINK =
  "https://play.google.com/store/apps/details?id=com.lun.mylun&hl=uk&gl=US";
const APP_STORE_LINK =
  "https://apps.apple.com/ua/app/%D0%BB%D1%83%D0%BD/id1508320534?l=uk";

const AppLink = ({ className, logoIcon, textIcon, href }) => (
  <a href={href} className={cx(styles.link, className)} target="_blank">
    <div className={styles.logo}>{logoIcon}</div>
    <div className={styles.content}>
      <FormattedMessage tagName="span" id="downloadIn" />
      {textIcon}
    </div>
  </a>
);

const AppLinks = ({ className, classes = {}, eventAction, eventCategory }) => {
  return (
    <div className={cx(styles.root, classes.root, className)}>
      <AnalyticsElement
        element={AppLink}
        eventCategory={eventCategory}
        eventAction={eventAction}
        eventLabel="goto_android_app"
        className={classes.link}
        textIcon={<Icon.GooglePlayText />}
        logoIcon={<Icon.GooglePlayLogo />}
        href={GOOGLE_PLAY_LINK}
      />
      <AnalyticsElement
        element={AppLink}
        eventCategory={eventCategory}
        eventAction={eventAction}
        eventLabel="goto_ios_app"
        className={classes.link}
        textIcon={<Icon.AppStoreText />}
        logoIcon={<Icon.AppStoreLogo />}
        href={APP_STORE_LINK}
      />
    </div>
  );
};

export default AppLinks;
