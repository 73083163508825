import Cookies from "js-cookie";

export const REALTY_FAVORITES_COOKIE_NAME_DEPRECATED =
  "lun_market_favorites_v2";
export const REALTY_FAVORITES_COOKIE_NAME = "lun_market_favorites_v3";

const COOKIES_OPTIONS = {
  domain: process.env.REACT_APP_COOKIE_DOMAIN_NAME,
  expires: 365,
};

const DEFAULT_VALUE = [];

const syncRealtyIdsFromDeprecatedCookies = (oldCookies) => {
  try {
    const parsedCookies = JSON.parse(oldCookies);
    if (Array.isArray(parsedCookies)) {
      const realtyIdList = parsedCookies.map((item) => item.realtyId);
      setCookie(realtyIdList);
      return realtyIdList;
    } else {
      throw new Error();
    }
  } catch (err) {
    console.error("Bad deprecated cookies format");
    return DEFAULT_VALUE;
  } finally {
    Cookies.remove(REALTY_FAVORITES_COOKIE_NAME_DEPRECATED, COOKIES_OPTIONS);
  }
};

export const getCookies = () => {
  const oldCookiesValue = Cookies.get(REALTY_FAVORITES_COOKIE_NAME_DEPRECATED);
  const cookiesValue = Cookies.get(REALTY_FAVORITES_COOKIE_NAME);

  if (oldCookiesValue && !cookiesValue) {
    return syncRealtyIdsFromDeprecatedCookies(oldCookiesValue);
  }
  if (!cookiesValue) {
    return DEFAULT_VALUE;
  }
  try {
    const parsedCookies = JSON.parse(cookiesValue);
    return Array.isArray(parsedCookies) ? parsedCookies : DEFAULT_VALUE;
  } catch (err) {
    console.error("Bad favorites cookies format", err);
    return DEFAULT_VALUE;
  }
};

export const setCookie = (value) => {
  Cookies.set(
    REALTY_FAVORITES_COOKIE_NAME,
    JSON.stringify(value),
    COOKIES_OPTIONS
  );
};

export const addItem = (realtyId) => {
  const previousValue = getCookies();
  const nextValue = [...previousValue, realtyId];
  setCookie(nextValue);
  return nextValue;
};

export const removeItem = (realtyId) => {
  const previousValue = getCookies();
  const nextValue = previousValue.filter((item) => item !== realtyId);
  setCookie(nextValue);
  return nextValue;
};

export const updateCookieByDeletedRealties = (ids) => {
  const previousValue = getCookies();
  const nextValue = previousValue.filter((item) => ids.includes(item));
  setCookie(nextValue);
  return nextValue;
};
