const COMPARE_TYPES = {
  ADD: "ADD",
  ADD_LOADING: "ADD_LOADING",
  REMOVE: "REMOVE",
  RESET: "RESET",
  REORDER: "REORDER",
  FETCH: "FETCH",
  FETCH_LOADING: "FETCH_LOADING",
  SET_HIDDEN_PROPS: "SET_HIDDEN_PROPS",
};

const initialCompares = {
  buildings: [],
  blocks: [],
  hiddenProps: [],
  isFetching: true,
  geoLink: null,
};

// Унификация параметров в array
const getInitialHidden = (params) => {
  if (!params.filter) return {};
  const parsedParams = {};
  Object.keys(params.filter).forEach((key) => {
    parsedParams[key] =
      typeof params.filter[key] === "string"
        ? [params.filter[key]]
        : params.filter[key];
  });
  return parsedParams;
};

const compareReducer = (compares, { type, payload }) => {
  switch (type) {
    case COMPARE_TYPES.FETCH:
      return {
        ...compares,
        isFetching: false,
        buildings: payload.items,
        blocks: payload.blocks,
        geoLink: payload.geo_link,
      };
    case COMPARE_TYPES.FETCH_LOADING:
      return {
        ...compares,
        isFetching: true,
      };
    case COMPARE_TYPES.ADD_LOADING:
      return {
        ...compares,
        buildings: [
          ...payload.ids.map((id) => ({ id, isFetching: true })),
          ...compares.buildings,
        ],
      };
    case COMPARE_TYPES.ADD:
      return {
        ...compares,
        buildings: compares.buildings.map(
          (oldCompare) =>
            payload.items.find((e) => e.id === oldCompare.id) || oldCompare
        ),
        blocks: compares.blocks.length ? compares.blocks : payload.blocks,
      };
    case COMPARE_TYPES.REMOVE:
      return {
        ...compares,
        buildings: [
          ...compares.buildings.slice(
            0,
            compares.buildings.findIndex((e) => e.id === payload.id)
          ),
          ...compares.buildings.slice(
            compares.buildings.findIndex((e) => e.id === payload.id) + 1
          ),
        ],
      };
    case COMPARE_TYPES.RESET:
      return {
        ...initialCompares,
        isFetching: false,
      };
    case COMPARE_TYPES.REORDER: {
      const result = Array.from(compares.buildings);
      const [removed] = result.splice(payload.startIndex, 1);
      result.splice(payload.endIndex, 0, removed);
      return {
        ...compares,
        buildings: result,
      };
    }
    case COMPARE_TYPES.SET_HIDDEN_PROPS:
      return {
        ...compares,
        hiddenProps: payload.hiddenProps,
      };
    default:
      throw new Error(`No compare action type: ${type}`);
  }
};

export { COMPARE_TYPES, initialCompares, compareReducer, getInitialHidden };
