import React, { createContext } from "react";
import { useMutationApi, useUserContext, useQueryApi } from "../helpers/hooks";
import * as Api from "../helpers/api";
import { useList } from "react-use";
import { initComparesAnalytics } from "../helpers/analytics";

const CompareBuildingIdsContext = createContext();

const CompareBuildingIdsContextProvider = ({ children }) => {
  const [compareIds, compareIdsActions] = useList([]);
  const {
    state: { isGuest },
  } = useUserContext();

  const compareIdsQuery = useQueryApi(
    ["compare-building-ids", { isGuest }],
    () => Api.getCompareIds(),
    {
      initialData: [],
      onSuccess(data) {
        compareIdsActions.set(data.building_ids);
        initComparesAnalytics(data.building_ids.length);
      },
    }
  );

  const add = useMutationApi(Api.addCompare, {
    onMutate(ids) {
      compareIdsActions.push(...ids);
    },
  });

  const remove = useMutationApi(Api.removeCompare, {
    onMutate(id) {
      compareIdsActions.removeAt(compareIds.indexOf(id));
    },
  });

  const value = {
    compareIds: compareIds,
    loading: compareIdsQuery.isFetching,
    remove,
    add,
  };

  return (
    <CompareBuildingIdsContext.Provider value={value}>
      {children}
    </CompareBuildingIdsContext.Provider>
  );
};

export { CompareBuildingIdsContext, CompareBuildingIdsContextProvider };
