import { useRouteMatch } from "react-router-dom";
import { useUrlParams, useBaseContext } from "helpers/hooks";

const useDeveloperFormPopup = () => {
  const { routes } = useBaseContext();
  const { urlParams } = useUrlParams();
  const isOnlyForm = useRouteMatch(routes.questionFormOnly);

  const sendBackPopupMessage = () => {
    window.parent.postMessage("closePopup", "*");
  };

  const getParentOrigin = () => {
    return urlParams.from || window.location.href;
  };

  return {
    onlyForm: isOnlyForm,
    sendBackPopupMessage,
    getParentOrigin,
  };
};

export default useDeveloperFormPopup;
