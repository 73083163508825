const USER_TYPES = {
  LOGIN: "LOGIN",
  LOGOUT: "LOGOUT",
  UPDATE: "UPDATE",
  SUBSCRIBE: "SUBSCRIBE",
  UNSUBSCRIBE: "UNSUBSCRIBE",
  INIT_SUBSCRIPTION: "INIT_SUBSCRIPTION",
};

const userReducer = (state, { type, payload }) => {
  switch (type) {
    case USER_TYPES.LOGIN:
      return {
        ...payload,
        isGuest: false,
        isLoaded: true,
      };
    case USER_TYPES.LOGOUT:
      return {
        isGuest: true,
        canSubscribe: false,
        buildings: payload ? payload.buildings : 0,
        layouts: payload ? payload.layouts : 0,
        cottages: payload ? payload.cottages : 0,
        isLoaded: true,
      };
    case USER_TYPES.INIT_SUBSCRIPTION:
      return {
        ...state,
        isSubscribed: payload.isSubscribed,
        canSubscribe: payload.canSubscribe,
        loginType: payload.loginType,
      };
    case USER_TYPES.SUBSCRIBE:
      return {
        ...state,
        isSubscribed: true,
      };
    case USER_TYPES.UNSUBSCRIBE:
      return {
        ...state,
        isSubscribed: false,
      };
    case USER_TYPES.UPDATE:
      return {
        ...state,
        ...payload,
      };
    default:
      return state;
  }
};

export { USER_TYPES, userReducer };
