import { FAVORITE_TYPES } from "./constants";

export const favoritesReducer = (state, action) => {
  switch (action.type) {
    case FAVORITE_TYPES.SET_FAVORITES:
      return {
        ...state,
        data: action.payload.data,
        loaded: true,
      };
    case FAVORITE_TYPES.SET_FAVORITE_IDS:
      return {
        ...state,
        ids: action.payload.data,
      };
    case FAVORITE_TYPES.ADD_FAVORTIE:
      return {
        ...state,
        data: [...state.data, action.payload.data],
        ids: [...state.ids, Number(action.payload.data.id)],
      };
    case FAVORITE_TYPES.ADD_FAVORTIE_ID:
      return {
        ...state,
        ids: [...state.ids, Number(action.payload.id)],
        loaded: false,
      };
    case FAVORITE_TYPES.REMOVE_FAVORTIE:
      return {
        ...state,
        data: state.data.filter(
          (e) => Number(e.id) !== Number(action.payload.id)
        ),
        ids: state.ids.filter((id) => Number(id) !== Number(action.payload.id)),
      };
    case FAVORITE_TYPES.RESET:
      return {
        ...state,
        loaded: false,
      };
    case FAVORITE_TYPES.IS_LOADING:
      return {
        ...state,
        loading: true,
      };
    case FAVORITE_TYPES.IS_IDS_LOADING:
      return {
        ...state,
        idsLoading: action.payload.loading,
      };
    case FAVORITE_TYPES.LOADING_DONE:
      return {
        ...state,
        loading: false,
      };
    default:
      return state;
  }
};
