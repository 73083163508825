import { REGION_TYPES } from "./region.reducer";
import { getRegionList, setRegion } from "../../helpers/api";

const cacheActiveRegion = (region) => {
  localStorage.setItem("activeRegion", JSON.stringify(region));
};

const getRegions = (dispatch) => () => {
  getRegionList().then(({ data, status }) => {
    if (status === "error") throw new Error();
    dispatch({ type: REGION_TYPES.INIT_REGIONS, payload: { regions: data } });
    cacheActiveRegion(data.active_region[0]);
  });
};

const setActiveRegion = (dispatch) => (region) => {
  dispatch({
    type: REGION_TYPES.SET_ACTIVE_REGION,
    payload: { activeRegion: region },
  });
  cacheActiveRegion(region);
  return setRegion({ region_id: region.region_id });
};

const setActiveLink = (dispatch) => (link) => {
  dispatch({
    type: REGION_TYPES.SET_ACTIVE_LINK,
    payload: { link },
  });
};

export { getRegions, setActiveRegion, setActiveLink };
