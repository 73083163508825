import React from "react";
import cn from "classnames";
import "./Grid.scss";

const SIZES = Array.from({ length: 12 }, (_, i) => i + 1);

export const UIGrid = ({
  container,
  item,
  size,
  lg,
  md,
  sm,
  xs,
  alwaysWrapLg,
  alwaysWrapMd,
  alwaysWrapSm,
  scrollable,
  noMobileGallery,
  children,
}) => {
  if (container) {
    return (
      <div
        className={cn("UIGrid", {
          "-scrollable": scrollable,
          "-always-wrap-lg": alwaysWrapLg,
          "-always-wrap-md": alwaysWrapMd,
          "-always-wrap-sm": alwaysWrapSm,
          "-no-mobile-gallery": noMobileGallery,
        })}
      >
        {children}
      </div>
    );
  } else if (item && size && SIZES.includes(size)) {
    return (
      <div
        className={cn({
          [`UIGrid-col-${size}`]: item && SIZES.includes(size),
          [`UIGrid-col-lg-${lg}`]: item && lg && SIZES.includes(lg),
          [`UIGrid-col-md-${md}`]: item && md && SIZES.includes(md),
          [`UIGrid-col-sm-${sm}`]: item && sm && SIZES.includes(sm),
          [`UIGrid-col-xs-${xs}`]: item && xs && SIZES.includes(xs),
        })}
      >
        {children}
      </div>
    );
  } else {
    return null;
  }
};
