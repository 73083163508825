import React, { useState } from "react";
import cx from "classnames";
import * as Icon from "../icons";
import { FormattedMessage, useIntl } from "react-intl";
import { UINavigationArrow } from "components/UIKit/NavigationArrow";
import { UIButton } from "components/UIKit/Button";
import {
  useArea,
  useBuildingType,
  useDate,
  useFloor,
  useWallType,
  useGeo,
  useUrl,
  usePrice,
} from "../hooks";
import { useMobile, useSwiper } from "../../helpers/hooks";
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Lazy } from "swiper";
import Webp from "../../components/Image/Wepb";
import { useGaCategory } from "../../analytics/useCategory";
import { gaEventActions as gaActions } from "../../analytics";
import { Favorite } from "./Favorite";
import { RedirectUrl } from "./RedirectUrl";

import "swiper/swiper.scss";
import styles from "./LunMarketFavoritesCard.module.scss";

SwiperCore.use([Lazy]);

const LunMarketFavoritesCard = ({
  price,
  price_sqm,
  currency_name: currencyName,
  floor,
  floor_count,
  area_total,
  header,
  area_living,
  area_kitchen,
  built_year,
  wall_type_name,
  house_type_name,
  room_count,
  download_time,
  insert_time,
  images,
  text,
  image_alt,
  geo,
  geo_entities,
  id,
  currency,
  currencyConfig,
}) => {
  const intl = useIntl();
  const { getGaEvent: ga } = useGaCategory();
  const { isPrev, isNext, onSwiper, onPrevSlide, onNextSlide } = useSwiper();
  const [descriptionOpened, setDescriptionOpened] = useState(false);
  const areaText = useArea({
    totalArea: area_total,
    livingArea: area_living,
    nonLivingArea: area_kitchen,
  });
  const { isTouch } = useMobile();
  const floorText = useFloor({ total: floor_count, number: floor });
  const buildingType = useBuildingType(house_type_name);
  const wallType = useWallType(wall_type_name);
  const downloadTime = useDate(download_time);
  const insertTime = useDate(insert_time);
  const geoText = useGeo(geo, geo_entities);
  const url = useUrl(id);
  const formattedPrice = usePrice({ price, currency }, currencyConfig);
  const formattedPriceSqm = usePrice(
    { price: price_sqm, currency, currencyName },
    currencyConfig
  );

  return (
    <div className={cx(styles.root, { [styles.closed]: !descriptionOpened })}>
      <div className={styles.base}>
        <div className={styles.slider}>
          <Swiper onSwiper={onSwiper} className={styles.swiper}>
            {images.map(({ image_id }) => {
              const srcBase = `https://lunappimg.appspot.com/lun-ua/414/336/images-cropped/${image_id}`;
              const srcsetBase = `https://lunappimg.appspot.com/lun-ua/828/672/images-cropped/${image_id}`;
              return (
                <SwiperSlide key={image_id}>
                  <RedirectUrl id={id}>
                    <Webp
                      src={`${srcBase}.webp`}
                      srcset={`${srcsetBase}.webp`}
                      imageSrc={`${srcBase}.jpg`}
                      imageSrcset={`${srcsetBase}.jpg`}
                      alt={image_alt}
                    />
                  </RedirectUrl>
                </SwiperSlide>
              );
            })}
          </Swiper>
          {!isTouch && (
            <>
              {isPrev && (
                <div className={cx(styles.arrow, styles.prev)}>
                  <UINavigationArrow
                    direction="left"
                    onClick={onPrevSlide}
                    event={ga(gaActions.market.prev_slide_click)}
                  />
                </div>
              )}
              {isNext && (
                <div className={cx(styles.arrow, styles.next)}>
                  <UINavigationArrow
                    direction="right"
                    onClick={onNextSlide}
                    event={ga(gaActions.market.next_slide_click)}
                  />
                </div>
              )}
            </>
          )}
          <Favorite id={id} />
        </div>
        <div className={styles.content}>
          <div className={styles.header}>
            <RedirectUrl className={styles.price} id={id}>
              <div className={styles.priceMain}>{formattedPrice}</div>
              <div className={styles.priceSqm}>{formattedPriceSqm} за м²</div>
            </RedirectUrl>
            <Favorite id={id} />
          </div>
          <RedirectUrl id={id} className={styles.title}>
            {header}
          </RedirectUrl>
          <RedirectUrl id={id} className={styles.subtitle}>
            {geoText}
          </RedirectUrl>
          <div
            className={styles.description}
            onClick={() => setDescriptionOpened(!descriptionOpened)}
          >
            {text}
          </div>
          <RedirectUrl id={id} className={styles.propertiesLink}>
            <div className={styles.properties}>
              <div className={styles.propertiesColumn}>
                <div className={styles.propertiesItem}>
                  <Icon.Rooms />
                  <FormattedMessage
                    id="market.rooms"
                    values={{ count: room_count }}
                    tagName="span"
                  />
                </div>
                {areaText && (
                  <div className={styles.propertiesItem}>
                    <Icon.Area />
                    <span>{areaText} m²</span>
                  </div>
                )}
                <div className={styles.propertiesItem}>
                  <Icon.Floor />
                  <span>{floorText}</span>
                </div>
              </div>
              <div className={styles.propertiesColumn}>
                <div className={styles.propertiesItem}>
                  <span>{buildingType}</span>
                </div>
                <div className={styles.propertiesItem}>
                  <span>{wallType}</span>
                </div>
                <div className={cx(styles.propertiesItem, styles.builtYear)}>
                  <span>{built_year}</span>
                </div>
              </div>
            </div>
          </RedirectUrl>

          <RedirectUrl className={styles.date}>
            {download_time && (
              <>
                <Icon.Updated />
                <span>{downloadTime};</span>
              </>
            )}
            <FormattedMessage id="market.created" tagName="span" /> {insertTime}
          </RedirectUrl>
          <UIButton
            attributes={{
              href: url.redirect,
              target: "_blank",
              rel: "nofollow noopener",
            }}
            className={styles.action}
            event={ga(gaActions.market.goto_realty)}
          >
            <FormattedMessage id="market.more" />
          </UIButton>
        </div>
      </div>
    </div>
  );
};

export default LunMarketFavoritesCard;
