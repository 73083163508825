import React, { useState, useEffect, useCallback } from "react";
import { useRouteMatch } from "react-router-dom";
import {
  useUserContext,
  useNotification,
  useForm,
  useMutationApi,
  useLoginPage,
  useBaseContext,
} from "helpers/hooks";
import { sendEvent } from "helpers/analytics";
import { UIForm } from "../components/UIKit/Form";
import { UIButton } from "../components/UIKit/Button";
import { AUTH_STEP } from "constants/auth";
import { UIInput } from "../components/UIKit/Input";
import { UILoader } from "../components/UIKit/Loader";
import { FormattedMessage, useIntl } from "react-intl";
import TelegramLoginButton from "react-telegram-login";
import styles from "./Authorization.module.scss";
import { attachGaDataAttributes } from "helpers/analytics";
import { ReactComponent as FacebookIcon } from "icons/facebook.svg";
import cn from "classnames";
import FormTerms from "../components/FormTerms/FormTerms";
import * as Api from "helpers/api";
import { gaEventActions as gaActions } from "../analytics";
import { useGaCategory } from "../analytics/useCategory";
import FacebookLogin from "react-facebook-login/dist/facebook-login-render-props";
import { useRecaptcha } from "../helpers/hooks";

const GOOGLE_BUTTON_ID = "google-auth-button";

const AuthorizationMain = ({ setStep, onClose }) => {
  const { routes } = useBaseContext();
  const showOneTapLogin = ![
    routes.questionFormOnly,
    routes.profileMenu,
    routes.login,
  ].some(useRouteMatch);
  const intl = useIntl();
  const user = useUserContext();
  const notification = useNotification();
  const form = useForm();
  const [loading, setLoading] = useState(false);
  const recaptcha = useRecaptcha();
  const isLoginPage = useLoginPage();
  const { getGaEventString: gaStr } = useGaCategory();

  const mutationOptions = {
    onError: (err) => {
      notification.showMessageStr(err.message);
    },
  };

  const login = useMutationApi(Api.login, {
    ...mutationOptions,
    onError: (err) => {
      notification.showMessageStr(err.message);
      sendEvent(gaStr(gaActions.auth.login_type.error));
    },
    onSuccess: (res) => {
      if (res.type === "phone") {
        setStep(AUTH_STEP.CODE);
      }
      if (res.type === "email") {
        setStep(AUTH_STEP.EMAIL);
      }
    },
  });

  const handleFormSubmit = () => {
    if (!form.validate()) return;
    recaptcha.execute((token) => {
      login.mutate({
        ...form.state,
        "g-recaptcha-response": token,
      });
    });
  };

  const handleFacebookLogin = async (response) => {
    const { accessToken, data_access_expiration_time } = response;
    try {
      setLoading(true);
      await user.actions.facebook({
        token: accessToken,
        expires: data_access_expiration_time,
      });
      setLoading(false);
      onClose();
      sendEvent(gaStr(gaActions.auth.success_type.facebook_success));
    } catch (error) {
      sendEvent(gaStr(gaActions.auth.facebook_type.error));
    }
  };

  const handleTelegramLogin = (params) => {
    user.actions
      .telegram(params)
      .then(() => {
        onClose();
        sendEvent(gaStr(gaActions.auth.success_type.telegram_success));
      })
      .catch(() => {
        sendEvent(gaStr(gaActions.auth.telegram_type.error));
      });
  };

  const googleLogin = () => {
    if (!window.google) return;

    window.google.accounts.id.initialize({
      client_id: process.env.REACT_APP_GOOGLE_CLIENT_ID,
      callback: (response) => {
        user.actions
          .google({ token: response.credential })
          .then(() => {
            onClose();
            sendEvent(gaStr(gaActions.auth.success_type.one_tap_success));
          })
          .catch(() => {
            sendEvent(gaStr(gaActions.auth.one_tap_type.error));
          });
      },
    });
    window.google.accounts.id.renderButton(
      document.getElementById(GOOGLE_BUTTON_ID),
      { locale: "uk_", size: "large", width: 300 } // customization attributes
    );

    if (showOneTapLogin) {
      window.google.accounts.id.prompt(); // one tap login
    }
  };

  useEffect(() => {
    try {
      googleLogin();
      // authService.initFacebook();
    } catch (err) {
      console.err(err);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <UILoader show={login.isLoading || loading || recaptcha.loading} />
      <div className={cn(styles.section, styles.phoneInput)}>
        <UIForm.Group>
          <UIForm.Row>
            <UIInput
              ref={form.onInputRef}
              onChange={form.handleChangeInput}
              name="login"
              label={intl.formatMessage({ id: "phone" })}
              value={form.state.login}
              event={gaActions.auth.login_type.phone_email_type}
              required
              type="tel"
              isPhone
            />
          </UIForm.Row>
          <UIButton
            appearance="primary"
            event={gaActions.auth.login_type.submit_click}
            onClick={handleFormSubmit}
            loading={login.isLoading}
          >
            <FormattedMessage id="enter" />
          </UIButton>
        </UIForm.Group>
      </div>
      <div className={styles.action}>
        <div id={GOOGLE_BUTTON_ID} className={styles.google} />
      </div>
      <FacebookLogin
        appId={process.env.REACT_APP_FACEBOOK_CLIENT_ID}
        callback={handleFacebookLogin}
        fields="name,email,picture"
        render={(renderProps) => (
          <div className={cn(styles.action, styles.facebook)}>
            <UIButton
              icon={<FacebookIcon />}
              appearance="ghost-light"
              className={styles.facebook}
              event={gaActions.auth.login_type.facebook_click}
              fullWidth
              disabled={renderProps.isDisabled || !renderProps.isSdkLoaded}
              onClick={() => renderProps.onClick()}
            >
              Facebook
            </UIButton>
          </div>
        )}
      />
      {!isLoginPage && (
        <div className={cn(styles.action, styles.telegram)}>
          <TelegramLoginButton
            {...attachGaDataAttributes(
              gaActions.auth.login_type.telegram_click
            )}
            usePic={false}
            dataOnauth={handleTelegramLogin}
            botName={process.env.REACT_APP_TELEGRAM_BOT_NAME}
          />
        </div>
      )}

      <FormTerms isAuth />
    </>
  );
};

export default AuthorizationMain;
