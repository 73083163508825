import React, { useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useForm, useBaseContext } from "helpers/hooks";
import { subscribeBack, unsubscribeFeedback } from "helpers/api";
import { sendEvent } from "helpers/analytics";
import { UIInput } from "components/UIKit/Input";
import { UIButton } from "components/UIKit/Button";
import { UIChip } from "components/UIKit/Chip";
import styles from "./Unsubscribe.module.scss";
import { ReactComponent as SuccessIcon } from "icons/subscribe.svg";
import cn from "classnames";
import { UITypography } from "../../components/UIKit/Typography";
import { useGaCategory } from "../../analytics/useCategory";
import { gaEventActions as gaActions } from "../../analytics";

const chips = [
  "uninterested",
  "spam",
  "disturb",
  "bought",
  "irrelevant",
  "other",
];

const subscribedEnum = {
  ON: "ON",
  OFF: "OFF",
  IN_PROGRESS: "IN_PROGRESS",
};

const Unsubscribe = ({ type, params, isGeneral }) => {
  const intl = useIntl();
  const { state, handleChangeInput } = useForm(params);
  const [tag, setTag] = useState({});
  const [response, setResponse] = useState(subscribedEnum.OFF);
  const [subscribed, setSubscribed] = useState(subscribedEnum.OFF);
  const { showMessage } = useBaseContext();
  const { getGaEvent: ga, getGaEventString: gaStr } = useGaCategory();

  const onSubmit = (e) => {
    e.preventDefault();
    setResponse(subscribedEnum.IN_PROGRESS);
    if (state.comment) {
      unsubscribeFeedback({
        ...state,
        type,
        tag: tag.message,
        token: params.token,
        id: params.bid || params.cid || params.rid,
      })
        .then((r) => {
          if (r.status !== "ok") throw new Error();
          setResponse(subscribedEnum.ON);
          sendEvent(gaStr(gaActions.unsubscribe[type].success));
        })
        .catch(() => {
          showMessage("error");
          setResponse(subscribedEnum.OFF);
          sendEvent(gaStr(gaActions.unsubscribe[type].error));
        });
    } else {
      setResponse(subscribedEnum.ON);
    }
  };

  const onSubscribeBack = () => {
    setSubscribed(subscribedEnum.IN_PROGRESS);
    subscribeBack(params)
      .then((r) => {
        if (r.status !== "ok") throw new Error();
        setSubscribed(subscribedEnum.ON);
      })
      .catch(() => {
        showMessage("error");
        setSubscribed(subscribedEnum.OFF);
      });
  };

  /* Успешно подписался обратно */
  if (subscribed === subscribedEnum.ON) {
    return (
      <div className={cn(styles.panel, styles.success)}>
        <UITypography variant="h2" className={styles.title}>
          <FormattedMessage id="unsubscribe.subscribe.title" />
        </UITypography>
        <UITypography className={styles.description}>
          <FormattedMessage id="unsubscribe.subscribe.description" />
        </UITypography>
        <SuccessIcon />
        <UIButton
          event={ga(gaActions.unsubscribe[type].goto_catalog)}
          appearance="primary"
          attributes={{ href: process.env.REACT_APP_SITE_LINK }}
          fullWidth
        >
          <FormattedMessage id="all.buildings" />
        </UIButton>
      </div>
    );
  }

  return (
    <div className={styles.panel}>
      <div className={styles.title}>
        <FormattedMessage
          id={`unsubscribe.title${isGeneral ? ".general" : ""}`}
          values={{ br: <br /> }}
        />
      </div>
      <div className={styles.description}>
        <FormattedMessage
          id={`unsubscribe.description.${type}`}
          values={params}
        />
      </div>
      {response === subscribedEnum.ON /* Успешно отправил фидбэк */ ? (
        <div className={styles.success}>
          <div className={styles.title}>
            <FormattedMessage id="unsubscribe.success.title" />
          </div>
          <div className={styles.description}>
            <FormattedMessage id="unsubscribe.success.description" />
          </div>
          <SuccessIcon />
        </div>
      ) : (
        <form onSubmit={onSubmit}>
          <div className={styles.subtitle}>
            <FormattedMessage id="unsubscribe.subtitle" />
          </div>
          <div className="UIChips -wrap">
            {chips.map((item) => (
              <FormattedMessage id={`unsubscribe.${item}`}>
                {(msg) => (
                  <UIChip
                    active={tag.id === item}
                    event={ga(gaActions.unsubscribe[type].reason_click(item))}
                    onClick={() => setTag({ id: item, message: msg })}
                  >
                    {msg}
                  </UIChip>
                )}
              </FormattedMessage>
            ))}
          </div>
          <div style={{ visibility: tag.message ? "visible" : "hidden" }}>
            <div className={styles.textarea}>
              <UIInput
                name="comment"
                label={intl.formatMessage({ id: "unsubscribe.label" })}
                value={state.comment}
                isTextarea
                onChange={handleChangeInput}
                attributes={{ rows: 4 }}
                event={ga(gaActions.unsubscribe[type].comment_type)}
              />
            </div>
            <div className={styles.submit}>
              <UIButton
                event={ga(gaActions.unsubscribe[type].submit_click)}
                appearance="primary"
                attributes={{ type: "submit" }}
                fullWidth
              >
                <FormattedMessage id="unsubscribe.send" />
              </UIButton>
            </div>
          </div>
        </form>
      )}
      {!isGeneral && (
        <UIButton
          appearance="secondary"
          disabled={subscribed === subscribedEnum.IN_PROGRESS}
          onClick={onSubscribeBack}
          event={ga(gaActions.unsubscribe[type].subscribe_click)}
          fullWidth
        >
          <FormattedMessage id="unsubscribe.subscribe" />
        </UIButton>
      )}
    </div>
  );
};

export default Unsubscribe;
