import React from "react";
import { attachGaDataAttributes } from "helpers/analytics";
import { useGaCategory } from "../analytics/useCategory";

const FooterProject = ({ icon, link, children, event }) => {
  const { getGaEvent: ga } = useGaCategory();
  return (
    <a
      href={link}
      target="_blank"
      rel="noopener noreferrer"
      {...attachGaDataAttributes(ga(event))}
      className="FooterProject"
    >
      <div className="FooterProject-logo">{icon}</div>
      <div className="FooterProject-description">{children}</div>
    </a>
  );
};

export default FooterProject;
