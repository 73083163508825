import { useState } from "react";
import { useInterval } from "react-use";
import { sendEvent } from "helpers/analytics";

const useFormTimerEvent = () => {
  const delay = 1000;
  const [seconds, setSeconds] = useState(0);
  const [isRunning, setIsRunning] = useState(false);

  useInterval(
    () => {
      setSeconds(seconds + 1);
    },
    isRunning ? delay : null
  );

  return {
    send: (event, label = "time_complete") =>
      sendEvent(`${event}|${label}`, { eventValue: seconds }),
    stop: () => setIsRunning(false),
    start: () => setIsRunning(true),
    seconds,
  };
};

export default useFormTimerEvent;
