import { useUrlParams } from "helpers/hooks";

export const useSharedBuildingIds = () => {
  const { urlParams } = useUrlParams();

  try {
    return JSON.parse(urlParams.building_ids);
  } catch {
    return null;
  }
};

export default useSharedBuildingIds;
